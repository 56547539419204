/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import BaseScheduleRepairs from './BaseScheduleRepairs';
import { Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useLocation, Route } from "react-router-dom";
import AssetService from '../../services/asset.service';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import ScheduleService from '../../services/schedule.service';
import HomeService from '../../services/home.service';
import AlertDetailsModal from './AlertDetailsModal';
import ScheduleJobModal from './ScheduleJobModal';
import UpdateJobModal from './UpdateJobModal';
import UnScheduleJObCloseModel from './UnScheduleJObCloseModel';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ScheduleRepairsTable from './ScheduleRepairsTable';
import Snackbar from '@material-ui/core/Snackbar';
import memoize from "memoize-one";
import moment from "moment";
import ServiceTitanService from '../../services/servicetitan.service';
import { jsHouse, jsOpportunity, jsTechnician, jsDetails, jsCustomer, alertSearchStartDate, jsScheduleDetails, jsPartner } from "../../componentObjects";
import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import alerttypemessagingService from "../../services/alerttypemessaging.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class ScheduleRepairs extends Component {
    static displayName = ScheduleRepairs.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.changeRadio = this.changeRadio.bind(this);
        this.getOpportunities = this.getOpportunities.bind(this);

        this.onOpportunityClick = this.onOpportunityClick.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        // this.closeOpportunity = this.closeOpportunity.bind(this);
        this.getAlertMessaging = this.getAlertMessaging.bind(this);
        this.getAlerts = this.getAlerts.bind(this);
        this.getCurrentOpportunity = this.getCurrentOpportunity.bind(this);
        this.getAlertMessaging = this.getAlertMessaging.bind(this);
        this.reloadTable = this.reloadTable.bind(this);

        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);

        this.onOpportunityClick = this.onOpportunityClick.bind(this);

        this.openScheduleJobModal = this.openScheduleJobModal.bind(this);
        this.showScheduleJobModal = this.showScheduleJobModal.bind(this);
        this.onBookClick = this.onBookClick.bind(this);

        this.showUnassignedTechModal = this.showUnassignedTechModal.bind(this);
        this.onUnassignedBookClick = this.onUnassignedBookClick.bind(this);
        this.showUnassignedTechUpdateModal = this.showUnassignedTechUpdateModal.bind(this);
        this.onUnassignedUpdateClick = this.onUnassignedUpdateClick.bind(this);

        this.openUpdateJobModal = this.openUpdateJobModal.bind(this);
        this.showUpdateJobModal = this.showUpdateJobModal.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.unScheduleJobClose = this.unScheduleJobClose.bind(this);
        this.onCancelUpdateClick = this.onCancelUpdateClick.bind(this);
        this.onCancelUnScheduleCLosejobClick = this.onCancelUnScheduleCLosejobClick.bind(this);

        this.openCloseJobModal = this.openCloseJobModal.bind(this);
        this.showCloseJobModal = this.showCloseJobModal.bind(this);
        //this.onCloseClick = this.onCloseClick.bind(this);

        this.openDeferJobModal = this.openDeferJobModal.bind(this);
        this.showDeferJobModal = this.showDeferJobModal.bind(this);
        this.onDeferClick = this.onDeferClick.bind(this);

        // this.closeOpportunity = this.closeOpportunity.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onAlertCancelClick = this.onAlertCancelClick.bind(this);

        this.onDetailsClick = this.onDetailsClick.bind(this);
        this.openAlertDetailsModal = this.openAlertDetailsModal.bind(this);

        this.onChangeScheduleDate = this.onChangeScheduleDate.bind(this);
        //this.onChangeScheduleTime = this.onChangeScheduleTime.bind(this);
        //this.onChangeScheduleDateTime = this.onChangeScheduleDateTime.bind(this);
        this.onChangeTechnician = this.onChangeTechnician.bind(this);
        this.onChangeVerification = this.onChangeVerification.bind(this);
        this.onChangeJobValue = this.onChangeJobValue.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.showUnscheduledJobClose = this.showUnscheduledJobClose.bind(this);

        //this.onChangeClosedTime = this.onChangeClosedTime.bind(this);
        //this.onChangeStatus = this.onChangeStatus.bind(this);

        this.state = {
            currentAccount: {
                parentAccountId: ""
            },
            currentPartner: {
                users: [
                    {
                        userId: null,
                        firstName: "",
                        lastName: "",
                        email: null,
                        isActive: null,
                        lastLogin: null,
                        roleList: null,
                        roleListId: [],
                        roleListIdStr: null
                    }
                ]
            },

            loading: false,
            opportunityLoading: false,
            tableLoading: false,
            alertMessagingLoading: false,
            expandLoading: false,

            message: "",
            showMessage: false,

            tableViewType: "Unscheduled",
            isSelected: -1,

            selectedTechnicianNumber: -1,
            selectedTechnician: jsTechnician,
            scheduledTime: null,
            closedTime: null,
            selectedVerification: "",
            notes: "",

            technicianList: [],
            opportunityList: [],
            unscheduledList: [],
            scheduledList: [],
            closedList: [],
            deferredList: [],
            alertMessaging: [],
            appliedclasses: [],
            unappliedclasses: [],

            currentHome: jsHouse,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            currentAsset: null,
            updateOpportunity: jsOpportunity,
            updateDetails: jsDetails,

            scheduledDateTime: "",
            scheduledDateTimeStr: "",
            alertDetail: null,
            showAlertDetails: false,
            detailsLoading: false, //true,

            columnList: [],
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },

            showScheduleJobConfirm: false,
            showUpdateJobConfirm: false,
            showCommentsConfirm: false,
            showAlertDetailsConfirm: false,
            showCloseJobConfirm: false,
            showDeferJobConfirm: false,
            showUnassignedTechConfirm: false,
            showUnassignedTechUpdateConfirm: false,
            scheduleDetails: jsScheduleDetails,
            currentPartnerSTDetails: jsPartner,
            isJobClose: false,
            showUnScheduleJobConfirm: false,
            pauseScreen: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {
            this.getOpportunities(this.props.currentCustomerId);
            this.getAlertMessaging();
            this.getAccount(this.props.currentPartnerId);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memOpportunities = memoize(this.getOpportunities);

    changeRadio(e, fromGetCurrentOpportunity = false, index = -1) {
        let x = e.target.value;
        let list = null;
        Event("Scheduling Radio Clicked", "User clicked a radio button in a scheduling screen", "Scheduling Radio Clicked");

        if (x === "Unscheduled")
            list = this.state.unscheduledList;
        else if (x === "Scheduled")
            list = this.state.scheduledList;
        else if (x === "Closed")
            list = this.state.closedList;
        else if (x == "Deferred")
            list = this.state.deferredList;

        var noSort = {
            enabled: false,
            type: null,
            reverse: false
        };

        var defaultList = this.columnSort(list, "DEFAULT", false);


        this.setState({
            tableViewType: x,
            tableLoading: false,//true,
            isSelected: -1,
            opportunityList: defaultList,
            columnList: defaultList,
            columnAltered: false,
            columnSort: noSort,
            currentOpportunityCustomer: jsCustomer,
            currentHome: jsHouse,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            selectedTechnician: jsTechnician,
            selectedTechnicianNumber: -1
        }, () => {
            if (fromGetCurrentOpportunity && index != -1) {
                this.onOpportunityClick(index);
            }
            else {
                this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: "" }) });
            }
        });
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        const jobList = this.state.opportunityList;
        let jobs = jobList;
        let colJobs = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colJobs = this.columnSort(jobs, "DEFAULT", false);
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colJobs = this.columnSort(jobs, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colJobs = this.columnSort(jobs, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colJobs = this.columnSort(jobs, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, columnList: colJobs }, () => {
            if (this.state.isSelected !== -1) {
                this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: "" }) });
                this.setState({ columnAltered: colAltered, isSelected: -1, currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, currentHome: jsHouse, selectedTechnician: jsTechnician });
            }
            else {
                this.setState({ columnAltered: colAltered });
            }
        });
    }

    columnSort(jobsList, col, isReverse) {
        let colJobs = [];
        let jobs = jobsList !== null && jobsList !== undefined && jobsList.length ? jobsList : [];

        if (col === "CUSTOMER") {
            colJobs = jobs.sort((a, b) => {
                const nameA = (a.customer == "" || a.customer == undefined) ? "" : a.customer.split(",")[0].toLocaleUpperCase();
                const nameB = (b.customer == "" || b.customer == undefined) ? "" : b.customer.split(",")[0].toLocaleUpperCase();

                if (nameA == "") {
                    if (nameB == "")
                        return 0;
                    else
                        return 1;
                }
                else {
                    if (nameB == "")
                        return -1;
                    else
                        return nameA.localeCompare(nameB);
                }
            });
        }
        else if (col === "CREATED") {
            colJobs = jobs.sort((a, b) => {
                const dateA = new Date(a.createdOnStr);
                const dateB = new Date(b.createdOnStr);
                return dateB - dateA;
            });

        }
        else if (col === "JOB") {
            colJobs = jobs.sort((a, b) => {
                const stringA = a.subject;
                const stringB = b.subject;

                return stringA.localeCompare(stringB);
            });
        }
        else if (col === "PRIORITY") {
            colJobs = jobs.sort((a, b) => {
                const valA = a.priority;
                const valB = b.priority;

                return valA - valB;
            });
        }
        else if (col === "SCHEDULEFOR") {
            colJobs = jobs.sort((a, b) => {
                const dateA = new Date(a.scheduledForStr);
                const dateB = new Date(b.scheduledForStr);
                return dateB - dateA;
            });
        }
        else if (col === "JOBCLOSE") {
            colJobs = jobs.sort((a, b) => {
                const dateA = new Date(a.closedOnStr);
                const dateB = new Date(b.closedOnStr);
                return dateB - dateA;
            });
        }
        else if (col === "DEFAULT") {
            colJobs = jobs.sort((a, b) => {
                const valA = a.priority;
                const valB = b.priority;

                if (valA == valB) {
                    const dateA = new Date(a.createdOnStr);
                    const dateB = new Date(b.createdOnStr);
                    return dateB - dateA;
                }
                else
                    return valA - valB;
            });
        }

        if (isReverse) {
            colJobs = colJobs.reverse();
        }

        return colJobs;
    }

    //details

    openAlertDetailsModal() {
        this.setState({
            showAlertDetailsConfirm: !this.state.showAlertDetailsConfirm
        }, () => {
            if (this.state.showAlertDetails)
                ModalView("alertDetails");
        });
    }

    async onDetailsClick() {
        this.openAlertDetailsModal();
        let alertId = this.state.currentOpportunityDetails.number.slice(1);
        this.setState({ detailsLoading: true }, async () => {
            await AlertService.getalertdetails(alertId)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ alertDetail: response.data, detailsLoading: true }, async () => {
                            let alert = response.data.alert.length > 0 ? response.data.alert[0] : {};
                            await AlertService.getalertasset(alert)
                                .then(response2 => {
                                    this.setState({ currentAsset: response2.data, detailsLoading: false });
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    //schedule

    openScheduleJobModal(job) {
        this.showScheduleJobModal();
    }

    showScheduleJobModal() {
        this.setState({
            showScheduleJobConfirm: !this.state.showScheduleJobConfirm
        }, () => {
            if (this.state.showScheduleJobConfirm)
                ModalView("scheduleJob");
        });
    }

    async getAccount(id) {
        await AccountService.getpartnerinfo(id)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        currentPartnerSTDetails: response.data
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    async onBookClick(scheduledDateStr, selectedTechId) {
        Event("Book Clicked", "User clicked book in Schedule Repairs", "Book Clicked");
        let errorMsg = "";
        let currJob = this.state.currentOpportunity;

        if (scheduledDateStr === "" || scheduledDateStr == null) {
            /*if (selectedTechId === "" || selectedTechId == null)
                errorMsg = "Scheduled time and technician are required to book.";
            else */
            errorMsg = "Scheduled time is required to book.";
        }
        /*else if (selectedTechId === "" || selectedTechId == null) {
            errorMsg = "Technician is required to book.";
        }*/

        if (errorMsg !== "") {
            this.setState({ message: errorMsg, showMessage: true });

            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 3000);
        }
        else {
            let myJsDetail = this.state.currentOpportunityDetails;
            myJsDetail.houseAssetId = this.state.currentHome.houseAssetId;
            myJsDetail.customerOrgId = this.state.currentOpportunityCustomer.accountId;
            myJsDetail.customerFirstName = this.state.currentOpportunityCustomer.firstName;
            myJsDetail.customerLastName = this.state.currentOpportunityCustomer.lastName;

            this.setState({
                currentJobDetails: jsDetails,
                expandLoading: true,
                showScheduleJobConfirm: false,
                scheduledDateTimeStr: scheduledDateStr
            }, async () => {
                if (selectedTechId === "" || selectedTechId == null) {
                    this.setState({
                        showUnassignedTechConfirm: true,
                        modalTitle: "Unassigned Technician",
                        modalMessage: "You are about to Schedule a Job without a Technician are you sure you'd like to continue?"
                    });
                }
                else {
                    await ScheduleService.updateopportunity(scheduledDateStr, selectedTechId, myJsDetail, this.state.currentHome.houseAssetId, this.state.currentOpportunityCustomer.accountId, this.state.currentOpportunityCustomer.firstName, this.state.currentOpportunityCustomer.lastName)
                        .then(response => {
                            if (this._isMounted) {
                                if (response.data === true) {
                                    this.setState({
                                        message: "The job was scheduled successfully!",
                                        showMessage: true,
                                        expandLoading: false
                                    }, () => {
                                        this.reloadTable();
                                        this.sortandReloadTable();
                                    });
                                }
                                else {
                                    this.setState({
                                        message: "Failed to schedule the job!",
                                        showMessage: true,
                                        expandLoading: false
                                    });
                                }

                                setTimeout(() => {
                                    this.setState({
                                        message: "",
                                        showMessage: false
                                    });
                                }, 3000);
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            });
        }
    }

    showUnassignedTechModal() {
        this.setState({
            showUnassignedTechConfirm: !this.state.showUnassignedTechConfirm,
            showUpdateJobConfirm: !this.state.showUpdateJobConfirm
        });
    }

    onUnassignedBookClick() {
        Event("Unassigned Book Clicked", "User clicked unassigned book in Schedule Repairs", "Unassigned Book Clicked");
        this.setState({
            showUnassignedTechConfirm: false
        }, async () => {
            await ScheduleService.updateopportunity(this.state.scheduledDateTimeStr, -1, this.state.currentOpportunityDetails) //myJsDetail, this.state.currentHome.houseAssetId, this.state.currentOpportunityCustomer.accountId, this.state.currentOpportunityCustomer.firstName, this.state.currentOpportunityCustomer.lastName)
                .then(response => {
                    if (this._isMounted) {
                        if (response.data === true) {
                            this.setState({
                                message: "The job was scheduled without a technician successfully!",
                                showMessage: true,
                                expandLoading: false
                            }, () => {
                                this.reloadTable();
                                this.sortandReloadTable();
                            });
                        }
                        else {
                            this.setState({
                                message: "Failed to schedule the job without a technician!",
                                showMessage: true,
                                expandLoading: false
                            });
                        }

                        setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 3000);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    showUnassignedTechUpdateModal() {
        this.setState({
            showUnassignedTechUpdateConfirm: !this.state.showUnassignedTechUpdateConfirm,
            showUpdateJobConfirm: !this.state.showUpdateJobConfirm
        });
    }

    onUnassignedUpdateClick() {
        Event("Unassigned Update Clicked", "User clicked unassigned update in Schedule Repairs", "Unassigned Update Clicked");
        this.setState({
            showUnassignedTechUpdateConfirm: false
        }, async () => {
            var dateStr = this.state.currentOpportunity.scheduledForTimeStr;
            if (this.state.scheduledDateTime != null && dateStr === "" || dateStr == null) {
                dateStr = moment(this.state.scheduledDateTime).format();
            }

            await ScheduleService.updateopportunity(dateStr, -1, this.state.currentOpportunityDetails)
                .then(response => {
                    if (this._isMounted) {
                        if (response.data === true) {
                            this.setState({
                                message: "The job was updated successfully!",
                                showMessage: true,
                                expandLoading: false
                            });
                        }
                        else {
                            this.setState({
                                message: "Failed to update the job!",
                                showMessage: true,
                                expandLoading: false
                            });
                        }

                        setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 3000);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    //update

    onCancelUpdateClick() {
        Event("Cancel Update Clicked", "User clicked cancel update in Schedule Repairs", "Cancel Update Clicked");
        this.setState({ currentOpportunity: this.state.updateOpportunity, currentOpportunityDetails: this.state.updateDetails, isJobClose: false });
    }

    onCancelUnScheduleCLosejobClick() {
        Event("Cancel Close Clicked", "User clicked cancel update in UnSchedule Repairs", "Cancel close Clicked");
        this.setState({ showUnScheduleJobConfirm: false }, () => {
            if (this.state.showUpdateJobConfirm)
                ModalView("closeJob");
        });
    }

    openUpdateJobModal(job) {
        this.setState({ updateOpportunity: this.state.currentOpportunity, updateDetails: this.state.currentOpportunityDetails }, () => {
            this.showUpdateJobModal();
        });
    }

    showUpdateJobModal() {
        this.setState({
            showUpdateJobConfirm: !this.state.showUpdateJobConfirm
        }, () => {
            if (this.state.showUpdateJobConfirm)
                ModalView("updateJob");
        });
    }

    onChangeScheduleDate(dateStr) {
        let dt = new Date(dateStr);
        this.setState(prevState => ({
            scheduledDateTime: dt,
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: dateStr,
                scheduledForStr: dateStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: dateStr,

            }
        }));
    }

    onChangeTechnician(selectedTech) {
        let name = selectedTech.firstName + " " + selectedTech.lastName;
        this.setState(prevState => ({
            selectedTechnician: selectedTech,
            currentOpportunity: {
                ...prevState.currentOpportunity,
                assignedToId: parseInt(selectedTech.userId, 10),
                assignedToName: name
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                assignedToId: parseInt(selectedTech.userId, 10),
                assignedToName: name
            }
        }));
    }

    onChangeVerification(selectedVer) {
        const verify = (selectedVer.value === "true");

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                isFalsePositive: verify
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                isFalsePositive: verify
            }
        }));
    }

    onChangeJobValue(impact) {
        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                impact: parseInt(impact, 10),
                impactEstimate: parseInt(impact, 10)
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                impactEstimate: parseInt(impact, 10),
                impactType: "Money",
                impactTypeId: 1
            }
        }));
    }

    onChangeNotes(notes) {
        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                comment: notes
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                comment: notes
            }
        }));
    }


    // pauseScreen

    async unScheduleJobClose() {
        let myJsDetail = this.state.currentOpportunity;
        myJsDetail.status = "Closed"

        this.setState({ pauseScreen: false }, async () => {
            await ScheduleService.closeOpportunity(this.props?.userId, myJsDetail)
                .then(response => {
                    if (this._isMounted) {
                        if (response.data === true) {
                            this.setState({
                                message: "The opportunity was successfully closed!",
                                showMessage: "The opportunity was successfully closed!",
                                expandLoading: false,
                                showUnScheduleJobConfirm: false
                            }, () => {
                                this.reloadTable();
                                this.sortandReloadTable();
                            });

                            setTimeout(() => {
                                this.setState({
                                    message: "",
                                    showMessage: false,
                                    pauseScreen: true
                                });
                            }, 3000);
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }


    async onUpdateClick() {
        Event("Update Job Clicked", "User clicked update in Schedule Repairs", "Update Job Clicked");
        let errorMsg = "";
        let job = this.state.currentOpportunity;
        if ((this.state.tableViewType == "Closed" || this.state.tableViewType == "Unscheduled") && this.state.scheduledDateTime != null && job.scheduledForTimeStr === "" || job.scheduledForTimeStr == null) {
            job.scheduledForTimeStr = moment(this.state.scheduledDateTime).format();
        }

        if (job.scheduledForTimeStr === "" || job.scheduledForTimeStr == null) {
            /*if (job.assignedToId === "" || job.assignedToId == null)
                errorMsg = "Scheduled time and technician are required to update.";
            else*/
            errorMsg = "Scheduled time is required to update.";
        }
        /*else if (job.assignedToId === "" || job.assignedToId == null) {
            errorMsg = "Technician is required to update.";
        }*/

        if (errorMsg !== "") {
            this.setState({ message: errorMsg, showMessage: true });

            setTimeout(() => {
                this.setState({
                    message: "",
                    showMessage: false
                });
            }, 3000);
        }
        else {
            let myJsDetail = this.state.currentOpportunityDetails;
            myJsDetail.houseAssetId = this.state.currentHome !== undefined ? this.state.currentHome.houseAssetId : null;
            if (this.state.currentOpportunityCustomer !== undefined) {
                myJsDetail.customerOrgId = this.state.currentOpportunityCustomer.accountId;
                myJsDetail.customerFirstName = this.state.currentOpportunityCustomer.firstName;
                myJsDetail.customerLastName = this.state.currentOpportunityCustomer.lastName;
            }
            //myJsDetail.isFalsePositive = job.isFalsePositive;
            //myJsDetail.impactEstimate = job.impactEstimate;
            //myJsDetail.comment = job.comment;

            if (this.state.isJobClose) {
                myJsDetail.status = "Closed"
            }

            this.setState({
                currentOpportunity: job,
                currentOpportunityDetails: myJsDetail,
                expandLoading: true,
                showUpdateJobConfirm: false
            }, async () => {
                if ((job.assignedToId === "" || job.assignedToId == null) && this.state.currentPartnerSTDetails.betaST) {
                    this.onUnassignedUpdateClick()

                }
                else if (job.assignedToId === "" || job.assignedToId == null) {
                    this.setState({
                        showUnassignedTechUpdateConfirm: true,
                        modalTitle: "Unassigned Technician",
                        modalMessage: "You are about to Update a Job without a Technician are you sure you'd like to continue?"
                    });
                }
                else {
                    await ScheduleService.updateopportunity(job.scheduledForTimeStr, job.assignedToId, myJsDetail)
                        .then(response => {
                            if (this._isMounted) {
                                if (response.data === true) {
                                    this.setState({
                                        message: ""
                                    },
                                        async () => {
                                            if (this.state.isJobClose) {
                                                await this.reclassRepairedAsset();
                                                if (this.state.currentOpportunityDetails !== null && this.state.currentOpportunityDetails.number !== null && this.state.currentOpportunityDetails.number.includes("A")) {
                                                    await this.getAlerts();
                                                }
                                                else {
                                                    this.setState({ message: "The opportunity was successfully closed! No associated alert to close found.", showMessage: true, opportunityLoading: false });

                                                    setTimeout(() => {
                                                        this.setState({
                                                            message: "",
                                                            showMessage: false
                                                        });
                                                    }, 3000);
                                                }
                                                this.reloadTable();
                                                this.sortandReloadTable();
                                            }
                                        });
                                    let userMessage = "";
                                    let showuserMessage = false;

                                    if (!this.state.isJobClose) {
                                        userMessage = "The job was updated successfully!";
                                        showuserMessage = true;
                                    }

                                    this.setState({
                                        message: userMessage,
                                        showMessage: showuserMessage,
                                        expandLoading: false
                                    }, () => {
                                        this.reloadTable();
                                        this.sortandReloadTable();
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            message: "",
                                            showMessage: false
                                        });
                                    }, 3000);
                                }
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            });
        }
    }

    //defer

    openDeferJobModal() {
        this.setState({ modalTitle: "Defer Job", modalMessage: "You are about to defer this job. Are you sure?" }, () => {
            this.showDeferJobModal();
        });
    }

    showDeferJobModal() {
        this.setState({ showDeferJobConfirm: !this.state.showDeferJobConfirm }, () => {
            if (this.state.showDeferJobConfirm)
                ModalView("deferJob");
        });
    }

    onDeferClick() {
        Event("Defer Clicked", "User clicked to defer job in Schedule Repairs", "Defer Clicked");
        this.setState({ showDeferJobConfirm: false, opportunityLoading: true }, async () => {
            await ScheduleService.updateopportunitystatus(this.state.currentOpportunity.caseId, "Deferred")
                .then(response => {
                    if (response.data === true) {
                        this.setState({
                            message: "The job was deferred successfully",
                            showMessage: true
                        });

                        this.reloadTable();
                    }
                    else {
                        this.setState({
                            message: "Failed to defer the job",
                            showMessage: true
                        });
                    }

                    setTimeout(() => {
                        this.setState({
                            message: "",
                            showMessage: false
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    //close

    openCloseJobModal() {

        if (this.state.tableViewType != "Unscheduled" && this.state.tableViewType != "Deferred" ) {
            this.showCloseJobModal();
        }
        else {

            this.setState(prevState => ({
                selectedTechnicianId: this.props?.userId,
                currentOpportunity: {
                    ...prevState.currentOpportunity,
                    assignedToName: this.props?.currentAccount?.firstName + " " + this.props?.currentAccount?.lastName,
                    verification: true,
                    scheduledForTimeStr: new Date().toDateString(),
                    assignedToId: this.props?.userId
                }

            }), () => { this.showUnscheduledJobClose(); });


        }
        // if (this.state.currentOpportunity.assignedToId === "" || this.state.currentOpportunity.assignedToId == null) {
        //     this.setState({ message: "Please assign a technician to the job before closing.", showMessage: true });

        //     setTimeout(() => {
        //         this.setState({
        //             message: "",
        //             showMessage: false
        //         });
        //     }, 3000);
        // }
        // else {
        //     this.setState({ modalTitle: "Close Job", modalMessage: "You are about to close this job. Are you sure?" }, () => {
        //         this.showCloseJobModal();
        //     });
        // }
    }


    showUnscheduledJobClose() {
        // this.setState({ showCloseJobConfirm: !this.state.showCloseJobConfirm });
        this.setState({ showUnScheduleJobConfirm: !this.state.showUnScheduleJobConfirm, isJobClose: true }, () => {
            if (this.state.showUpdateJobConfirm)
                ModalView("closeJob");
        });
    }

    showCloseJobModal() {
        // this.setState({ showCloseJobConfirm: !this.state.showCloseJobConfirm });
        this.setState({ showUpdateJobConfirm: !this.state.showUpdateJobConfirm, isJobClose: true }, () => {
            if (this.state.showUpdateJobConfirm)
                ModalView("closeJob");
        });
    }

    // onCloseClick() {
    //     let errorMsg = "";
    //     let errorFields = [];

    //     if (this.state.currentOpportunity.scheduledForTimeStr === "" && !this.state.currentPartnerSTDetails.betaST)
    //         errorFields.push("Scheduled Time");
    //     if ((this.state.currentOpportunity.assignedToId === "" || this.state.currentOpportunity.assignedToId == null) &&  !this.state.currentPartnerSTDetails.betaST)
    //         errorFields.push("Technician");
    //     if (this.state.currentOpportunityDetails.isFalsePositive == null)
    //         errorFields.push("Verification"); 
    //     if (this.state.currentOpportunityDetails.impactEstimate === "" || this.state.currentOpportunityDetails.impactEstimate == null)
    //         errorFields.push("Job Value");

    //     errorMsg = errorFields.join(", ");

    //     if (errorMsg !== "") {
    //         var txt = " is ";
    //         if (errorFields.length > 1) txt = " are ";
    //         this.setState({ message: errorMsg + txt + "required to close opportunity.", showMessage: true });

    //         setTimeout(() => {
    //             this.setState({
    //                 message: "",
    //                 showMessage: false
    //             });
    //         }, 3000);
    //     }
    //     else {
    //         this.setState(prevState => ({
    //             showCloseJobConfirm: false,
    //             currentOpportunityDetails: {
    //                 ...prevState.currentOpportunityDetails,
    //                 status: "Closed"
    //             }
    //         }), () => {
    //             this.closeOpportunity();
    //         });
    //     }
    // }

    async getAlerts() {
        var baseAlert = this.state.alertDetail.alert[0];
        var now = moment();
        var before = moment(alertSearchStartDate); //moment(now).add(-2, 'weeks'); //two weeks ago; set only for demo set 04/01/21
        var nowStr = now.format();
        var beforeStr = before.format();
        this.setState({
            alertArgs: {
                alertTypeIds: [baseAlert.alertTypeId.toString()],
                startTime: beforeStr,
                endTime: nowStr,
                isClosed: false,
                isActive: baseAlert.endTime != null ? false : true,
                isMuted: baseAlert.isMuted != null ? baseAlert.isMuted : false,
                parentAssetId: baseAlert.assetId
            }
        }, async () => {
            var startTime = performance.now();
            await AlertService.getalertlist("Consolidated", this.state.alertArgs)
                .then(response => {
                    if (this._isMounted)
                        this.setState({
                            alertList: response.data
                        }, async () => {
                            await AlertService.triagealerts("CLOSEINTERNAL", response.data)
                                .then(response2 => {
                                    if (response2.status === 200) {
                                        this.setState({
                                            message: "Alerts closed successfully!"
                                        });
                                        Event("Close Opportunity Clicked", "User clicked close opportunity button", "Close Opportunity Clicked");
                                    }
                                });
                            //}
                            this.reloadTable();
                            this.setState({ loading: false, alertTableLoading: false }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("LifeWhere Triage Table Loading", "loading", elapsedTime, "LifeWhere Triage Alerts Table List Loading");
                            });
                        });
                });
        });
    }

    // async closeOpportunity() {
    //     this.setState({ opportunityLoading: true });
    //     await ScheduleService.updateopportunity(this.state.currentOpportunity.scheduledForTimeStr, this.state.currentOpportunityDetails.assignedToId, this.state.currentOpportunityDetails)
    //         .then(response => {
    //             if (response.data === true) {
    //                 this.setState({
    //                     message: ""
    //                 }, async () => {
    //                         await this.reclassRepairedAsset();
    //                         if (this.state.currentOpportunityDetails !== null && this.state.currentOpportunityDetails.number !== null && this.state.currentOpportunityDetails.number.includes("A")) {
    //                             await this.getAlerts();
    //                         }
    //                         else {
    //                             this.setState({ message: "The opportunity was successfully closed! No associated alert to close found.", showMessage: true, opportunityLoading: false });
    //                         }

    //                     this.reloadTable();
    //                 });
    //             }
    //             else {
    //                 this.setState({
    //                     message: "Failed to close the opportunity!",
    //                     showMessage: true,
    //                     opportunityLoading: false
    //                 });
    //             }

    //             setTimeout(() => {
    //                 this.setState({
    //                     message: "",
    //                     showMessage: false
    //                 });
    //             }, 3000);
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // }

    async reclassRepairedAsset() {
        // GET CURRENT CLASSES
        try {
            var assetPath = this.state.currentOpportunity.drill.split("/");
            var assetId = assetPath[assetPath.length - 1];
            var message = "";

            await AssetService.getappliedclasses(assetId)
                .then(response => {
                    this.setState({ appliedClasses: response.data }, async () => {
                        await AssetService.getunappliedclasses(assetId).then(response2 => {
                            this.setState({ unappliedClasses: response2.data }, () => {
                                // Check if Reprovision Class exists on the asset:
                                let isReprovision = this.state.appliedClasses != [] && this.state.appliedClasses.length && this.state.appliedClasses.findIndex(c => c.name === "Reprovision") > -1 ? true : false;
                                let isAwaiting = this.state.appliedClasses != [] && this.state.appliedClasses.length && this.state.appliedClasses.findIndex(c => c.name === "AwaitingData") > -1 ? true : false;
                                let isRetrain = this.state.appliedClasses != [] && this.state.appliedClasses.length && this.state.appliedClasses.findIndex(c => c.name === "Retrain") > -1 ? true : false;

                                if (isAwaiting || isReprovision) {
                                    let removeList = [];
                                    const reprovisionObject = isReprovision && this.state.appliedClasses.find(obj => obj.name === "Reprovision");
                                    const awaitingDataObject = isAwaiting && this.state.appliedClasses.find(obj => obj.name === "AwaitingData");
                                    (reprovisionObject?.classId != null && reprovisionObject?.classId != undefined) && removeList.push(reprovisionObject.classId);
                                    (awaitingDataObject?.classId != null && awaitingDataObject?.classId != undefined) && removeList.push(awaitingDataObject.classId);
                                    (removeList.length > 0) && AssetService.removeclasses(assetId, removeList).then(response4 => {
                                        if (response4.status === 200) {
                                            message = "Successfully updated Asset classes!";
                                        }
                                        else {
                                            message = "Failed to remove Asset classes!";
                                        }
                                    });
                                }
                                if (!isRetrain) {
                                    const retrainDataObject = this.state.unappliedClasses.find(obj => obj.name === "Retrain");
                                    if (retrainDataObject?.classId !== undefined && retrainDataObject?.classId !== null) {
                                        let applyList = [retrainDataObject.classId];
                                         AssetService.applyclasses(assetId, applyList).then(response3 => {
                                            if (response3.status === 200) {
                                                message = "Successfully applied Asset classes!";
                                            }
                                            else {
                                                message = "Failed to apply Asset classes!";
                                            }
                                        });
                                    }
                                }
                               
                               
                            });
                        });

                    });
                })
                .catch(e => {
                    console.log(e);
                });
        }
        catch (error) {
            var errorMessage = error;
        }
    }


    // general 

    onAlertCancelClick() {
        this.setState({ alertDetail: null });
    };

    onCancelClick() {
        Event("Cancel Opportunity Clicked", "User clicked button to cancel changes to opportunity", "Cancel Opportunity Changes Clicked");
        this.setState({ currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, message: "Changes canceled!", scheduledDateTime: "" }, () => {
            this.setState({ currentOpportunity: this.state.opportunityList[this.state.isSelected] }, () => {
                this.setState({ currentOpportunityDetails: this.state.currentOpportunity.details, isJobClose: false });
                if (this.state.currentOpportunity.scheduledForTimeStr !== null && this.state.currentOpportunity.scheduledForTimeStr !== "" && this.state.currentOpportunity.scheduledForTimeStr !== undefined) {
                    let dt = new Date(this.state.currentOpportunity.scheduledForTimeStr);
                    this.setState({ scheduledDateTime: dt });
                }
            })
        });


        setTimeout(() => {
            this.setState({
                message: ""
            });
        }, 3000);

    }

    onOpportunityClick(index) {
        let x = index;
        var startTime = performance.now();
        Event("Opportunity Clicked", "User clicked on an opportunity in the table", "Opportunity Clicked");

        if (this.state.isSelected == x) {
            this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: "" }) });
            this.setState({ isSelected: -1, currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, currentHome: jsHouse, selectedTechnician: jsTechnician });
        }
        else {
            this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: this.state.opportunityList[x].number }) });

            this.setState({ currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, currentHome: jsHouse, selectedTechnician: jsTechnician, opportunityLoading: true, expandLoading: true, isSelected: index }, () => {
                this.setState({ currentOpportunity: this.state.opportunityList[x] }, async () => {
                    await ScheduleService.getopportunitydetails(this.state.currentOpportunity.caseId)
                        .then(response => {
                            if (this._isMounted)
                                var technicianDetails = this.state.selectedTechnician;
                            technicianDetails.firstName = response?.data?.assignedToName
                            technicianDetails.lastName = response?.data?.assignedToName
                            technicianDetails.userId = response?.data?.assignedToId;
                            this.setState({ currentOpportunityDetails: response.data, selectedTechnician: technicianDetails }, async () => {
                                if (this.state.currentOpportunityDetails.organizationId !== null && this.state.currentOpportunityDetails.organizationId !== "null" && this.state.currentOpportunityDetails.organizationId !== undefined) {
                                    await AccountService.get(this.state.currentOpportunityDetails.organizationId, false)
                                        .then(response2 => {
                                            if (this._isMounted)
                                                this.setState({ currentOpportunityCustomer: response2.data }, async () => {
                                                    await AccountService.getpartnerinfo(this.state.currentOpportunityCustomer.parentAccountId, true)
                                                        .then(response3 => {
                                                            if (this._isMounted)
                                                                this.setState({ currentPartner: response3.data }, () => {
                                                                    this.setState({ detailsLoading: true, technicianList: this.state.currentPartner.users.filter(user => user.roleList.includes("Technician")) }, async () => {
                                                                        if (this.state.currentOpportunityDetails.number.includes("A")) {
                                                                            let alertId = this.state.currentOpportunityDetails.number.slice(1);
                                                                            AlertService.getalertdetails(alertId)
                                                                                .then(response4 => {
                                                                                    this.setState({ alertDetail: response4.data, detailsLoading: true }, async () => {
                                                                                        let alert = response4.data.alert.length > 0 ? response4.data.alert[0] : {};
                                                                                        await AlertService.getalertasset(alert)
                                                                                            .then(response5 => {
                                                                                                this.setState({ currentAsset: response5.data, detailsLoading: false });
                                                                                            })
                                                                                            .catch(e => {
                                                                                                console.log(e);
                                                                                            });
                                                                                    });
                                                                                })
                                                                                .catch(e => {
                                                                                    console.log(e);
                                                                                });
                                                                            await ServiceTitanService.getScheduleDetails(this.state.currentOpportunity.caseId)
                                                                                .then(response4 => {
                                                                                    if (this._isMounted) {
                                                                                        var responseDetails = response4.data;
                                                                                        var details = jsScheduleDetails;

                                                                                        details.stID = responseDetails?.leadId;
                                                                                        details.lwJobID = responseDetails?.opportunitieId;

                                                                                        if (responseDetails === "" || responseDetails?.scheduledDate === "0001-01-01T00:00:00" || responseDetails?.scheduledDate === "") {
                                                                                            details.scheduledDate = this.state.currentOpportunity.scheduledForStr;
                                                                                        }
                                                                                        if (responseDetails === "" || responseDetails?.technician == null || responseDetails?.technician == "") {
                                                                                            let assignedToObj = this.state.technicianList?.find(x => x.userId == this.state.currentOpportunity.assignedToId);
                                                                                            if (assignedToObj != "undefined" && assignedToObj != null && assignedToObj != "") {
                                                                                                details.technician = assignedToObj?.lastName + ", " + assignedToObj?.firstName + " (" + assignedToObj?.userId + ")";
                                                                                            } else { details.technician = ""; }
                                                                                        }
                                                                                        if (responseDetails === "" || responseDetails?.jobStatus == null || responseDetails?.jobStatus == "") { details.jobStatus = this.state.currentOpportunity.status; }
                                                                                        if (responseDetails === "" || responseDetails?.jobvalue == null || responseDetails?.jobvalue == "") { details.jobvalue = this.state.currentOpportunity.impact; }
                                                                                        if (responseDetails === "" || responseDetails?.jobNotes == null || responseDetails?.jobNotes == "") { details.jobNotes = this.state.currentOpportunity.comment; }
                                                                                        if (responseDetails !== "" && responseDetails?.jobValue != null && responseDetails?.jobValue != "" && responseDetails?.jobValue?.includes("||")) {
                                                                                            let jobvalue = responseDetails?.jobValue?.split("||");
                                                                                            let total = (jobvalue != null) ? jobvalue[1] : "00";
                                                                                            details.invoiceTotal = total;
                                                                                        }


                                                                                        this.setState({ scheduleDetails: details, expandLoading: false });
                                                                                    }
                                                                                }).catch(e => {
                                                                                    this.setState({ expandLoading: false });
                                                                                    console.log(e);
                                                                                });
                                                                        }

                                                                    });
                                                                });
                                                        });
                                                });
                                        });
                                    await HomeService.get(this.state.currentOpportunityDetails.organizationId)
                                        .then(response6 => {
                                            if (this._isMounted)
                                                this.setState({ currentHome: response6.data[0], opportunityLoading: false });
                                        });
                                }
                                else {

                                    this.setState({
                                        opportunityLoading: false,
                                        expandLoading: false,
                                        message: "Invalid customer for appointment"
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            message: ""
                                        });
                                    }, 3000);
                                }

                                if (this.state.currentOpportunityDetails.scheduledFor !== null && this.state.currentOpportunityDetails.scheduledFor !== "" && this.state.currentOpportunityDetails.scheduledFor !== undefined) {
                                    let dt = new Date(this.state.currentOpportunityDetails.scheduledFor.match(/\d+/)[0] * 1);
                                    this.setState({ scheduledDateTime: dt });
                                }
                            });
                        }).catch(e => {
                            console.log(e);
                        });
                });
            });
        }
    }

    async getOpportunities(id) {
        var startTime = performance.now();
        var now = moment();
        var nowStr = moment(now).format();
        var beforeStr = moment(now).add(-3, "months").format();
        this.setState({
            beginStr: beforeStr,
            endStr: nowStr,
            loading: true,
            isSelected: -1,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            tableViewType: this.state.tableViewType
        }, async () => {
            await ScheduleService.getopportunities(beforeStr, nowStr)
                .then(response3 => {
                    if (this._isMounted)
                        var technicianDetails = this.state.selectedTechnician;
                    technicianDetails.firstName = response3?.data?.assignedToName
                    technicianDetails.lastName = response3?.data?.assignedToName
                    technicianDetails.userId = response3?.data?.assignedToId;
                    this.setState({ opportunityList: response3.data, selectedTechnician: technicianDetails, loading: false }, () => {
                        this.setState({ unscheduledList: this.state.opportunityList["Unscheduled"], scheduledList: this.state.opportunityList["Scheduled"], closedList: this.state.opportunityList["Closed"], deferredList: this.state.opportunityList["Deferred"], opportunityList: this.state.opportunityList[this.state.tableViewType] }, () => {
                            this.getCurrentOpportunity();
                        });
                        var elapsedTime = performance.now() - startTime;
                        Timing("Opportunity List Loading", "loading", elapsedTime, "Opportunity Table List Loading");
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    async getAlertMessaging() {
        this.setState({
            alertMessagingLoading: true
        }, async () => {
            await alerttypemessagingService.getalertmessaging()
                .then(response3 => {
                    if (this._isMounted)
                        this.setState({ alertMessaging: response3, alertMessagingLoading: false });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    async getCurrentOpportunity() {
        const jobNum = new URLSearchParams(this.props.location.search).get("jobNumber");
        if (jobNum) {
            var index = this.state.unscheduledList.findIndex(i => i.number == jobNum);

            if (index != -1) {
                var e = { target: { value: "Unscheduled" } }
                this.changeRadio(e, true, index);
            }
            else {
                index = this.state.scheduledList.findIndex(i => i.number == jobNum);
                if (index != -1) {
                    var e = { target: { value: "Scheduled" } }
                    this.changeRadio(e, true, index);
                }
                else {
                    index = this.state.closedList.findIndex(i => i.number == jobNum);
                    if (index != -1) {
                        var e = { target: { value: "Closed" } }
                        this.changeRadio(e, true, index);
                    }
                    else {
                        index = this.state.deferredList.findIndex(i => i.number == jobNum);
                        if (index != -1) {
                            var e = { target: { value: "Deferred" } }
                            this.changeRadio(e, true, index);
                        }
                        else
                            this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: "" }) });
                    }
                }
            }
        }
    }

    async reloadTable() {
        this.setState({
            expandLoading: true,
            isSelected: -1,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            selectedTechnician: jsTechnician,
            selectedTechnicianNumber: -1,
            currentHome: jsHouse,
            scheduledDateTime: ""
        }, async () => {
            this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: "" }) });
            await ScheduleService.getopportunities(this.state.beginStr, this.state.endStr)
                .then(response => {
                    if (this._isMounted)
                        this.setState({
                            opportunityList: response.data,
                            technicianList: this.state.currentPartner.users.filter(user => user.roleList.includes("Technician")),
                            opportunityLoading: false
                        }, () => {
                            this.setState({ expandLoading: false, unscheduledList: this.state.opportunityList["Unscheduled"], scheduledList: this.state.opportunityList["Scheduled"], closedList: this.state.opportunityList["Closed"], deferredList: this.state.opportunityList["Deferred"], opportunityList: this.state.opportunityList[this.state.tableViewType] }, () => { this.sortandReloadTable(); });

                        });
                }).catch(e => {
                    console.log(e);
                });

        });
    }

    async sortandReloadTable() {

        const colSort = this.state.columnSort;
        let newSort = colSort;
        const jobList = this.state.opportunityList;
        let jobs = jobList;
        let colJobs = [];
        let colAltered = false;

        colJobs = this.columnSort(jobs, colSort.type, colSort.reverse);

        this.setState({ columnSort: newSort, columnList: colJobs }, () => {
            if (this.state.isSelected !== -1) {
                this.props.history.push({ search: "?" + new URLSearchParams({ jobNumber: "" }) });
                this.setState({ columnAltered: colAltered, isSelected: -1, currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, currentHome: jsHouse, selectedTechnician: jsTechnician });
            }
            else {
                this.setState({ columnAltered: colAltered });
            }
        });

    }

    render() {
        const { classes } = this.props;

        const params = new URLSearchParams(this.props.location.search).get("jobNumber");

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Schedule Repairs
                    </h2>

                    <ScheduleRepairsTable changeTableRadio={this.changeRadio} tableRadio={this.state.tableViewType} loading={this.state.loading} tableLoading={this.state.tableLoading} expandLoading={this.state.expandLoading}
                        opportunityList={this.state.opportunityList} technicianList={this.state.technicianList} columnList={this.state.columnList} columnAltered={this.state.columnAltered} columnSort={this.state.columnSort} onColumnClick={this.onColumnClick}
                        selected={this.state.isSelected} onJobClick={this.onOpportunityClick} _isMounted={this._isMounted} history={this.props.history} location={this.props.location} alertMessaging={this.state.alertMessaging}
                        currentJob={this.state.currentOpportunity} currentJobDetails={this.state.currentOpportunityDetails} currentJobCustomer={this.state.currentOpportunityCustomer} currentHome={this.state.currentHome}
                        onDetailsClick={this.onDetailsClick} openAlertDetailsModal={this.openAlertDetailsModal} openScheduleJobModal={this.openScheduleJobModal} openUpdateJobModal={this.openUpdateJobModal} openCloseJobModal={this.openCloseJobModal} openDeferJobModal={this.openDeferJobModal}
                        scheduleDetails={this.state.scheduleDetails} currentPartnerSTDetails={this.state.currentPartnerSTDetails}
                    />

                    <AlertDetailsModal onClick={this.openAlertDetailsModal} onCancelClick={this.onAlertCancelClick} show={this.state.showAlertDetailsConfirm} details={this.state.alertDetail} messaging={this.state.alertMessaging} asset={this.state.currentAsset} detailsLoading={this.state.detailsLoading} ></AlertDetailsModal>

                    <ConfirmationModal show={this.state.showCloseJobConfirm} onYes={this.onCloseClick} onClick={this.showCloseJobModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal show={this.state.showDeferJobConfirm} onYes={this.onDeferClick} onClick={this.showDeferJobModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal show={this.state.showUnassignedTechConfirm} onYes={this.onUnassignedBookClick} onClick={this.showUnassignedTechModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    <ConfirmationModal show={this.state.showUnassignedTechUpdateConfirm} onYes={this.onUnassignedUpdateClick} onClick={this.showUnassignedTechUpdateModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>

                    <ScheduleJobModal show={this.state.showScheduleJobConfirm} onClick={this.showScheduleJobModal} title={this.state.modalTitle}
                        currentJob={this.state.currentOpportunity} technicianList={this.state.technicianList}
                        onBook={this.onBookClick}>
                    </ScheduleJobModal>

                    <UpdateJobModal show={this.state.showUpdateJobConfirm} onClick={this.showUpdateJobModal} onCancelClick={this.onCancelUpdateClick} title={this.state.modalTitle}
                        currentJob={this.state.currentOpportunity} currentJobDetails={this.state.currentOpportunityDetails} updateJob={this.state.updateOpportunity} technicianList={this.state.technicianList} selectedTechnician={this.state.selectedTechnician} scheduledDate={this.state.scheduledDateTime}
                        onChangeDate={this.onChangeScheduleDate} onChangeTechnician={this.onChangeTechnician} onChangeVerification={this.onChangeVerification}
                        onChangeJobValue={this.onChangeJobValue} onChangeNotes={this.onChangeNotes} onUpdate={this.onUpdateClick} currentPartnerSTDetails={this.state.currentPartnerSTDetails} selectedTab={this.state.tableViewType} isJobClose={this.state.isJobClose}>
                    </UpdateJobModal>
                    <UnScheduleJObCloseModel show={this.state.showUnScheduleJobConfirm} onClick={this.onCancelUnScheduleCLosejobClick} title={this.state.modalTitle}
                        unScheduleJobClose={this.unScheduleJobClose} currentJobDetails={this.state.currentOpportunity} pauseScreen={this.state.pauseScreen}>
                    </UnScheduleJObCloseModel>

                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ScheduleRepairs);