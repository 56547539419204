/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { useRef, Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import MapPointModal from './MapPointModal';
import CustomerService from "../../services/customer.service";
import { Spinner } from 'react-bootstrap';
import ReactMapGL, { Marker, Popup, NavigationControl } from 'react-map-gl';

import { jsPoint, jsCustomer } from "../../componentObjects";
import memoize from "memoize-one";
import { withStyles } from '@material-ui/core/styles';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoicmVzaWRlb2xpZmV3aGVyZSIsImEiOiJja3RqMW15OTMwbHg0Mm9wZHQycGljazhrIn0.U6LhQ9Frvcjc381pkdJtZg';
const TOKEN = 'pk.eyJ1IjoicmVzaWRlb2xpZmV3aGVyZSIsImEiOiJja3RqMW15OTMwbHg0Mm9wZHQycGljazhrIn0.U6LhQ9Frvcjc381pkdJtZg';
const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const navStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px"
};

const pinStyle = {
    cursor: "pointer",
    fill: "#d00",
    stroke: "none"
};

const borderStyle = {
    border: "5px solid #838385",
    marginLeft : "15px"
};

class Map extends Component {
    static displayName = Map.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.mapContainer = React.createRef();
        this.map = React.createRef();
        this.marker1 = React.createRef();
        this.getPoints = this.getPoints.bind(this);
        this.updateViewport = this.updateViewport.bind(this);
        this.onPointClick = this.onPointClick.bind(this);
        this.openPointModal = this.openPointModal.bind(this);
        this.showPointModal = this.showPointModal.bind(this);
        this.fitViewPort = this.fitViewPort.bind(this);

        this.state = {
            viewport: {
                latitude: 37.785164,
                longitude: -96,
                zoom: 4,
                bearing: 0,
                pitch: 0
            },
            currentPoint: jsPoint,
            currentCustomer: jsCustomer,
            pointList: [],
            assetList: [],
            lng: -70.9,
            lat: 42.35,
            zoom: 2,
            loading: false,
            showPointModal: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (this.props.currentHomeAssetId != "") {
            this.getPoints(this.props.currentPartnerId, this.props.currentHomeAssetId);
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.currentHomeAssetId !== prevProps.currentHomeAssetId) {
            this.getPoints(this.props.currentPartnerId, this.props.currentHomeAssetId);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //memMapData = memoize(this.getPoints);

    updateViewport = viewport => {
        this.setState({ viewport });
    };

    openPointModal() {
        this.showPointModal();
    }

    showPointModal() {
        this.setState({
            showPointModal: !this.state.showPointModal
        }, () => {
            ModalView("mapPoint");
        });
        window.scrollTo(0, 0);
    }

    getPartnerName(id) {
        var partner = this.props.partnerList.find(p => p.organizationId === id);
        var partnerName = partner.name;
        return partnerName
    }

    async fitViewPort(points) {
        let totalPts = points.length;
        let totalLat = 0;
        let totalLong = 0;
        // todo check for zoom level using min/max longs/lats? 
        let i = 0;

        for (i = 0; i < totalPts; i++) {
            totalLat += points[i].latitude;
            totalLong += points[i].longitude;
        }

        totalLat = totalLat / totalPts;
        totalLong = totalLong / totalPts;

        this.setState(prevState => ({
            viewport: {
                latitude: totalLat,
                longitude: totalLong,
                zoom: 4
            }
        }), () => {
            console.log("long = " + this.state.viewport.longitude + ", lat = " + this.state.viewport.latitude);
        });
    }

    async onPointClick(index) {
        Event("Map Point clicked", "User clicked a point on the map", "Map Point clicked");
        var x = index;
        this.setState({ currentPoint: jsPoint, loading: true }, () => {
            this.setState({ currentPoint: this.state.pointList[index], currentCustomer: jsCustomer, showPointModal: false }, async () => {
                await CustomerService.getmapcustomer(this.state.currentPoint.houseAssetId)
                    .then(response => {
                        this.setState({ currentCustomer: response.data, loading: false }, () => {
                            this.openPointModal();
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        });
    }

    async getPoints(id, homeAssetId) {
        var partnerName = await this.getPartnerName(id);
        this.setState({ loading: true }, async () => {
            await CustomerService.getmappoints(partnerName, homeAssetId)
                .then(response => {
                    if (this._isMounted)
                        this.setState({ pointList: response.data !== null && response.data !== undefined ? response.data : [] }, async () => {
                            if (this.state.pointList?.length > 0) {
                                await this.fitViewPort(this.state.pointList);
                            }
                            this.setState({ loading: false });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        });

    }

    getTooltip(point) {
        return (
            <Tooltip id="tooltip-point">
                <small>
                    <div className="row no-gutters">
                        <p>{"Address: " + point.address}</p>
                    </div>
                    <div className="row no-gutters">
                        <p>{"Open Active Alerts: " + point.openActiveAlerts}</p>
                    </div>
                    <div className="row no-gutters">
                        <p>{"Open Inactive Alerts: " + point.openInactiveAlerts}</p>
                    </div>
                </small>
            </Tooltip>
        );
    }

    render() {
        const { classes } = this.props;
        const { viewport } = this.state;

        let contents = this.state.loading
            ? <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body">
                        <Spinner animation="border" variant="light" />
                    </div>
                </div></div>
            : <div></div>;

        return (
            <div className={classes.root}>
                 <h3 className="pageTitle" id="tabelLabel" >Map</h3>
                <div >
                    <ReactMapGL
                        {...viewport}
                        width="72vw" height="70vh" style={borderStyle}
                        mapStyle='mapbox://styles/mapbox/light-v10'
                        onViewportChange={this.updateViewport}
                        mapboxApiAccessToken={TOKEN}>
                        {this.state.pointList.map((point, index) =>
                            <Marker key={`marker-${index}`} latitude={point.latitude} longitude={point.longitude} style={{ opacity: '0.65 !important' }}>
                                <OverlayTrigger key="bottom" placement="top"
                                    overlay={<Tooltip id="tooltip-point">
                                        <small>
                                            <div className="row no-gutters centered">
                                                {"Customer: " + point.name}
                                            </div>
                                            <div className="row no-gutters centered">
                                                {"Address: " + point.address}
                                            </div>
                                            <div className="row no-gutters centered">
                                                {"Open Active Alerts: " + point.openActiveAlerts}
                                            </div>
                                            <div className="row no-gutters centered">
                                                {"Open Inactive Alerts: " + point.openInactiveAlerts}
                                            </div>
                                        </small>
                                    </Tooltip>
                                    }>
                                    <svg id="TooltipPoint" height={20} viewBox="0 0 24 24" style={{ ...pinStyle, transform: `translate(${-20 / 2}px, ${-20}px)`, fill: point.color }} onClick={() => { this.onPointClick(index) }} >
                                        <path d={ICON} />
                                    </svg>
                                </OverlayTrigger>
                            </Marker>
                        )}

                        <div className="nav" style={navStyle}>
                            <NavigationControl />
                        </div>
                    </ReactMapGL>

                    {contents}
                </div>

                <MapPointModal show={this.state.showPointModal} onClick={this.showPointModal} point={this.state.currentPoint} customer={this.state.currentCustomer}
                    setCurrentCustomerId={this.props.setCurrentCustomerId} setActivePath={this.props.setActivePath} >
                </MapPointModal>
            </div>
        );
    }
}

export default withStyles(styles)(Map);