/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import ScheduleService from '../../services/schedule.service';
import HomeService from '../../services/home.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BaseAlertPage from '../LWTriageAlerts/BaseAlertPage';
import ReplaceSystemModal from "./ReplaceSystemModal";
import memoize from 'memoize-one';
import moment from 'moment';

import { jsTechnician, jsHouse, jsDetails, jsOpportunity, jsCustomer, jsComponent, alertSearchStartDate } from "../../componentObjects";
import { PageView, ModalView, Event, Timing } from "../GoogleAnalytics";

import { withStyles } from '@material-ui/core/styles';
import hvaccomponentService from "../../services/hvaccomponent.service";
import appointmentService from "../../services/appointment.service";
import AssetService from "../../services/asset.service";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important'
        },
    }
});

class CurrentJob extends Component {
    static displayName = CurrentJob.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getAlerts = this.getAlerts.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.reloadOppTable = this.reloadOppTable.bind(this);
        this.changeRadio = this.changeRadio.bind(this);

        this.getOpportunities = this.getOpportunities.bind(this);
        this.onOpportunityClick = this.onOpportunityClick.bind(this);
        this.closeOpportunity = this.closeOpportunity.bind(this);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);

        this.onChangeScheduleDate = this.onChangeScheduleDate.bind(this);
        this.onChangeScheduleTime = this.onChangeScheduleTime.bind(this);
        this.onChangeScheduleDateTime = this.onChangeScheduleDateTime.bind(this);
        this.onChangeTechnician = this.onChangeTechnician.bind(this);
        this.onChangeVerification = this.onChangeVerification.bind(this);
        this.onChangeJobValue = this.onChangeJobValue.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeClosedTime = this.onChangeClosedTime.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);

        this.onChangeJobDone = this.onChangeJobDone.bind(this);
        this.onChangeHours = this.onChangeHours.bind(this);
        this.onChangeMinutes = this.onChangeMinutes.bind(this);
        this.onChangeTotalParts = this.onChangeTotalParts.bind(this);
        this.handleChangePart = this.handleChangePart.bind(this);
        this.getHrsAndMins = this.getHrsAndMins.bind(this);

        this.onAddPartClick = this.onAddPartClick.bind(this);
        this.onDeletePartClick = this.onDeletePartClick.bind(this);
        this.onReplaceSystem = this.onReplaceSystem.bind(this);
        this.openSystemModal = this.openSystemModal.bind(this);
        this.showSystemModal = this.showSystemModal.bind(this);

        this.state = {
            alertArgs: {
                queryId: "custom",
                startTime: null,
                endTime: null,
                isActive: true,
                priority: 3,
                organizationId: null,
                isClosed: false
            },
            currentAccount: {
                parentAccountId: ""
            },
            alertList: [],
            alertType: "Active",
            loading: false,
            alertTableLoading: false,
            noCustomer: false,

            currentPartner: {
                users: [
                    {
                        userId: null,
                        firstName: "",
                        lastName: "",
                        email: null,
                        isActive: null,
                        lastLogin: null,
                        roleList: null,
                        roleListId: [],
                        roleListIdStr: null
                    }
                ]
            },
            beginStr: "",
            endStr: "",
            beginUtcStr: "",
            endUtcstr: "",
            oppPanelLoading: false,
            opportunityLoading: false,
            oppTableLoading: false,
            tableViewType: "Scheduled",
            isSelected: -1,
            selectedTechnicianNumber: -1,
            selectedTechnician: jsTechnician,
            scheduledTime: null,
            closedTime: null,
            selectedVerification: "",
            notes: "",
            technicianList: [],
            opportunityList: [],
            scheduledList: [],
            oppAlertList: [],
            currentHome: jsHouse,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            message: "",
            scheduledDateTime: "",

            jobDone: "",
            timeSpentHrs: "", 
            timeSpentMins: "",
            timeSpentHrsInt: 0,
            timeSpentMinsInt: 0,
            hrsMessage: "",
            minsMessage: "",
            componentList: [],
            oppParts: [{jsComponent}],
            numParts: 0,
            totalParts: 1,
            multiPart: false,
            replacedSystem: false,
            showSystemModal: false,
            inApptDB: false,

            appointmentDB: {
                caseId: 0,
                appointmentDate: null,
                verificationId: "",
                timeToRepair: "",
                useCaseId: "",
                jobValue: "",
                notes: "",
                closed: false,
                componentReplaced: [ jsComponent ]
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {
            this.getAlerts();
            this.getOpportunities();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    memAlerts = memoize(this.getAlerts);

    async getAlerts() {
        //var startTime = performance.now();
        var now = moment();
        var before =  moment(now).add(-24, 'hours'); 
        var nowStr = now.format();
        var beforeStr = before.format();
        var nowUtcStr = now.utc().format();
        var beforeUtcStr = before.utc().format();

        if (this.props.currentCustomerId === -1) {
            this.setState({ noCustomer: true, loading: false, alertTableLoading: false });
        }
        else {
            this.setState(prevState => ({
                alertArgs: {
                    ...prevState.alertArgs,
                    startTime: beforeUtcStr,
                    endTime: nowUtcStr,
                    organizationId: parseInt(this.props.currentCustomerId)
                }
            }), async () => {
                await AlertService.getuseralertlist(this.state.alertType, this.state.alertArgs)
                    .then(response2 => {
                        if (this._isMounted)
                            this.setState({
                                alertList: response2.data
                            }, () => {
                                    this.setState({ loading: false, alertTableLoading: false, noCustomer: false });
                            });
                    })
                    .catch(e => {
                        console.log(e);
                    })
            });
        }
    }

    async getAlertsNew(propId) {
        var startTime = performance.now();
        if (propId === -1) {
            this.setState({ noCustomer: true, loading: false, alertTableLoading: false });
        }
        else {
            this.setState(prevState => ({
                alertArgs: {
                    ...prevState.alertArgs,
                    organizationId: parseInt(propId)
                }
            }));

            await AlertService.getuseralertlist(this.state.alertType, this.state.alertArgs)
                .then(response2 => {
                    if (this._isMounted)
                        this.setState({
                            alertList: response2.data
                        }, () => {
                            this.setState({ loading: false, alertTableLoading: false }, async () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("Current Job Alerts Loading", "alertTableLoading", elapsedTime, "Current Job Alerts List Loading");

                            });
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    async reloadTable() {
        await this.getAlerts();
    }

    async reloadOppTable() {
        await this.getOpportunities();
    }

    changeRadio(val) {
        Event("Radio Clicked", "User clicked radio in Current Job", "Radio Clicked");
        let x = val;

        this.setState({
            alertType: x,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: x === "Inactive" ? false : true
            }
        }));

        this.setState({ alertTableLoading: true }, async () => {
            await this.getAlerts();
        });
    }

/* begin appt. form functions */

    openSystemModal() {
        this.showSystemModal();
    }

    showSystemModal() {
        this.setState({
            showSystemModal: !this.state.showSystemModal
        }, () => {
            if (this.state.showSystemModal)
                ModalView('replaceSystem');
        });
        window.scrollTo(0, 0);
    }

    onChangeScheduleDate(e) {
        let date = e.target.value;
        let curr = this.state.currentOpportunity.scheduledForStr.split("T");
        let currTime = curr[1];

        let newStr = date.concat("T", currTime);

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: newStr,
                scheduledForStr: newStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: newStr
            }
        }));
    }

    onChangeScheduleTime(e) {
        let time = e.target.value;
        let curr = this.state.currentOpportunity.scheduledForStr.split("T");
        let currDate = curr[0];

        let newStr = currDate.concat("T", time);

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: newStr,
                scheduledForStr: newStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: newStr
            }
        }));
    }

    onChangeScheduleDateTime(e) {
        let str = moment(e).format();
        let newStr = str.replace("Z", "");
        let dt = moment(e).toDate();
        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                scheduledForTimeStr: newStr,
                scheduledForStr: newStr
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                scheduledFor: newStr
            },
            appointmentDB: {
                ...prevState.appointmentDB,
                appointmentDate: dt
            },
            scheduledDateTime: dt
        }));
    }

    onChangeTechnician(e) {
        const techId = e.target.value;
        let tech = this.state.currentPartner.users.find(u => u.userId === techId);
        let name = tech.firstName + " " + tech.lastName;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                assignedToId: parseInt(techId),
                assignedToName: name
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                assignedToId: parseInt(techId)
            }
        }));
    }

    onChangeVerification(e) {
        const dbVerify = e.target.value; //(e.target.value === "true" ? true : e.target.value === "false" ? false : null);
        const verify = dbVerify === 3 ? true : (dbVerify === 1 ? false : null);

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                isFalsePositive: verify
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                isFalsePositive: verify
            },
            appointmentDB: {
                ...prevState.appointmentDB,
                verificationId: parseInt(dbVerify)
            }
        }));
    }

    onChangeJobValue(e) {
        const impact = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                impact: impact
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                impactEstimate: parseInt(impact),
                impactType: "Money",
                impactTypeId: 1
            },
            appointmentDB: {
                ...prevState.appointmentDB,
                jobValue: impact
            }
        }));
    }

    onChangeNotes(e) {
        const notes = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                comment: notes
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                comment: notes
            },
            appointmentDB: {
                ...prevState.appointmentDB,
                notes: notes
            }
        }));
    }

    onChangeClosedTime(e) {
        let time = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                closedTimeStr: time,
                closedOnStr: time
            }
        }));
    }

    onChangeStatus(e) {
        let status = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                status: status
            },
            currentOpportunityDetails: {
                ...prevState.currentOpportunityDetails,
                status: status
            }
        }));
    }

/* START NEW QUESTIONS */

    onChangeJobDone(e) {
        let jobDone = e.target.value;
        let jobInt = parseInt(jobDone);
        let multiPart = false;
        let replacedSystem = false;
        let totalParts = 1;
        //let numParts = 0;
        let jobComponent = jsComponent;
        jobComponent.caseId = this.state.appointmentDB.caseId;
        let parts = [jobComponent];

        if (jobInt === 2 || jobInt === 4) {
            multiPart = true;
            totalParts = 2;
            //numParts = 1;
            parts[1] = jobComponent;
        }
        else if (jobInt > 4)
            replacedSystem = true;

        if (this.state.jobDone !== jobDone) {
            this.setState(prevState => ({
                jobDone: jobInt,
                oppParts: parts,
                numParts: 0, //should this be set equal to numParts variable? commented out to get rid of console warning
                totalParts: totalParts,
                multiPart: multiPart,
                replacedSystem: replacedSystem,
                appointmentDB: {
                    ...prevState.appointmentDB,
                    useCaseId: jobInt
                }
            }));
        }
        else 
            this.setState({ jobDone: jobDone });
    }

    onChangeHours(e) {
        let hrs = e.target.value;
        let hrsInt = parseInt(hrs);
        if (hrsInt < 0 || hrsInt > 24) {
            this.setState({ timeSpentHrs: hrs, timeSpentHrsInt: hrsInt, hrsMessage: "Hours must be between 0-24" });
        }
        else {
            let repairTime = hrsInt * 60 + this.state.timeSpentMinsInt;
            this.setState(prevState => ({
                timeSpentHrs: hrs,
                timeSpentHrsInt: hrsInt,
                hrsMessage: "",
                appointmentDB: {
                    ...prevState.appointmentDB,
                    timeToRepair: repairTime
                }
            }));
        }
    }

    onChangeMinutes(e) {
        let mins = e.target.value;
        let minsInt = parseInt(mins);
        if (mins < 0 || mins > 60) {
            this.setState({ timeSpentMins: mins, timeSpentMinsInt: minsInt, minsMessage: "Minutes must be between 0-60" });
        }
        else {
            let repairTime = this.state.timeSpentHrsInt * 60 + minsInt;
            this.setState(prevState => ({
                timeSpentMins: mins,
                timeSpentMinsInt: minsInt,
                minsMessage: "",
                appointmentDB: {
                    ...prevState.appointmentDB,
                    timeToRepair: repairTime
                }
            }));
        }
    }

    handleChangePart(newPart, index) {
        let parts = this.state.oppParts;
        parts[index] = newPart;
        this.setState(prevState => ({
            oppParts: parts,
            appointmentDB: {
                ...prevState.appointmentDB,
                componentReplaced: parts
            }
        }));
    }

    onChangeTotalParts(e) {
        let currentNum = this.state.totalParts;
        let parts = this.state.oppParts;
        let numParts = e.target.value;
        let num = parseInt(numParts);

        if (num !== currentNum) {
            let i = currentNum - 1;
            if (num > currentNum) {
                while (i < num) {
                    parts[i] = jsComponent;
                    i++;
                }
            }
            else {
                i++;
                while (i > num) {
                    parts.pop();
                    i--;
                }
            }
        }

        this.setState({ totalParts: num, numParts: num - 1, oppParts: parts });
    }

    onAddPartClick() {
        Event("Add Part Clicked", "Users clicked add part in Current Job", "Add Part Clicked");
        this.setState({ numParts: this.state.numParts + 1 }, () => {
            var parts = this.state.oppParts;
            parts[this.state.numParts] = jsComponent;
            this.setState({ oppParts: parts });
        });
    }

    onDeletePartClick() {
        Event("Delete Part Clicked", "Users clicked delete part in Current Job", "Delete Part Clicked");
        var parts = this.state.oppParts;
        parts.pop();
        this.setState({ numParts: this.state.numParts - 1, oppParts: parts });
    }

/* END NEW QUESTIONS */

    onReplaceSystem() {
        Event("System Replaced", "User replaced system in Current Job", "System Replaced");
        this.showSystemModal();
        this.closeOpportunity();
    }

    onCancelClick() {
        Event("Cancel Opportunity Clicked", "User clicked button to cancel changes to opportunity", "Cancel Opportunity Changes Clicked");
        this.setState({ currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, message: "Changes canceled!", scheduledDateTime: "", jobDone: "", timeSpentHrs: "", timeSpentMins: "", timeSpentHrsInt: 0, timeSpentMinsInt: 0, componentList: [], oppParts: [{ jsComponent }], numParts: 0, multiPart: false, replacedSystem: false }, () => {
            if (this.state.isSelected !== -1 && this.state.scheduledList !== null && this.state.scheduledList !== undefined && this.state.scheduledList.length)
                this.setState({ currentOpportunity: this.state.opportunityList[this.state.isSelected] }, () => {
                    this.setState({ currentOpportunityDetails: this.state.currentOpportunity.details });
                    if (this.state.currentOpportunity.scheduledForTimeStr !== null && this.state.currentOpportunity.scheduledForTimeStr !== "" && this.state.currentOpportunity.scheduledForTimeStr !== undefined) {
                        let dt = new Date(this.state.currentOpportunity.scheduledForTimeStr);
                        this.setState({ scheduledDateTime: dt });
                    }
                })
        });

        setTimeout(() => {
            this.setState({
                message: ""
            });
        }, 3000);
    }

    onCloseClick() {
        let errorMsg = "";
        let errorFields = [];

        if (this.state.currentOpportunity.scheduledForTimeStr === "")
            errorFields.push("Scheduled Time");
        if (this.state.currentOpportunity.assignedToId === "" || this.state.currentOpportunity.assignedToId == null)
            errorFields.push("Technician");
        if (this.state.currentOpportunity.isFalsePositive === "")
            errorFields.push("Verification");

        if (this.state.appointmentDB.useCaseId === "" || this.state.appointmentDB.useCaseId === 0)
            errorFields.push("Action Taken");
        else if (this.state.appointmentDB.useCaseId === 1 || this.state.appointmentDB.useCaseId === 3) {
            if (this.state.oppParts[0].componentId === -1 || (this.state.oppParts[0].componentId === 13 && (this.state.oppParts[0].other === "None" || this.state.oppParts[0].other === "")))
                errorFields.push("Part Type");
            if (this.state.oppParts[0].oem === "")
                errorFields.push("OEM Selection");
            if (this.state.timeSpentHrs === "" || this.state.timeSpentMins === "")
                errorFields.push("Time Spent (Hours and Minutes)");
            else if (this.state.hrsMessage !== "" || this.state.minsMessage !== "")
                errorFields.push("Valid Time Spent Selection")
        }
        else if (this.state.appointmentDB.useCaseId === 2 || this.state.appointmentDB.useCaseId === 4) {
            let partsError = false;
            this.state.oppParts.forEach(part => {
                if (part.componentId === -1 || part.oem === "" || (part.componentId === 13 && (part.other === "None" || part.other === "")))
                    partsError = true;
            }); 
            if (partsError)
                errorFields.push("Each Part's Type and OEM Selection")
            if (this.state.timeSpentHrs === "" || this.state.timeSpentMins === "")
                errorFields.push("Time Spent (Hours and Minutes)");
            else if (this.state.hrsMessage !== "" || this.state.minsMessage !== "")
                errorFields.push("Valid Time Spent Selection")
        }
        else if (this.state.appointmentDB.useCaseId > 4) {
            if (this.state.timeSpentHrs === "" || this.state.timeSpentMins === "")
                errorFields.push("Time Spent (Hours and Minutes)");
            else if (this.state.hrsMessage !== "" || this.state.minsMessage !== "")
                errorFields.push("Valid Time Spent Selection")
        }

        if (this.state.currentOpportunityDetails.impactEstimate === "" || this.state.currentOpportunityDetails.impactEstimate == null)
            errorFields.push("Job Value");

        errorMsg = errorFields.join(", ");

        if (errorMsg !== "") {
            var txt = " is ";
            if (errorFields.length > 1) txt = " are ";
            this.setState({ message: errorMsg + txt + "required to close opportunity." });

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 3000);
        }
        else {
            this.setState(prevState => ({
                currentOpportunityDetails: {
                    ...prevState.currentOpportunityDetails,
                    status: "Closed"
                },
                appointmentDB: {
                    ...prevState.appointmentDB,
                    closed: true
                }
            }), () => {
                    if (this.state.replacedSystem)
                        this.openSystemModal();
                    else
                        this.closeOpportunity();
            });
        }
    }

    async closeOpportunity() {
        Event("Oportunity Closed", "User closed opportunity in Current Job", "Opportunity Closed");
        this.setState({ opportunityLoading: true });
        await ScheduleService.updateopportunity(this.state.currentOpportunity.scheduledForTimeStr, this.state.currentOpportunityDetails.assignedToId, this.state.currentOpportunityDetails)
            .then(response => {
                if (response.data === true) {
                    this.setState({
                        message: ""
                    }, async () => {
                        if (this.state.inApptDB)
                            await appointmentService.updateappointmentdb(this.state.appointmentDB)
                                .then(response3 => {
                                    if (response3 !== null) {  
                                        this.setState({ message: "Appointment has been successfully updated in the database!" }, async () => {
                                            let message = "";

                                            if (this.state.appointmentDB.useCaseId > 2) {
                                                await AssetService.updateassetclasses(parseInt(this.state.currentOpportunity.assetId), this.state.appointmentDB.useCaseId).then(response5 => {

                                                    if (response5.statusCode === 200) {
                                                        message = "Successfully updated Asset classes!";
                                                    }
                                                    else {
                                                        message = "Failed to update Asset classes!";
                                                    }

                                                    this.setState({
                                                        statusMessage: message
                                                    });
                                                });
                                            }
                                        });
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        else
                            await appointmentService.addappointmentdb(this.state.appointmentDB)
                                .then(response4 => {
                                    if (response4 !== null && response4.caseId !== null) { 
                                        this.setState({ message: "Appointment has been successfully updated in the database!" }, async () => {
                                            let message = "";

                                            if (this.state.appointmentDB.useCaseId > 2) {
                                                await AssetService.updateassetclasses(parseInt(this.state.currentOpportunity.assetId), this.state.appointmentDB.useCaseId).then(response6 => {

                                                    if (response6.statusCode === 200) {
                                                        message = "Successfully updated Asset classes!";
                                                    }
                                                    else {
                                                        message = "Failed to update Asset classes!";
                                                    }

                                                    this.setState({
                                                        statusMessage: message
                                                    });
                                                });
                                            }
                                        });
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                });

                        if (this.state.currentOpportunity.sourceId !== null && this.state.currentOpportunity.sourceId !== undefined) 
                            await AlertService.closealert(parseInt(this.state.currentOpportunity.sourceId))
                                .then(response2 => {
                                    if (response2.statusCode === 200) {
                                        this.setState({
                                            message: "The opportunity and associated alert were closed successfully!"
                                        });

                                        Event("Close Opportunity Clicked", "User clicked close opportunity button", "Close Opportunity Clicked");

                                        this.reloadOppTable();
                                    }
                                });
                        else
                            this.setState({ message: "Failed to close the associated alert for the opportunity!", opportunityLoading: false });

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 3000);
                    });
                }
                else {
                    this.setState({
                        message: "Failed to close the opportunity!",
                        opportunityLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    async onUpdateClick() {
        Event("Opportunity Updated", "User updated opportunity in Current Job", "Opportunity Updated");
        this.setState({ opportunityLoading: true });
        await ScheduleService.updateopportunity(this.state.currentOpportunity.scheduledForTimeStr, this.state.currentOpportunityDetails.assignedToId, this.state.currentOpportunityDetails)
            .then(response => {
                if (response.data === true) {
                    this.setState({
                        message: ""
                    }, async () => {
                            Event("Update Opportunity Clicked", "User clicked update opportunity button", "Update Opportunity Clicked");
                            if (this.state.inApptDB)
                                await appointmentService.updateappointmentdb(this.state.appointmentDB)
                                    .then(response2 => {
                                        this.setState({ message: "The opportunity was updated successfully!" })
                                        this.reloadOppTable();

                                        setTimeout(() => {
                                            this.setState({
                                                message: ""
                                            });
                                        }, 3000);
                                    });
                            else
                                await appointmentService.addappointmentdb(this.state.appointmentDB)
                                    .then(response3 => {
                                        if (response3 !== null) {
                                            this.setState({ message: "The opportunity was updated successfully!" })
                                            this.reloadOppTable();

                                            setTimeout(() => {
                                                this.setState({
                                                    message: ""
                                                });
                                            }, 3000);
                                        }
                                    });
                    });
                    
                }
                else {
                    this.setState({
                        message: "Failed to update the opportunity!",
                        opportunityLoading: false
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    /* end appt. form functions */

    async onOpportunityClick(index) {
        let x = index;
        //var startTime = performance.now();
        Event("Opportunity Clicked", "User clicked on an opportunity in the table", "Opportunity Clicked");

        this.setState({ currentOpportunity: jsOpportunity, currentOpportunityDetails: jsDetails, currentOpportunityCustomer: jsCustomer, currentHome: jsHouse, selectedTechnician: jsTechnician, opportunityLoading: true, isSelected: index, jobDone: "", timeSpentHrs: "", timeSpentMins: "", timeSpentHrsInt: 0, timeSpentMinsInt: 0, componentList: [], oppParts: [{ jsComponent }], numParts: 0, multiPart: false, replacedSystem: false }, () => {
            this.setState({ currentOpportunity: this.state.opportunityList[x] }, async () => {
                if (this._isMounted)
                    this.setState({ currentOpportunityDetails: this.state.currentOpportunity.details }, async () => {
                        if (this.state.currentOpportunityDetails.organizationId !== null && this.state.currentOpportunityDetails.organizationId !== undefined) {
                            await AccountService.get(this.state.currentOpportunityDetails.organizationId, false)
                                .then(response2 => {
                                    if (this._isMounted)
                                        this.setState({ currentOpportunityCustomer: response2.data }, async () => {
                                            await AccountService.getpartnerinfo(this.state.currentOpportunityCustomer.parentAccountId, true)
                                                .then(response3 => {
                                                    if (this._isMounted)
                                                        this.setState({ currentPartner: response3.data }, () => {
                                                            this.setState({ technicianList: this.state.currentPartner.users.filter(user => user.roleList.includes("Technician")) });
                                                        });
                                                });
                                        });
                                });
                            await HomeService.get(this.state.currentOpportunityDetails.organizationId)
                                .then(response4 => {
                                    if (this._isMounted)
                                        this.setState({ currentHome: response4.data[0] }, async () => {
                                            if (this.state.currentOpportunityDetails.scheduledFor !== null && this.state.currentOpportunityDetails.scheduledFor !== "" && this.state.currentOpportunityDetails.scheduledFor !== undefined) {
                                                let dt = new Date(this.state.currentOpportunityDetails.scheduledFor.match(/\d+/)[0] * 1);
                                                this.setState({ scheduledDateTime: dt });
                                            }

                                            
                                            
                                        });
                                });

                            await appointmentService.getappointmentbyid(parseInt(this.state.currentOpportunity.caseId))
                                .then(response6 => {
                                    if (this._isMounted)
                                        if (response6 !== null && response6 !== undefined) {
                                            this.setState({ appointmentDB: response6 }, () => {
                                                this.setState({ inApptDB: this.state.appointmentDB.caseId > 0 ? true : false }, () => {
                                                    if (this.state.inApptDB) {
                                                        this.getHrsAndMins(this.state.appointmentDB.timeToRepair);
                                                        let numPartsDB = this.state.appointmentDB.componentReplaced.length;
                                                        this.setState({
                                                            oppParts: numPartsDB > 0 ? this.state.appointmentDB.componentReplaced : [jsComponent],
                                                            multiPart: numPartsDB > 1 ? true : false,
                                                            totalParts: numPartsDB,
                                                            numParts: numPartsDB - 1
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            numParts: 0,
                                                            totalParts: 1,
                                                            multiPart: false,
                                                            appointmentDB: {
                                                                caseId: this.state.currentOpportunity.caseId,
                                                                appointmentDate: this.state.scheduledDateTime,
                                                                verificationId: this.state.currentOpportunity.isFalsePositive === true ? 3 : (this.state.currentOpportunity.isFalsePositive === false ? 1 : 2),
                                                                useCaseId: 0,
                                                                timeToRepair: "",
                                                                jobValue: this.state.currentOpportunityDetails.impactEstimate,
                                                                notes: this.state.currentOpportunity.comment,
                                                                closed: this.state.currentOpportunity.isClosed,
                                                                componentReplaced: [jsComponent]
                                                            }
                                                        })
                                                    }
                                                });
                                            });
                                        }
                                        else {
                                            this.setState({
                                                inApptDB: false,
                                                appointmentDB: {
                                                    caseId: this.state.currentOpportunity.caseId,
                                                    appointmentDate: this.state.scheduledDateTime,
                                                    verificationId: this.state.currentOpportunity.isFalsePositive === true ? 3 : (this.state.currentOpportunity.isFalsePositive === false ? 1 : 2),
                                                    useCaseId: 0,
                                                    timeToRepair: "",
                                                    jobValue: this.state.currentOpportunityDetails.impactEstimate,
                                                    notes: this.state.currentOpportunity.comment,
                                                    closed: this.state.currentOpportunity.isClosed,
                                                    componentReplaced: [jsComponent]
                                                }
                                            })
                                        }
                                });

                            await hvaccomponentService.gethvaccomponents(this.state.currentOpportunity.assetType)
                                .then(response5 => {
                                    if (this._isMounted) {
                                        this.setState({
                                            componentList: response5,
                                            opportunityLoading: false
                                        });
                                    }
                                });

                        }
                        else {
                            this.setState({
                                opportunityLoading: false,
                                message: "Invalid appointment information."
                            });

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 3000);
                        }

                    });
            });
        });
    }

    async getOpportunities() {
        //var startTime = performance.now();
        var now = moment();
        var nowStr = moment(now).format();
        var beforeStr =moment(now).add(-24, 'hours').format();
        
        this.setState({
            beginStr: beforeStr,
            endStr: nowStr,
            oppPanelLoading: true,
            isSelected: -1,
            currentOpportunity: jsOpportunity,
            currentOpportunityDetails: jsDetails,
            currentOpportunityCustomer: jsCustomer,
            tableViewType: this.state.tableViewType
        }, async () => {
                await AlertService.getuseralertlist("Repairs", this.state.alertArgs)
                    .then(response2 => {
                        if (this._isMounted)
                            this.setState({
                                oppAlertList: response2.data
                            }, async () => {
                                await ScheduleService.getuseropportunities(this.state.beginStr, this.state.endStr, this.state.oppAlertList)
                                    .then(response3 => {
                                        if (this._isMounted)
                                            this.setState({ opportunityList: response3.data, selectedTechnician: jsTechnician, oppPanelLoading: false, opportunityLoading: false, loading: false }, () => {
                                                this.setState({ scheduledList: this.state.opportunityList["Scheduled"], opportunityList: this.state.opportunityList["Scheduled"] });
                                            });
                                    })
                            });
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ loading: false, oppPanelLoading: false, message: "caught in getOpportunities()" });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
        });
    }

    getHrsAndMins(totalMins) {
        let hrs = 0;
        let mins = 0;

        hrs = Math.floor(totalMins / 60);
        mins = totalMins - hrs * 60;

        this.setState({ timeSpentHrs: hrs.toString(), timeSpentHrsInt: hrs, timeSpentMins: mins.toString(), timeSpentMinsInt: mins });
    }

    getCurrOppDate(str) {
        let curr = str.split("T");
        let date = curr[0];

        return (date);
    }

    getCurrOppTime(str) {
        let curr = str.split("T");
        let time = curr[1];

        return (time);
    }

    getDate(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment(str).format("l h:mm A");
        }
        return (date);
    }

    convertDate(str) {
        var d = new Date(str);

        return d;
    }

    renderTimeMessage() {
        if (this.state.hrsMessage !== "" && this.state.minsMessage !== "") {
            return (
                <div className="col-12 centered"><small style={{ color: 'red' }}>{this.state.hrsMessage + " and " + this.state.minsMessage}</small></div>
            );
        }
        else 
            return (
                <div className="col-12 centered"><small style={{ color: 'red' }}>{this.state.hrsMessage + this.state.minsMessage}</small></div>
                );
    }

    renderJobQuestions(jobDone) {
        let jobQuestions = null;
        let jobInt = parseInt(jobDone); // UseCaseId

        let timeMessage = this.state.hrsMessage !== "" || this.state.minsMessage !== "" ?
            this.renderTimeMessage()
            : null;

        let numParts = this.state.multiPart || this.state.numParts > 0 ?
            <div className="row no-gutters">
                <div className="col-4">
                    <p className="semibold">Total Parts:</p>
                </div>
                <div className="col">
                    <select id="total-parts" className="txt-detail" value={this.state.totalParts} onChange={this.onChangeTotalParts} >
                        <option value="1" defaultValue disabled hidden>--Select Total Number of Parts--</option>
                        <option id="2" key="2" value="2">2</option>
                        <option id="3" key="3" value="3">3</option>
                        <option id="4" key="4" value="4">4</option>
                        <option id="5" key="5" value="5">5</option>
                        <option id="6" key="6" value="6">6</option>
                        
                    </select>
                </div>
            </div>
            : null;

        let partQuestions = (jobInt < 5) ?
            <div>
                {numParts}
                {this.state.oppParts.map((oppPart, index) =>
                    <PartTypeQuestions key={`${index}-partType`} currentPart={oppPart} currentCaseId={this.state.currentOpportunity.caseId} index={index} handleChangePart={this.handleChangePart} componentList={this.state.componentList} totalParts={this.state.totalParts} />
                )}
            </div>
            : null;

        
        jobQuestions = <div>
            {partQuestions}

            <div className="row no-gutters">
                <div className="col-4">
                    <p className="semibold">Time Spent:</p>
                </div>
                <div className="col-8">
                    <div className="row no-gutters" style={{ width: '95%' }}>
                        <div className="col">
                            <input type="number" className="txt-detail" min="0" max="24" value={this.state.timeSpentHrs} placeholder="--Hours--" onChange={this.onChangeHours} />
                        </div>
                        <div className="col">
                            <input type="number" className="txt-detail" min="0" max="60" value={this.state.timeSpentMins} placeholder="--Minutes--" onChange={this.onChangeMinutes} style={{ width: 'inherit' }} />
                        </div>
                    </div>
                </div>
                {timeMessage}
            </div>
        </div>;

        return jobQuestions;
    }

    renderTable() {
        let scheduledContent = this.state.scheduledList !== null && this.state.scheduledList !== undefined && this.state.scheduledList.length ?
            <tbody>
                {this.state.scheduledList.map((opportunity, index) =>
                    <tr key={opportunity.caseId} data={index} onClick={() => { this.onOpportunityClick(index) }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                        <td>{opportunity.number}</td>
                        <td>{opportunity.priorityStr}</td>
                        <td>{opportunity.subject}</td>
                        <td>{this.getDate(opportunity.createdOnTimeStr)}</td>
                        <td>{this.getDate(opportunity.scheduledForTimeStr)}</td>
                    </tr>
                )}
            </tbody>
            : <tbody>
                <tr><td>No Scheduled Opportunities</td></tr>
            </tbody>;

        let table =
            <div style={{ height: '510px', overflow: "auto" }}>
                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                    <thead>
                        <tr >
                            <th>Number</th>
                            <th>Priority</th>
                            <th>Issue</th>
                            <th>Created On</th>
                            <th>Scheduled For</th>
                        </tr>
                    </thead>
                    {scheduledContent}
                </table>
            </div>;

        return table;
    }

    renderOpportunities() {
        const { classes } = this.props;

        let opportunityLoader = this.state.opportunityLoading
            ? <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;

        return (
            <div className="row no-gutters">
                <div className="col-sm-12 col-md-8">
                    <div className="white-container">
                        <h5 className="sectionTitle">Leads</h5>
                        <div className="table-container-2">
                            {this.renderTable()}
                        </div>
                    </div>
                    <br />
                    <p className="status-msg">{this.state.message}</p>
                </div>

                <div className="col-sm-12 col-md-4">
                    <div className="grey-container">
                        <h5 className="sectionTitle">Appointment</h5>
                        <small>
                            <div className="row no-gutters">
                                <div className="col-4">
                                    <p className="semibold">Scheduled:</p>
                                </div>
                                <div className="col-8">
                                    <DatePicker
                                        customInput={<input className="txt-detail"/>}
                                        id="scheduleDatetime"
                                        name="scheduleDateTime"
                                        selected={this.state.scheduledDateTime}
                                        onChange={date => this.onChangeScheduleDateTime(date)}
                                        placeholderText="--Select Date/Time--"
                                        dateFormat="MM/dd/yyyy h:mm:ss aa"
                                        timeIntervals={15}
                                        showTimeSelect
                                        popperPlacement="top-end"
                                        popperProps={{
                                            positionFixed: true
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-4">
                                    <p className="semibold">Assign To:</p>
                                </div>
                                <div className="col">
                                    <select className="txt-detail" value={this.state.currentOpportunity.assignedToId == null ? "" : this.state.currentOpportunity.assignedToId} onChange={this.onChangeTechnician} style={{ paddingRight: '18px' }}>
                                        <option value="" disabled defaultValue hidden>--Select Technician--</option>
                                        {this.state.currentPartner.users.map((tech, index) =>
                                            <option key={`${index}-${tech.userId}`} id={`${index}-${tech.userId}`} value={tech.userId}>{tech.lastName + ", " + tech.firstName + ` (${tech.userId})`}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-4">
                                    <p className="semibold">Verification:</p>
                                </div>
                                <div className="col">
                                    {/* this matches db, must save accordingly for TT */}
                                    <select className="txt-detail" value={this.state.appointmentDB.verificationId} onChange={this.onChangeVerification}>
                                        <option key="0" id="0" defaultValue disabled hidden value="">--Select Verification--</option>
                                        <option key="1" id="1" value="1">Verified</option>
                                        <option key="2" id="2" value="2">Unverified</option>
                                        <option key="3" id="3" value="3">False Positive</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="col-4">
                                    <p className="semibold">Action Taken:</p>
                                </div>
                                <div className="col">
                                    <select className="txt-detail" value={this.state.appointmentDB.useCaseId} onChange={this.onChangeJobDone} required>
                                        <option value="0" disabled defaultValue hidden>--Select Action Taken--</option>
                                        <option key="1" id="1" value="1">Repaired a Part</option>
                                        <option key="2" id="2" value="2">Repaired >1 Part</option>
                                        <option key="3" id="3" value="3">Replaced a Part</option>
                                        <option key="4" id="4" value="4">Replaced >1 Part</option>
                                        <option key="5" id="5" value="5">Replaced Heating System</option>
                                        <option key="6" id="6" value="6">Replaced Cooling System</option>
                                        <option key="7" id="7" value="7">Replaced Entire HVAC System</option>
                                    </select>
                                </div>
                            </div>

                            {this.state.appointmentDB !== undefined && this.state.appointmentDB.useCaseId !== undefined && this.state.appointmentDB.useCaseId !== "" && this.state.appointmentDB.useCaseId !== -1 && this.state.appointmentDB.useCaseId !== 0 ?
                                this.renderJobQuestions(this.state.appointmentDB.useCaseId)
                                : null
                            }

                            <div className="row no-gutters">
                                <div className="col-4">
                                    <p className="semibold">Job Value:</p>
                                </div>
                                <div className="col">
                                    <input
                                        type="number"
                                        className="txt-detail"
                                        id="jobValue"
                                        name="jobValue"
                                        placeholder="Dollars($)"
                                        value={this.state.currentOpportunityDetails.impactEstimate == null ? "" : String(this.state.currentOpportunityDetails.impactEstimate)}
                                        onChange={this.onChangeJobValue}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-4">
                                    <p className="semibold">Notes:</p>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        className="txt-detail"
                                        id="notes"
                                        name="notes"
                                        value={this.state.currentOpportunity.comment == null ? "" : this.state.currentOpportunity.comment}
                                        onChange={this.onChangeNotes}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lastItem">
                                <div className="col-10">
                                    <br />
                                </div>
                                <div className="col">
                                    <div className="btn-right">
                                        <button className="secondary-btn btn-small" onClick={this.onCancelClick}>Cancel</button>
                                        <button className="secondary-btn btn-small" onClick={this.onUpdateClick}>Update</button>
                                        <button className="secondary-btn btn-small" onClick={this.onCloseClick}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </small>
                    </div>
                </div>
                {opportunityLoader}
            </div>
        );
    }

    renderOpportunitiesPanel() {
        const { classes } = this.props;

        let contents = this.state.oppPanelLoading
            ? <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderOpportunities();

        return (
            <div>
                {contents}
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let content = this.state.noCustomer ?
            <div><p className="status-msg">No customer selected. Please select a customer to view current job information.</p></div>
            : <Tabs>
                <TabList style={{ paddingLeft: 15 + "px" }}>
                    <Tab>Alerts</Tab>
                    <Tab>Appointments</Tab>
                </TabList>

                <TabPanel>
                    <BaseAlertPage alertList={this.state.alertList} getAlerts={this.getAlerts} changeRadio={this.changeRadio} reloadTable={this.reloadTable} _isMounted={this._isMounted} alertTableLoading={this.state.alertTableLoading} loading={this.state.loading} showButtons={false}
                        setCurrentPartnerId={this.props.setCurrentPartnerId} setCurrentCustomerId={this.props.setCurrentCustomerId} setActivePath={this.props.setActivePath} />
                </TabPanel>
                <TabPanel>
                    {this.renderOpportunitiesPanel()}
                </TabPanel>
            </Tabs>;

        return (
            <div className={classes.root}>
                <div className="infoPage">
                <h3 id="tabellabel" className="pageTitle" >Current Job</h3>
                    {content}
                    <ReplaceSystemModal show={this.state.showSystemModal} onClick={this.showSystemModal} onReplaceSystem={this.onReplaceSystem} ></ReplaceSystemModal>
                </div>
            </div>
        );
    }
}

class PartTypeQuestions extends Component {

    constructor(props) {
        super(props);

        this.onChangePartType = this.onChangePartType.bind(this);
        this.onChangeOtherPart = this.onChangeOtherPart.bind(this);
        this.onChangeOEM = this.onChangeOEM.bind(this);

        this.state = {
            part: {
                caseId: this.props.currentCaseId,
                componentId: this.props.currentPart.componentId,
                oem: this.props.currentPart.oem,
                other: this.props.currentPart.other
            }
        }
    }

    onChangePartType(e) {
        let part = e.target.selectedOptions;
        let partStr = part[0].text;
        let idStr = e.target.value;
        let id = parseInt(idStr);
        let other = "None";
       
        if (partStr === "Other") {
            other = "";
        }
        else {
            other = "None"
        }

        this.setState(prevState => ({
            part: {
                ...prevState.part,
                componentId: id,
                other: other
            }
        }), () => {
            this.props.handleChangePart(this.state.part, this.props.index);
        });
    }

    onChangeOtherPart(e) {
        let other = e.target.value;

        this.setState(prevState => ({
            part: {
                ...prevState.part,
                other: other
            }
        }), () => {
            this.props.handleChangePart(this.state.part, this.props.index);
        });
    }

    onChangeOEM(e) {
        let str = e.target.value;
        let isOEM = str === "true" ? true : false;

        this.setState(prevState => ({
            part: {
                ...prevState.part,
                oem: isOEM
            }
        }), () => {
                this.props.handleChangePart(this.state.part, this.props.index);
                console.log(this.state.part);
        });
    }

    render() {
        const partIndex = this.props.index;
        const displayIndex = partIndex + 1;
        let isLast = (displayIndex === this.props.totalParts && this.props.totalParts > 1);

        return (
            <div>
                {this.props.totalParts > 1 ?
                    <div className="detailTitle">Part {displayIndex} Information</div>
                    : null
                }

                <div className="row no-gutters">
                    <div className="col-4">
                        <p className="semibold">Type of part:</p>
                    </div>
                    <div className="col">
                        <select key={`${partIndex}-partType`} id={`${partIndex}-partType`} index={partIndex} className="txt-detail" value={this.props.currentPart.componentId} onChange={this.onChangePartType}>
                            <option value="-1" disabled defaultValue hidden>--Select Part Type--</option>
                            {this.props.componentList !== null && this.props.componentList !== undefined && this.props.componentList.length ?
                                this.props.componentList.map((part, index) =>
                                    <option key={`${index}-${part.id}`} id={`${index}-${part.id}`} text={part.name} value={part.id}>{part.name}</option>
                                )
                                : null
                            }
                        </select>
                    </div>
                </div>

                {this.props.currentPart.other !== "None" ?
                    <div className="row no-gutters">
                        <div className="col-4">
                            <p className="semibold">Enter Type:</p>
                        </div>
                        <div className="col">
                            <input type="text" className="txt-detail" key={`${partIndex}-other`} id={`${partIndex}-other`} index={partIndex} value={this.props.currentPart.other} onChange={this.onChangeOtherPart} placeholder="Enter Type of Part" />
                        </div>
                    </div>
                    : null
                }

                <div className={isLast ? "row no-gutters lastItem" : "row no-gutters"} style={{ paddingLeft: '0px' }}>
                    <div className="col-4">
                        <p className="semibold">Is OEM part:</p>
                    </div>
                    <div className="col">
                        <select className="txt-detail" value={this.props.currentPart.oem} onChange={this.onChangeOEM}>
                            <option key="0" id="0" value="" disabled defaultValue hidden>--Select if OEM--</option>
                            <option key="1" id="1" value="true">Yes</option>
                            <option key="2" id="2" value="false">No</option>
                        </select>
                    </div>
                </div>

            </div>
        );
    }
} 

export default withStyles(styles)(CurrentJob);