/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/assets";
class AssetService {
    // get assets
    async get(id) {
        const response = await http.get(`${controller}/assets?houseAssetId=${id}`);
        const data = response;
        return data;
    }
    async getassetsproperties(ids) {
        let query = ids.join('&assetsIds=');
        const response = await http.get(`${controller}/assetsproperties?assetsIds=${query}`);
        const data = await response;
        return data;
    }
    
    async getassethierarchy(id, fromAssetId = 0) {
        const response = await http.get(`${controller}/assethierarchy?organizationId=${id}&fromAssetId=${fromAssetId}`);
        const data = response;
        return data;
    }
    async assethierarchybyorgid(id, fromAssetId = 0) {
        const response = await http.get(`${controller}/assethierarchybyorgid?organizationId=${id}&fromAssetId=${fromAssetId}`);
        const data = response;
        return data;
    }
    async getbillingdevices(id, assets) {
        const response = await http.post(`${controller}/getbillingdevices?hvacSystemId=${id}`, assets);
        const data = response;
        return data;
    }

    async getfurnacetrainingevents(request) {
        const response = await http.post(`${controller}/getfurnacetrainingevents`, request);
        const data = response;
        return data;
    }
    
    async getfurnaceedittrainingannotations(request) {
        const response = await http.post(`${controller}/getfurnaceedittrainingannotations`, request);
        const data = response;
        return data;
    }

    async publishfurnacetraining(id, publishedBy, furnFinal) {
        const response = await http.post(`${controller}/publishfurnacetraining?assetId=${id}&publishedBy=${publishedBy}`, furnFinal);
        const data = response;
        return data;
    }

    async publishfurnaceedittraining(id, publishedBy, furnFinal) {
        const response = await http.post(`${controller}/publishfurnaceedittraining?assetId=${id}&publishedBy=${publishedBy}`, furnFinal);
        const data = response;
        return data;
    }

    async getfurnaceapplypropsresult(request) {
        const response = await http.post(`${controller}/getfurnaceapplypropsresult`, request);
        const data = response;
        return data;
    }

    async getassetdevices(id, assets) {
        const response = await http.post(`${controller}/assetdevices?hvacSystemId=${id}`, assets);
        const data = response;
        return data;
    }

    async gethvacassets(id) {
        const response = await http.get(`${controller}/hvacassets?houseAssetId=${id}`);
        const data = response;
        return data;
    }

    async getunparented() {
        const response = await http.get(`${controller}/unparented`);
        const data = response;
        return data;
    }

    async getappliedclasses(assetId) {
        const response = await http.get(`${controller}/appliedclasses?assetId=` + assetId);
        const data = response;
        return data;
    }

    async getclasslist() {
        const response = await http.get(`${controller}/classlist`);
        const data = response;
        return data;
    }

    async getinstallproperties(classId){
        const response = await http.get(`${controller}/installproperties?classId=` + classId);
        const data = response;
        return data;
    }

    async getappliedtags(assetId) {
        const response = await http.get(`${controller}/appliedtags?assetId=` + assetId);
        const data = response;
        return data;
    }

    async applyclasses(assetId, applyList) {
        const response = await http.post(`${controller}/applyclasses?assetId=` + assetId, applyList);
        const data = response;
        return data;
    }

    async removeclasses(assetId, removeList) {
        const response = await http.post(`${controller}/removeclasses?assetId=` + assetId, removeList);
        const data = response;
        return data;
    }

    async removetestassetsordevices(org, removeAssets, removeIds) {
        const response = await http.post(`${controller}/removetestassetsordevices?orgName=${org}&removeAssets=${removeAssets}`, removeIds);
        const data = response;
        return data;
    }

    async searchfrommac(org, macIds) {
        const response = await http.post(`${controller}/searchfrommac?orgName=${org}`, macIds);
        const data = response;
        return data;
    }

    add(data) {
        return http.post(`${controller}`, data);
    }

    async addunparented(assetId, deviceId, houseAssetId, customerOrgId) {
        return await http.post(`${controller}/addUnparented?assetId=` + assetId + `&deviceId=` + deviceId + `&organizationId=` + customerOrgId + `&houseAssetId=` + houseAssetId);
    }

    update(data) {        
        return http.put(`${controller}`, data);
    }

    updateProperties(properties) {
        return http.put(`${controller}/updateproperties`, properties);
    }

    updatehomesparent(accountId, parentOrgId) {
        return http.post(`${controller}/updatehomesparent?accountId=` + accountId + `&parentOrgId=` + parentOrgId);
    }

    async deleteContractAssetss(org, user, homeId, hvacId, assets) {
        const response = await http.post(`${controller}/deletecontractassets?customerOrgId=${org}&user=${user}&homeAssetId=${homeId}&hvacAssetId=${hvacId}`, assets);
        const data = response;
        return data;
    }

    delete(id, mac, user = "") {
        return http.delete(`${controller}?assetId=${id}&mac=${mac}&user=${user}`);
    }

    async retrainasset(assetId, comment) {
        const response = await http.post(`${controller}/retrainasset?assetId=${assetId}&comment=${comment}`);
        const data = response;
        return data;
    }

    async setassetawaitingdata(assetId, comment) {
        const response = await http.post(`${controller}/setassetawaitingdata?assetId=${assetId}&comment=${comment}`);
        const data = response;
        return data;
    }

    async logassetdeletion(asset) {
        const response = await http.post(`/AssetDeletionLogs`, asset);
        const data = response;
        return data;
    }

    async refreshhomeassets(parentOrgId) {
        const response = await http.post(`${controller}/refreshhomeassets?parentOrganizationId=` + parentOrgId);
        const data = response;
        return data;
    }

    async getassetlinks(assetId) {
        const response = await http.get(`${controller}/assetLinks?assetId=` + assetId);
        const data = response;
        return data;
    }
    async getunappliedclasses(assetId) {
        const response = await http.get(`${controller}/unappliedclasses?assetId=` + assetId);
        const data = response;
        return data;
    }

    async getpropertydatalist(assetId) {
        const response = await http.get(`${controller}/propertydatalist?assetId=` + assetId);
        const data = response;
        return data;
    }

    async updateassetclasses(id, useCaseId) {
        const response = await http.post(`${controller}/updateassetclasses?assetId=${id}&useCaseId=${useCaseId}`);
        const data = response;
        return data;
    }

    async updateassettags(assetId, tagIds, addingTags = false) {
        const response = await http.post(`${controller}/updateassettags?assetId=${assetId}&addingTags=${addingTags}`, tagIds);
        const data = await response;
        return data;
    }
}

export default new AssetService();