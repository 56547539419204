import {Image} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {CardActionArea} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Player from "@vimeo/player";

function TrainingCard({ id, thumbnailImage, description, url, isWatched, onWatched, isMobile }) {
    
    const iframeRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasWatchedHalf, setHasWatchedHalf] = useState(false);

    useEffect(() => {
        if (!iframeRef.current) {
            return;
        }
        
        const player = new Player(iframeRef.current);

        player.on("timeupdate", ({ percent }) => {
            if (!hasWatchedHalf && !isWatched && percent >= 0.5) {
                setHasWatchedHalf(true);
                onWatched(id);
            }
        });

        return () => {
            player.off("timeupdate");
        };
    }, [hasWatchedHalf, isPlaying]);
    
    const onVideoClick = () => {
        setIsPlaying(true);
    }
    
    const renderPreview = () => {
        return (
          <div>
              {isWatched ?
                  <div className="row" style={{ alignItems: "center", position: "absolute", top: 0, right: 0, marginTop: '10px', marginRight: '10px' }}>
                      <p style={{ fontSize: '12px', marginBottom: '0px', marginRight: '3px' }}>(Previously Watched)</p>
                      <Tooltip title="Previously Watched" enterDelay={800} leaveDelay={300} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                          <VisibilityIcon style={{ color: "#0033a0", height: '28px', width: '28px' }} />
                      </Tooltip>
                  </div> : null}
              <Image src={`data:image/png;base64,${thumbnailImage}`} style={{ width: 422 + 'px', height: 256 + 'px', display: "inline-block" }} />
          </div>  
        );
    }
    
    const renderVideo = () => {
        return (
            <iframe
                ref={iframeRef}
                width="422"
                height="250"
                src={url}
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
                allowFullScreen
                style={{ border: 0, padding: 0 }}
            />
        );
    }

    return (
        <Card elevation={4} style={{height: 326 + 'px', width: 422 + 'px', ...(isMobile && {transform: "scale(0.75)"})}} onClick={onVideoClick}>
            <CardActionArea style={{height: '100%'}}>
                <CardContent style={{padding: '0px'}}>
                    {isPlaying ? renderVideo() : renderPreview()}
                    <div style={{borderBottom: '1px solid lightGray'}} />
                    <h6 style={{padding: '16px'}}>{description}</h6>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default TrainingCard;
