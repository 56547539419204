/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { BatterySymbol } from './InstallationCheck';

import AssetService from "../../services/asset.service";
import DeviceService from "../../services/device.service";
import AlertService from "../../services/alerts.service";

import ProvisioningService from "../../services/provisioning.service";

import SensorMapModal from "./SensorMapModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { Battery } from '@pxblue/react-progress-icons';
import '../../custom.css';

import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import { jsAsset, jsDevice, jsHouse } from "../../componentObjects";
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';

import TechnicianView from "../Technician/TechnicianInfo";
import useTechnicianInfo from "../Technician/UseTechnicianInfo";
import AssetHierarchyView from "../Assets/Hierarchy/AssetHierarchyView";
import DevTemp from './DevTemp';

import threadService from "../../services/thread.service";
import installService from "../../services/install.service";
import DeviceRequestsService from "../../services/devicerequests.service";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HighchartGraph from '../Graph/HighchartGraph';
import DatePicker from 'react-datepicker';
import { addHours } from 'date-fns';

import Pagination from '@material-ui/lab/Pagination';
import { object } from 'prop-types';



const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});
//const timeout = 300000; //300,000 milliseconds = 5 minutes
const DEVICE_STATUS_THREAD_NAME = "Device Status_LPX";
const STATUS_PROP_NAME = "Status";

const FLYCATCHER_PROPS = [
    "CompressorCurrent",
    "CoolingFanCurrent",
    "Current",
    "SubgRSSI",
    "WifiRSSI",
    "batteryVoltage",
    "deviceTemperature"
];
const PageSize = 20;
const LifePulseT10 = "LifePulseT10";


var contents = <div className="loading" style={{ display: "block" }}>
    <div className="loading-wrapper">
        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
    </div></div>;

function collect(props) {
    return props;
}

function getPrettyName(name) {
    if (name !== null && name !== undefined) {

        var split = name.split("__");
        return split[0];
    }
    else {
        return "";
    }
}

class Devices extends Component {
    static displayName = Devices.name;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.onAssetClick = this.onAssetClick.bind(this);
        this.onContextClick = this.onContextClick.bind(this);

        this.openRepairSensorMapModal = this.openRepairSensorMapModal.bind(this);
        this.showRepairSensorMapModal = this.showRepairSensorMapModal.bind(this);
        this.mapSensor = this.mapSensor.bind(this);
        this.updateSensorMap = this.updateSensorMap.bind(this);
        this.onHVACSystemSelect = this.onHVACSystemSelect.bind(this);
        this.setTechnicianInfo = this.setTechnicianInfo.bind(this);

        this.openTTConfirmModal = this.openTTConfirmModal.bind(this);
        this.showTTConfirmModal = this.showTTConfirmModal.bind(this);
        this.onTTConfirm = this.onTTConfirm.bind(this);
        this.refreshStatus = this.refreshStatus.bind(this);
        this.refreshAllStatus = this.refreshAllStatus.bind(this);
        this.refreshorgetDevicelogs = this.refreshorgetDevicelogs.bind(this);

        this.setDevices = this.setDevices.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.getHighchartsTimelineData = this.getHighchartsTimelineData.bind(this);
        this.populateAssetData = this.populateAssetData.bind(this);
        this.onViewRuns = this.onViewRuns.bind(this);
        this.getFirmwareData = this.getFirmwareData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);


        this.state = {
            deviceList: [],
            assetList: [],

            currentHVACSystem: jsHouse,
            currentAsset: jsAsset,
            currentDevice: jsDevice,
            currentInstall: {},
            currentInstallTechStr: "",

            loading: false,
            assetInfoLoading: false,
            configLoading: false,
            isSelected: -1,
            isFlyCatcher: false,
            showRepairSensorMap: false,
            showTTConfirm: false,
            modalMessage: "",
            modalTitle: "",
            statusMessage: null,
            showStatusMessage: null,
            tooltipSensorOpen: false,
            setTooltipSensorOpen: true,
            tooltipWifiOpen: false,
            deviceConfiguration: "",
            devTTUrl: "",
            updateStatusProgress: "",
            isUpdated: false,
            isTimeout: false,
            statusLoading: false,
            statusLoadingList: [],
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,

            sensorsLoading: false,
            currentSensor: null,
            sensorList: [],
            unmappedProperties: [],
            mappedSensors: [],
            mapDict: {}, //{propertyIndex: (isMapped ? sensorndex : -1)}
            isSystemChanged: false,
            logListGateway: [],
            logListSH1: [],
            logListSH2: [],
            selectedDeviceId: "",
            deviceGraph: [],
            visibility: {
                wifirssi: true,
                subgrssi: false,
                batteryVoltage: false,
                current: false,
                devicetemperature: false
            },
            dpStartDateTime: null,
            dpEndDateTime: null,
            startDateTimeStr: "",
            endDateTimeStr: "",
            firmware: {
                gatewayversion: 0,
                serverLoggedin: 0,
                firmwareversion: 0,
                shtype: "",
                class: "",
                gatewayLoggedin: 0,
            },
            pageCountGateway: 1,
            currentPageGateway: 1,
            pageCountSH1: 1,
            currentPageSH1: 1,
            pageCountSH2: 1,
            currentPageSH2: 1,
            paginglogListGateway: [],
            paginglogListSH1: [],
            paginglogListSH2: [],
            showDeviceLogs: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
        if (!this.props.isLoading) {
            this.populateAssetData();
            this.setState({ loading: true });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async setDevices() {
        if (this.state.currentHVACSystem.children.length) {
            var startTime = performance.now();
            let devices = this.state.currentHVACSystem.children.map(d => d.id);
            await AssetService.getassetsproperties(devices).then(hvacsResponse => {
                if (this._isMounted) {
                    if (hvacsResponse.data !== null && hvacsResponse.data !== undefined) {
                        var newAssetList = hvacsResponse.data;
                        if (newAssetList !== null && newAssetList !== undefined && newAssetList.length && this.props.hasServiceTier && this.props.serviceTier < 3)
                            newAssetList = newAssetList.filter(n => n.type.trim() === "Thermostat");
                        for (let i = 0; i < newAssetList.length; i++) {
                            for (let j = 0; j < this.state.currentHVACSystem.children.length; j++) {
                                if (newAssetList[i].assetId === this.state.currentHVACSystem.children[j].id) {
                                    newAssetList[i].mac = this.state.currentHVACSystem.children[j].name;
                                    newAssetList[i].name = this.state.currentHVACSystem.children[j].name;
                                }
                            }
                        }
                        this.setState({ loading: false, assetList: newAssetList, currentAsset: jsAsset, currentDevice: jsDevice, isSelected: -1, deviceList: [] }, async () => {
                            await AssetService.getassetdevices(this.state.currentHVACSystem.id, this.state.assetList)
                                .then(response => {
                                    if (this._isMounted) {
                                        let id = response.data.length > 0 ? response.data[0].mac : "";
                                        this.setState({ deviceList: response.data, loading: false, isSystemChanged: false, selectedDeviceId: id });
                                    }
                                });
                            var elapsedTime = performance.now() - startTime;
                            Timing("Assets Loading", "loading", elapsedTime, "Customer Assets Loading");
                        });
                    } else {
                        this.setState({ loading: false }, () => {
                            var elapsedTime = performance.now() - startTime;
                            Timing("Assets Loading", "loading", elapsedTime, "Customer Assets Loading");
                        });
                    }
                }
            });
        } else {
            this.setState({ loading: false, assetList: [], currentAsset: jsAsset }, async () => {
                var elapsedTime = performance.now() - startTime;
                Timing("Assets Loading", "loading", elapsedTime, "Customer Assets Loading");
            });
        }
    }
    getMacId(assets) {
        if (assets !== null && assets !== undefined && assets.length) {
            if (assets.length > 1) {
                if (assets.filter(a => a.type.trim() !== "Thermostat").length)
                    return assets.filter(a => a.type.trim() !== "Thermostat")[0].mac;
            }
            else
                return assets[0].mac;
        }
    }

    cleanMapping(path) {
        if (path) {
            var namesArray = path.split(" / ");

            for (var i = 0; i < namesArray.length; i++) {
                namesArray[i] = getPrettyName(namesArray[i]);
            }

            var resultString = namesArray.join(" / ");

            return resultString;
        }
        else {
            return "";
        }
    }

    setTechnicianInfo(e) {
        if (e !== undefined) {
            this.setState({ isFlyCatcher: e.gatewayTypeId == 2 ? true : false });
        }
    }

    onHVACSystemSelect(e) {
        if (e !== undefined) {
            Event("Customer HVAC System Selected", "User selected one of the customers HVAC systems in dropdown", "Customer HVAC System Selected");
            this.setState({
                currentHVACSystem: e,
                currentDevice: jsDevice,
                deviceConfiguration: "",
                isSelected: -1,
                isSystemChanged: e.id !== this.state.currentHVACSystem.id
            }, async () => {
                await this.setDevices(this.state.currentHVACSystem);
            });
        } else {
            this.setState({ loading: false });
        }
    }

    async onHomeSelect(e) {
        let x = e.target.value;

        this.setState({ newHouseLoading: true, isSelected: -1 }, () => {
            this.getDevices(x);
        });
    }

    async onViewRuns() {

        let devices = this.state.assetList;
        await AlertService.getdevicegraphdata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, devices)
            .then(response => {
                if (response.data != null) {
                    this.setState({
                        deviceGraph: response.data,
                        loading: false,
                        configLoading: false
                    });
                }

            }).catch(e => {
                this.setState({
                    loading: false
                });
            });
    }

    async getFirmwareData() {
        let endDate = moment().format();
        let startDate = moment(endDate).add(-24, 'hours').format();
        let selectedDevice = this.state.selectedDeviceId?.split("-");
        let configData = JSON.parse(this.state.deviceConfiguration)
        let gatewayversion = 0;
        let serverLoggedin = 0;
        let gatewayLoggedin = 0;
        let firmwareversion = 0;
        let assetLiast = this.state.assetList;
        let assetClasses = "";
        assetLiast = assetLiast?.find(x => x.name == this.state.selectedDeviceId)?.properties;
        let assettype = assetLiast?.find(x => x.description == "shType")?.value;
        gatewayversion = this.state.logListGateway?.length > 0 ? this.state.logListGateway[0]?.firmwareRevision : 0;
        gatewayLoggedin = this.state.logListGateway?.length > 0 ? this.state.logListGateway?.filter(x => x.date >= startDate)?.length : 0;
        serverLoggedin = this.state.logListSH1?.length > 0 ? this.state.logListGateway?.filter(x => x.date >= startDate)?.length : 0;
        firmwareversion = this.state.logListSH1?.length > 0 ? this.state.logListSH1[0]?.firmwareRevision : 0;

        if (configData != undefined && configData?.mac != undefined && configData?.mac != "") {
            let assetId = (configData?.mac == selectedDevice[0]) ? configData?.lpxs?.find(x => x.id == selectedDevice[1])?.mac : null;
            let assets = (configData?.mac == selectedDevice[0]) ? configData?.lpxs?.find(x => x.id == selectedDevice[1])?.assets : null;
            let gatewayversion = configData?.version;
            let noTimesassetLogin = 0;
            if (assets != null && assets != undefined && assets.length > 0) {

                assetClasses = assets[0]?.ttClasses
            }
        }

        this.setState({
            configLoading: false,
            firmware: {
                serverLoggedin: serverLoggedin,
                firmwareversion: firmwareversion,
                shtype: assettype,
                class: assetClasses,
                gatewayversion: gatewayversion,
                gatewayLoggedin: gatewayLoggedin
            }
        });


    }
    async getDevices(id) {
        var startTime = performance.now();
        this.setState({
            currentHome: this.state.homeList[id], isSelected: -1
        }, async () => {
            await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                if (this._isMounted)
                    this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                        // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                        if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                            await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                if (this._isMounted)
                                    this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                        //await DeviceService.get(this.state.assetList)
                                        //    .then(response => {
                                        //        if (this._isMounted)
                                        //            this.setState({ deviceList: response.data }, () => {
                                        //                this.setState({ newHouseLoading: false }, () => {
                                        //                    var elapsedTime = performance.now() - startTime;
                                        //                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        //                });
                                        //            })
                                        //    });

                                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                            .then(response => {
                                                if (this._isMounted)
                                                    this.setState({ deviceList: response.data }, async () => {
                                                        var macId = this.getMacId(this.state.assetList);
                                                        await installService.getinstallbymacid(macId).then(response3 => {
                                                            let install = response3;
                                                            if (install !== null && install !== undefined) {
                                                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                let isFlyCatcher = install.gatewayTypeId == 2;
                                                                this.setState({
                                                                    currentInstall: install,
                                                                    currentInstallTechStr: techStr,
                                                                    isFlyCatcher: isFlyCatcher,
                                                                    newHouseLoading: false
                                                                }, () => {
                                                                    var elapsedTime = performance.now() - startTime;
                                                                    Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                });
                                                            }
                                                        })
                                                    })
                                            });
                                    });
                            });
                        }
                        else {
                            this.setState({ newCustomerLoading: false });
                        }

                    });
            });
        });
    }

    async onAssetClick(index) {
        let x = index;
        let prettyPrint = "";
        var startTime = performance.now();
        Event("Device Clicked", "User clicked on device in table", "Customer Device Clicked");

        this.setState({ assetInfoLoading: true, configLoading: true, currentDevice: jsDevice, isSelected: index }, () => {
            this.setState({
                currentDevice: this.state.deviceList !== undefined && this.state.deviceList.length > x ? this.state.deviceList[x] : jsDevice,
                currentAsset: this.state.assetList !== undefined && this.state.assetList.length > x ? this.state.assetList[x] : jsAsset,
                statusMessage: ""
            }, async () => {
                let DeviceId = this.state.currentDevice.mac;
                await ProvisioningService.getconfig(this.state.currentDevice.mac)
                    .then(response => {
                        prettyPrint = JSON.stringify(response.data, null, 2);
                        this.setState({ deviceConfiguration: prettyPrint, assetInfoLoading: false, loading: false, selectedDeviceId: DeviceId }, () => {
                            this.refreshorgetDevicelogs();
                            var elapsedTime = performance.now() - startTime;
                            Timing("Device Info Loading", "assetInfoLoading", elapsedTime, "Customer Device Info Loading");
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ assetInfoLoading: false, configLoading: false, loading: false });
                    })
                await AssetService.getappliedtags(this.state.currentAsset.assetId).then(response => {
                    if (response.status === 200 && response.data && response.data.some(t => t.name === LifePulseT10)) {
                        this.setState({ showDeviceLogs: false });
                    }
                    else {
                        this.setState({ showDeviceLogs: true });
                    }
                });

            });
        });
    }

    onContextClick = (e, data) => {
        this.setState({ devTTUrl: "https://app.lifewhere.com/twins/detail/" + data.name })
        this.openTTConfirmModal();
    }

    showTTConfirmModal() {
        this.setState({ showTTConfirm: !this.state.showTTConfirm });
    }

    openTTConfirmModal() {
        this.setState({ modalMessage: "You are about to leave the LifeWhere portal and be taken to the Device Properties page in TwinThread. Do you wish to continue?", modalTitle: "Leave LifeWhere Portal" }, () => {
            this.showTTConfirmModal();
        });
    }

    onTTConfirm() {
        Event("View Device Properties Clicked", "User clicked view device properties and is being taken to TwinThread Properties view", "View Device Properties Clicked");
        window.open(this.state.devTTUrl, '_blank'); // opens new tab with TwinThread twins detail page
        this.showTTConfirmModal(); // closes modal
    }

    showRepairSensorMapModal() {
        this.setState({
            showRepairSensorMap: !this.state.showRepairSensorMap
        }, () => {
            if (this.state.showRepairSensorMap)
                ModalView("repairSensorMapConfirm");
        });
        window.scrollTo(0, 0);
    }

    openRepairSensorMapModal() {
        if (this.state.isSelected != -1 && this.state.currentDevice != jsDevice) {
            this.setState({ sensorsLoading: true, showRepairSensorMap: true }, async () => {
                await DeviceService.getsensormapitems(this.state.currentDevice.mac.split('-')[0])
                    .then(response => {
                        if (this._isMounted) {
                            let sensors = response.data;
                            let isMultiCT = this.state.isFlyCatcher && this.state.currentAsset.type == "AirConditioner";

                            let unmappedProps = this.getMapProperties(this.state.currentAsset.properties, isMultiCT);
                            let mapDict = {};
                            let currentSensor = null;
                            let mappedSensors = [];
                            if (sensors !== null && sensors !== undefined && sensors.length) {
                                if (isMultiCT)
                                    mappedSensors = sensors.filter(s => s.status === "Active" && (FLYCATCHER_PROPS.find(f => s.mappedTo.includes(f)) !== undefined) && s.mappedTo.includes(this.state.currentDevice.deviceId));
                                else
                                    mappedSensors = sensors.filter(s => s.status === "Active" && s.mappedTo.includes(" Current") && s.mappedTo.includes(this.state.currentDevice.deviceId));

                                if (mappedSensors !== null && mappedSensors !== undefined && mappedSensors.length)
                                    currentSensor = mappedSensors.find(m => m.mappedTo.includes(this.state.currentDevice.deviceId));//mappedSensors[0];

                                sensors = sensors.filter(s => s.status === "Unmapped");
                                mapDict = unmappedProps.map((prop, index) => {
                                    return -1;
                                });
                            }

                            this.setState({
                                sensorList: sensors,
                                currentSensor: currentSensor,
                                mappedSensors: mappedSensors,
                                unmappedProperties: unmappedProps,
                                mapDict: mapDict,
                                sensorsLoading: false
                            });
                        }
                    })
            })
        }
    }

    getMapProperties(assetProperties, isMultiCT = true) {
        let props = [];
        let currentProps = [];
        if (isMultiCT)
            currentProps = assetProperties.filter(p => FLYCATCHER_PROPS.includes(p.name));
        else
            currentProps = assetProperties.filter(p => p.name == "Current");
        props = currentProps;

        return props;
    }

    mapSensor(propertyIndex, sensorIndex) {
        let mapDict = this.state.mapDict;
        mapDict.forEach((item, index) => {
            if (index == propertyIndex) {
                mapDict[index] = sensorIndex;
            }
            else if (mapDict[index] == sensorIndex) {
                mapDict[index] = -1;
            }
        });

        this.setState({ mapDict: mapDict });
    }

    updateSensorMap() {
        this.setState({ loading: true }, () => {
            if (this.state.currentDevice != null && this.state.currentDevice !== undefined && this.state.currentDevice.deviceId !== "" /*todo verify all props mapped*/) {
                let sensorDict = {}; //{ sensorName: sensorObject }
                for (let i = 0; i < this.state.unmappedProperties.length; i++) {
                    if (this.state.mapDict[i] !== -1) {
                        let sensor = this.state.sensorList[this.state.mapDict[i]];
                        let property = this.state.unmappedProperties[i];
                        sensorDict[property.name] = sensor; //sensor.name
                    }
                }

                DeviceService.repairsensormap(this.state.currentHVACSystem.id, this.state.currentDevice.mac, sensorDict)
                    .then(response => {
                        let message = "";

                        if (response.data === true) {
                            message = "Successfully repaired Sensor Map!";
                            Event("Repair Sensor Map Clicked", "User clicked repair sensor map button", "Repair Sensor Map Clicked");
                            this.setState({ statusMessage: message, showRepairSensorMap: false }, async () => {
                                this.setDevices(this.state.currentHVACSystem);
                            })
                        }
                        else {
                            message = "Failed to repair Sensor Map!";

                            this.setState({ statusMessage: message, loading: false, showRepairSensorMap: false });
                        }

                    }).catch(e => {
                        console.log(e);
                    });
            }
            else {
                let message = "No Device selected!";
                this.setState({ statusMessage: message, loading: false, showRepairSensorMap: false });
            }
        });
    }

    getDate(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("l h:mm:ss A");
        }
        return (date);
    }

    getSigStamp(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("[on] MMM Do, YYYY [at] h:mm A");
        }
        return (date);
    }

    getSigStampAlone(str) {
        var date = "";
        if (moment(str).isValid()) {
            return moment.utc(str).local().format("MMM Do, YYYY [at] h:mm A");
        }
        return (date);
    }

    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    refreshAllStatus() {
        var statusUpdateList = [];
        for (let i = 0; i < this.state.deviceList.length; i++) {
            statusUpdateList[i] = true;
            this.refreshStatus(i);
        }
        this.setState({ statusLoadingList: statusUpdateList })
    }

    setUpPagination(selectedTab) {
        let tableList;
        let pageCount = 1;
        let startIndex = 0;
        let page = null;
        let endIndex = 0;
        switch (selectedTab) {
            case 0:
                tableList = this.state.logListGateway;
                if (tableList.length > PageSize) {
                    pageCount = Math.ceil(tableList.length / PageSize);
                }
                endIndex = (this.state.currentPageGateway * PageSize) - 1;
                if (this.state.logListGateway !== null && this.state.logListGateway !== undefined) {
                    page = this.state.logListGateway.slice(startIndex, endIndex);
                }
                this.setState({ pageCountGateway: pageCount, paginglogListGateway: page });
                break;

            case 1:
                tableList = this.state.logListSH1;
                if (tableList.length > PageSize) {
                    pageCount = Math.ceil(tableList.length / PageSize);
                }
                endIndex = (this.state.currentPageSH1 * PageSize) - 1;
                if (this.state.logListSH1 !== null && this.state.logListSH1 !== undefined) {
                    page = this.state.logListSH1.slice(startIndex, endIndex);
                }
                this.setState({ pageCountSH1: pageCount, paginglogListSH1: page });
                break;

            case 2:
                tableList = this.state.logListSH2;
                if (tableList.length > PageSize) {
                    pageCount = Math.ceil(tableList.length / PageSize);
                }
                endIndex = (this.state.currentPageSH2 * PageSize) - 1;
                if (this.state.logListSH2 !== null && this.state.logListSH2 !== undefined) {
                    page = this.state.logListSH2.slice(startIndex, endIndex);
                }
                this.setState({ pageCountSH2: pageCount, paginglogListSH2: page });
                break;
        }
    }

    onChangePage(e, page) {
        let pageSelected = parseInt(e.target.innerText, 10);
        if (this._isMounted)
            switch (page) {
                case 0:
                    this.setState({ currentPageGateway: pageSelected }, () => {
                        this.getPageList(page);
                    });
                    break;
                case 1:
                    this.setState({ currentPageSH1: pageSelected }, () => {
                        this.getPageList(page);
                    });
                    break;
                case 2:
                    this.setState({ currentPageSH2: pageSelected }, () => {
                        this.getPageList(page);
                    });
                    break;

            }
    }

    getPageList(selectedgateway) {
        let page = null;
        let endIndex = 0;
        let startIndex = 0;
        switch (selectedgateway) {
            case 0:
                endIndex = (this.state.currentPageGateway * PageSize) - 1;
                startIndex = (this.state.currentPageGateway - 1) * PageSize;

                if (this.state.logListGateway !== null && this.state.logListGateway !== undefined) {
                    page = this.state.logListGateway.slice(startIndex, endIndex);
                }

                this.setState({ paginglogListGateway: page });
                break;
            case 1:
                endIndex = (this.state.currentPageSH1 * PageSize) - 1;
                startIndex = (this.state.currentPageSH1 - 1) * PageSize;

                if (this.state.logListSH1 !== null && this.state.logListSH1 !== undefined) {
                    page = this.state.logListSH1.slice(startIndex, endIndex);
                }

                this.setState({ paginglogListSH1: page });
                break;
            case 2:
                endIndex = (this.state.currentPageSH2 * PageSize) - 1;
                startIndex = (this.state.currentPageSH2 - 1) * PageSize;

                if (this.state.logListSH2 !== null && this.state.logListSH2 !== undefined) {
                    page = this.state.logListSH2.slice(startIndex, endIndex);
                }

                this.setState({ paginglogListSH2: page });
                break;
        }
    }


    async refreshStatus(index) {
        Event("Refresh Status Clicked", "User refreshed status in Devices page", "Refresh Status Clicked");
        let loadingCheck = [];
        let assetId = parseInt(this.state.deviceList[index].parentId, 10);
        let startDateInt = new Date().getTime();

        this.setState(prevState => ({
            statusLoadingList: {
                ...prevState.statusLoadingList,
                [index]: true
            }
        }));
        this.setState({ statusLoading: true, isTimeout: false, updateStatusProgress: "Running Status Update" });

        // Get the Asset Links and find the Device Status_LPX Thread details
        await AssetService.getassetlinks(assetId)
            .then(response1 => {
                if (response1.data.threads && response1.data.threads.length > 0) {
                    var assetThreads = response1.data.threads;
                    var deviceStatusThreads = assetThreads.filter(a => a.name === DEVICE_STATUS_THREAD_NAME);
                    if (deviceStatusThreads && deviceStatusThreads.length > 0) {
                        this.setState({ deviceStatusThread: deviceStatusThreads }, async () => {
                            // Run the Device Status_LPX Thread
                            await threadService.runthread(deviceStatusThreads[0].threadId)
                                .then(response2 => {
                                    if (response2.data && response2.data.blockDebugInfo && response2.data.blockDebugInfo.length > 0 && response2.data.blockDebugInfo[0].outputs.length > 0) {
                                        this.setState({ deviceStatusThread: deviceStatusThreads }, async () => {
                                            // Get Properties to find Status property
                                            await AssetService.getpropertydatalist(assetId)
                                                .then(response3 => {
                                                    let statusProp = response3.data.find(prop => prop.name === STATUS_PROP_NAME);
                                                    //let updateTime = statusProp.timestamp;
                                                    //let updateTimestamp = new Date(parseInt(updateTime.match(/\d+/)[0], 10));
                                                    //let updateTimeInt = updateTimestamp.getTime();
                                                    let startDate = new Date(startDateInt);
                                                    let endDate = new Date();

                                                    let trendDataRequest = {
                                                        assetId: parseInt(assetId, 10),
                                                        propertyList: [parseInt(statusProp.propertyId, 10)],
                                                        startTime: startDate.toISOString(),
                                                        endTime: endDate.toISOString(),
                                                        maxPoints: 10,
                                                        summaryType: 11
                                                    };
                                                    // Get the Status Property Trend Data
                                                    AlertService.getstringtrenddata(trendDataRequest)
                                                        .then(response4 => {
                                                            if (response4.data && response4.data.length > 0) {
                                                                let latestTimestamp = response4.data[0].timestamps[response4.data[0].timestamps.length - 1];
                                                                let latestTimestampInt = new Date(latestTimestamp).getTime();
                                                                // If the Status property was updated since the start of this process, then we have the latest Status value
                                                                if (startDateInt < latestTimestampInt) {
                                                                    let devices = this.state.deviceList;
                                                                    devices[index].status = response4.data[0].values[response4.data[0].values.length - 1];
                                                                    this.setState(prevState => ({
                                                                        statusLoadingList: {
                                                                            ...prevState.statusLoadingList,
                                                                            [index]: false
                                                                        }
                                                                    }));
                                                                    this.setState({ isUpdated: true, deviceList: devices });
                                                                    loadingCheck = this.state.statusLoadingList;
                                                                    let isDoneLoading = Object.keys(loadingCheck).filter(key => loadingCheck[key]).every(v => v === false);
                                                                    if (isDoneLoading) {
                                                                        this.setState({ updateStatusProgress: "Successfully Updated Status!" }, async () => {
                                                                            await this.delay(1000);
                                                                            this.setState({ statusLoading: false, updateStatusProgress: "" });
                                                                        });
                                                                    }
                                                                }
                                                            }

                                                        });
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                    this.failedDeviceStatusUpdate(index);
                                                });

                                        });
                                    }
                                    else {
                                        this.failedDeviceStatusUpdate(index);
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.failedDeviceStatusUpdate(index);
                                });
                        });
                    }
                    else {
                        this.failedDeviceStatusUpdate(index);
                    }
                }
                else {
                    this.failedDeviceStatusUpdate(index);
                }
            })
            .catch(e => {
                console.log(e);
                this.failedDeviceStatusUpdate(index);
            });
    }

    failedDeviceStatusUpdate(index) {
        this.setState(prevState => ({
            statusLoadingList: {
                ...prevState.statusLoadingList,
                [index]: false
            }
        }));
        this.setState({ updateStatusProgress: "Failed to Update Status" }, async () => {
            await this.delay(1000);
            this.setState({ statusLoading: false, updateStatusProgress: "" });
        });
    }

    getDeviceStatus(index) {
        if (this.state.statusLoadingList[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else {
            let deviceStatus = this.state.deviceList[index].status;
            return (
                <span href="#" id="TooltipStatus">{deviceStatus}
                    <RefreshIcon onClick={() => { this.refreshStatus(index) }} />
                </span>
            );
        }
    }

    getRepairButtonDisabled() {
        let disabled = false;

        if (this.state.isSelected === -1 || this.state.currentDevice === null || this.state.currentDevice === undefined || this.state.currentDevice.sensorMap === undefined)
            disabled = true;

        return disabled;
    }

    getDateString(date) {
        let dateStr = moment(date).format('YYYY-MM-DD hh:mm:ss a');

        return dateStr;
    }
    async refreshorgetDevicelogs() {
        let macIdList = [];
        let deviceId = this.state.selectedDeviceId;
        let devices = this.state.assetList;
        let deviceConfig = this.state.deviceConfiguration;
        let deviceConfigObj = this.state.deviceConfiguration != "" && JSON.parse(this.state.deviceConfiguration);
        for (var keyName of Object.keys(deviceConfigObj)) {
            if (keyName == 'mac') {
                macIdList.push(deviceConfigObj?.mac);
            }

            if (keyName == 'lpxs') {
                if (deviceConfigObj?.lpxs?.length > 0) {
                    for (var lpx of deviceConfigObj?.lpxs) {
                        if (lpx?.mac !== undefined && lpx?.mac !== null) {
                            macIdList.push(lpx?.mac);
                        }
                    }
                }
            }

        }

        let devicemac = deviceConfigObj?.lpxs?.find(x => x.id == this.state.selectedDeviceId.split("-")[1])?.mac;
        //let devicemacId = devicemac.find(x => x.id == this.state.selectedDeviceId.split("-")[2])

        await AlertService.getdevicegraphdata(this.state.startDateTimeStrPlus, this.state.endDateTimeStrPlus, devices)
            .then(response => {
                if (response.data !== null) {
                    this.setState({
                        deviceGraph: response.data,
                        loading: false,
                        configLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    loading: false,
                    configLoading: false
                });
            });

        if (macIdList !== undefined && devicemac !== null && macIdList.length > 0) {
            macIdList.forEach(async (mac, i) => {
                await DeviceRequestsService.getdevicerequests(mac)
                    .then(response => {
                        if (response.data != null) {
                            switch (i) {
                                case 0:
                                    this.setState({
                                        logListGateway: response.data?.reverse(),

                                    }, () => {
                                        this.getFirmwareData();
                                        this.setUpPagination(i);
                                    });
                                    break;
                                case 1:
                                    this.setState({
                                        logListSH1: response.data?.reverse(),

                                    }, () => {

                                        this.setUpPagination(i);
                                    });
                                    break;
                                case 2:
                                    this.setState({
                                        logListSH2: response.data?.reverse(),

                                    }, () => {

                                        this.setUpPagination(i);
                                    });
                                    break;
                            }
                           

                        }
                    }
                    )
                    .catch(e => {
                        console.log(e);
                        this.setState({
                            configLoading: false
                        });
                    });
            });
        }
        else {
            this.setState({
                logList: [],
                paginglogList: [],
                searchLoading: false,
                loading: false,
                configLoading: false
            });
        }

    }

    onChangeStart(e) {
        var start = moment(e).toDate();
        var startStr = moment(e).utc().format();
        this.setState({ dpStartDateTime: start, startDateTimeStrPlus: startStr });
    }

    onChangeEnd(e) {
        var end = moment(e).toDate();
        var endStr = moment(e).utc().format();
        this.setState({ dpEndDateTime: end, endDateTimeStrPlus: endStr });
    }

    handleCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

    populateAssetData() {
        if (!this.props.isLoading) {
            var now = moment();
            var before = moment(now).add(-1, 'hours');
            var beforeStr = before.utc().format();
            var nowStr = now.utc().format();
            var dpSTR = before.toDate();    //date picker string 
            var dpSTR2 = now.toDate();     // date picker string end
            this.setState({ loading: true, dpStartDateTime: dpSTR, startDateTimeStrPlus: beforeStr, dpEndDateTime: dpSTR2, endDateTimeStrPlus: nowStr }, async () => {
                if (this.props.currentCustomerId === "-1") {
                    this.setState({ loading: false });
                }
            });
        }
    }

    getHighchartsTimelineData() {
        let timelineDataList = [];
        let batteryArr = this.state.deviceGraph?.find(x => (x.deviceId == this.state.selectedDeviceId));

        if (this.state.deviceGraph?.length >= 0 && this.state.deviceGraph?.find(x => (x.deviceId == this.state.selectedDeviceId) != null) && batteryArr != null && batteryArr != undefined) {
            {
                var dt = new Date(batteryArr.startTime);
                var pointStartDT = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());

                if (this.state.visibility.wifirssi) {
                    let value = batteryArr.dataArray[batteryArr.deviceId]["device_WifiRSSI"].values
                    let batteryData = {
                        name: 'WiFi',
                        data: value,
                        color: 'darkolivegreen',
                        type: 'line',
                        zIndex: 0,
                        pointStart: pointStartDT,
                        pointInterval: 1000,
                        yAxis: 1
                    };
                    timelineDataList = timelineDataList.concat(batteryData);

                }
                if (this.state.visibility.subgrssi) {
                    let value = batteryArr.dataArray[batteryArr.deviceId]["device_subgRSSI"].values
                    let batteryData = {
                        name: '	SensorHub',
                        data: value,
                        color: 'darkred',
                        type: 'line',
                        zIndex: 0,
                        pointStart: pointStartDT,
                        pointInterval: 1000,
                        yAxis: 1
                    };
                    timelineDataList = timelineDataList.concat(batteryData);
                }
                if (this.state.visibility.batteryVoltage) {
                    let value = batteryArr.dataArray[batteryArr.deviceId]["device_batteryvoltage"].values
                    let batteryData = {
                        name: 'Battery voltage',
                        data: value,
                        color: 'red',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartDT,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    timelineDataList = timelineDataList.concat(batteryData);
                }
                if (this.state.visibility.current) {
                    let value = batteryArr.dataArray[batteryArr.deviceId]["device_current"].values
                    let batteryData = {
                        name: 'Current',
                        data: value,
                        color: '#f0b169',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartDT,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    timelineDataList = timelineDataList.concat(batteryData);
                }
                if (this.state.visibility.devicetemperature) {
                    let value = batteryArr.dataArray[batteryArr.deviceId]["device_temp"].values
                    let batteryData = {
                        name: 'Temperature',
                        data: value,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartDT,
                        pointInterval: 1000,
                        yAxis: 1
                    };
                    timelineDataList = timelineDataList.concat(batteryData);
                }
            }

        }
        return timelineDataList;
    }
    renderGraphButtons() {
        let checkboxes =
            <div className="radio-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    <input type="checkbox" value="wifirssi" checked={this.state.visibility.wifirssi} id="id_wifirssi" onChange={this.handleCheckbox} />
                    &nbsp; Wifi &nbsp;&nbsp;
                </div>
                <div>
                    <input type="checkbox" value="subgrssi" checked={this.state.visibility.subgrssi} id="id_subgrssi" onChange={this.handleCheckbox} />
                    &nbsp; 	SensorHub &nbsp;&nbsp;
                </div>
                <div>
                    <input type="checkbox" value="batteryVoltage" checked={this.state.visibility.batteryVoltage} id="id_batteryVoltage" onChange={this.handleCheckbox} />
                    &nbsp; Battery Voltage &nbsp;&nbsp;
                </div>
                <div>
                    <input type="checkbox" value="current" checked={this.state.visibility.current} id="id_current" onChange={this.handleCheckbox} />
                    &nbsp; Current &nbsp;&nbsp;
                </div>
                <div>
                    <input type="checkbox" value="devicetemperature" checked={this.state.visibility.devicetemperature} id="id_devicetemperature" onChange={this.handleCheckbox} />
                    &nbsp; Temperature &nbsp;&nbsp;
                </div>
            </div>
        let viewRunsButton = !this.state.thermostatSelected ?
            <div className="row centered">
                <button style={{ marginTop: '.7em' }} className="secondary-btn btn-small" onClick={this.onViewRuns} disabled={this.state.assetSelected || !this.state.disableGraphOptions ? false : true}>View </button>
            </div>
            : null;
        return (
            <div>
                {viewRunsButton}
                <hr />
                <div className="lastItem">
                    <div className="detailTitle">Graph Display Options</div>
                    {checkboxes}
                </div>
            </div>
        );
    }
    renderGraph() {
        let rightCol =
            <div className="col-sm-12 col-md-4">
                <div className="grey-container">
                    <h5 className="sectionTitle">
                        Graph Options
                    </h5>
                    <div className="row">
                        <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                            <div style={{ marginLeft: '10px' }}>Start:</div>
                        </div>
                        <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{ textAlign: 'center' }}>
                            <DatePicker
                                customInput={<input className="txt-detail" disabled={this.state.disableGraphOptions} style={{ maxWidth: '274px', textAlign: "center", paddingRight: '18px' }} />}
                                id="startDate"
                                name="startDate"
                                selected={this.state.dpStartDateTime}
                                onChange={date => { Event("Run Start Date Click", "User clicked start date picker to change it", "Run Start Date Changed"); this.onChangeStart(date); }}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                popperProps={{
                                    positionFixed: true
                                }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-2 col-md-6 col-lg-3 col-xl-2">
                            <div style={{ marginLeft: '10px' }}>End:</div>
                        </div>
                        <div className="col-sm-9 col-md-12 col-lg-9 centered" style={{ textAlign: 'center' }}>
                            <DatePicker
                                customInput={<input className="txt-detail" disabled={this.state.disableGraphOptions} style={{ maxWidth: '274px', textAlign: "center", paddingRight: '18px', marginBottom: '0px' }} />}
                                id="endDate"
                                name="endDate"
                                selected={this.state.dpEndDateTime}
                                onChange={date => { Event("Run End Date Click", "User clicked end date picker to change it", "Run End Date Changed"); this.onChangeEnd(date); }}
                                dateFormat="MM/dd/yyyy h:mm:ss aa"
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement="top-end"
                                minDate={this.state.dpStartDateTime}
                                maxDate={addHours(this.state.dpStartDateTime, 48)}
                                popperProps={{
                                    positionFixed: true
                                }}
                            />
                        </div>
                    </div>
                    {this.renderGraphButtons()}
                </div>
                <br /><br />
            </div>;
        let deviceGraph = (this.state.deviceGraph && this.state.deviceGraph != null && this.state.deviceGraph !== undefined && !this.state.configLoading) ?
            <div style={{ marginTop: 10 + "px", marginRight: 15 + "px" }}>
                <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsTimelineData()} hasTempAxis={this.state.visibility.devicetemperature} showTemp={this.state.visibility.devicetemperature} isAirConditioner={true}
                    haswifiAxis={this.state.visibility.subgrssi || this.state.visibility.wifirssi}
                />

            </div>
            : <div style={{ marginTop: 10 + "px", marginLeft: 20 + "px", marginRight: 25 + "px" }}>
                {
                    "There is no data for this unit or loading data"
                }
                <br /><br />
            </div>;

        let devicePanel = (this.state.deviceGraph && this.state.deviceGraph != null && this.state.deviceGraph !== undefined && this.state.deviceGraph.length > 0) ?
            <div className="row">
                <div className="col-sm-12 col-md-8">
                    <div style={{ marginTop: 10 + "px", marginRight: 15 + "px" }}>
                        {deviceGraph}

                        <br /><br />
                    </div>
                </div>
                {rightCol}
            </div>
            : <div className="row">
                <div className="col-sm-12 col-md-8">
                    <div style={{ marginTop: 10 + "px", marginLeft: 20 + "px", marginRight: 25 + "px" }}>
                        No data within selected time frame. The unit is either not running or not sending data. Check the Devices page to verify the status of the LifeWhere devices.
                        <br /><br />
                    </div>
                </div>
            </div>;

        let otaserver = <div className="row">
            <div className="col-sm-12 col-md-8">
                <div style={{ marginTop: 10 + "px", marginLeft: 20 + "px", marginRight: 25 + "px" }}>
                    <br /> <br />
                    Gateway version: {this.state.firmware.gatewayversion}
                    <br /> <br />
                    Last 24 hours Gateway has checked in with the OTA server :  {this.state.firmware.gatewayLoggedin}
                    <br /> <br />
                    Firmware version: {this.state.firmware.firmwareversion}
                    <br /> <br />
                    Last 24 hours the SH has checked in with the OTA server :  {this.state.firmware.serverLoggedin}
                    <br /> <br />
                    Firmware loaded {this.state.firmware.shtype} and associated class are {this.state.firmware.class}
                    <br /> <br />
                </div>
            </div>
        </div>;

        return (
            <Tabs>
                <TabList style={{ marginTop: 10 + "px", paddingLeft: 15 + "px" }}>
                    <Tab>Graph</Tab>
                    <Tab>OTA Server</Tab>
                </TabList>

                <TabPanel>
                    {devicePanel}
                </TabPanel>
                <TabPanel>
                    {otaserver}
                </TabPanel>
            </Tabs>
        );
    }
    renderDevices(deviceList) {
        const { classes } = this.props;
        let configLoader = this.state.configLoading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : <div>{null}</div>;

        let contents = <tbody>
            <tr>
                <td>No Devices</td>
            </tr>
        </tbody>;
        let contentforGatewayLogs = <tbody>
            <tr>
                <td>No Devices Logs</td>
            </tr>
        </tbody>;
        let contentforSH1Logs = <tbody>
            <tr>
                <td>No Devices Logs</td>
            </tr>
        </tbody>;

        let contentforSH2Logs = <tbody>
            <tr>
                <td>No Devices Logs</td>
            </tr>
        </tbody>;

        if (this.state.isSystemChanged) {
            contents = <tbody>
                <tr>
                    <td>loading devices...</td>
                </tr>
            </tbody>;
            contentforGatewayLogs = <tbody>
                <tr>
                    <td>loading devices Logs...</td>
                </tr>
            </tbody>;
        } else if (deviceList.length) {

            contents =
                <tbody>
                    {deviceList.map((device, index) =>
                        <ContextMenuTrigger
                            renderTag='tr' name={device.parentId}
                            id="devContextMenu" holdToDisplay={1000} data={index} key={device.deviceId}
                            onClick={() => {
                                this.onAssetClick(index)
                            }}
                            collect={collect}>

                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>{device.deviceType}</td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>{device.mac}</td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>{device.nickname}</td>
                            <td className={this.state.isSelected === index ? "tableSelected" : ""}>{this.getDeviceStatus(index)}</td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="right"
                                    overlay={
                                        <Tooltip
                                            id="tooltip-temperature">{this.getSigStampAlone(device.deviceTemperature_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipTemperature"><DevTemp
                                        devTemp={device.deviceTemperature} /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="right"
                                    overlay={
                                        <Tooltip
                                            id="tooltip-battery">{device.batteryVoltage + " " + this.getSigStamp(device.batteryVoltage_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipBattery"><BatterySymbol
                                        assetType={this.state.assetList.find(a => a.mac === device.deviceId).type.trim()}
                                        battVoltage={device.batteryVoltage} /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="right"
                                    overlay={
                                        <Tooltip
                                            id="tooltip-wifi">{device.wifiRSSI + " dBm " + this.getSigStamp(device.wifiRSSI_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipWifi"><SignalSymbol sigStrength={device.wifiRSSI}
                                        sigType="Wifi" /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="left"
                                    overlay={
                                        <Tooltip
                                            id="tooltip-sensor">{device.subgRSSI + " dBm " + this.getSigStamp(device.subgRSSI_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI}
                                        sigType="Subg" /></span>
                                </OverlayTrigger>
                            </td>
                            <td onClick={() => {
                                this.onAssetClick(index)
                            }} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                <OverlayTrigger key="bottom" placement="left"
                                    overlay={
                                        <Tooltip
                                            id="tooltip-current">{this.getSigStampAlone(device.current_Timestamp)}</Tooltip>
                                    }>
                                    <span href="#" id="TooltipCurrent">{device.current}</span>
                                </OverlayTrigger>
                            </td>

                        </ContextMenuTrigger>
                    )}
                </tbody>;
            if (this.state.paginglogListGateway?.length > 0) {
                contentforGatewayLogs = <tbody>
                    {this.state.paginglogListGateway?.map((request, index) =>
                        <tr className="alerts-table-row" key={`${request.deviceId}-tableRow-${index}`} data={index} value={index}>
                            <td style={{ borderLeft: '1px solid #ebeef0' }}>{request.id}</td>
                            <td>{request?.deviceId}</td>
                            <td>{request?.action}</td>
                            <td>{request?.hardwareRevision}</td>
                            <td>{request?.firmwareRevision}</td>
                            <td>{request?.certificateId}</td>
                            <td>{this.getDateString(request.date)}</td>
                        </tr>
                    )}
                </tbody>;
            }

            if (this.state.paginglogListSH1?.length > 0) {
                contentforSH1Logs = <tbody>
                    {this.state.paginglogListSH1?.map((request, index) =>
                        <tr className="alerts-table-row" key={`${request.deviceId}-tableRow-${index}`} data={index} value={index}>
                            <td style={{ borderLeft: '1px solid #ebeef0' }}>{request.id}</td>
                            <td>{request?.deviceId}</td>
                            <td>{request?.action}</td>
                            <td>{request?.hardwareRevision}</td>
                            <td>{request?.firmwareRevision}</td>
                            <td>{request?.certificateId}</td>
                            <td>{this.getDateString(request.date)}</td>
                        </tr>
                    )}
                </tbody>;
            }

            if (this.state.paginglogListSH2?.length > 0) {
                contentforSH2Logs = <tbody>
                    {this.state.paginglogListSH2?.map((request, index) =>
                        <tr className="alerts-table-row" key={`${request.deviceId}-tableRow-${index}`} data={index} value={index}>
                            <td style={{ borderLeft: '1px solid #ebeef0' }}>{request.id}</td>
                            <td>{request?.deviceId}</td>
                            <td>{request?.action}</td>
                            <td>{request?.hardwareRevision}</td>
                            <td>{request?.firmwareRevision}</td>
                            <td>{request?.certificateId}</td>
                            <td>{this.getDateString(request.date)}</td>
                        </tr>
                    )}
                </tbody>;
            }
        }
        let sensor3 = this.state.isFlyCatcher && this.state.currentAsset.type == "AirConditioner" && this.state.currentDevice !== undefined ?
            <div>
                <div className="txt-wide">
                    <label htmlFor="description">Sensor 3</label>

                    <label className="form-control" id="sensor3">
                        {this.state.currentDevice.sensor3}
                    </label>

                </div>
                <div className="txt-wide">
                    <label htmlFor="description">Mapped to</label>

                    <label className="form-control-text-runoff" id="mapsTo2">
                        {this.cleanMapping(this.state.currentDevice.mapsTo3)}
                    </label>

                </div>
            </div>
            : null;
        let paginationforGateway =
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={this.state.pageCountGateway} page={this.state.currentPageGateway} onChange={e => this.onChangePage(e, 0)} showFirstButton={this.state.pageCountGateway > 7 ? true : false} showLastButton={this.state.pageCountGateway > 7 ? true : false} size="small" />
            </div>;
        let paginationforSH1 =
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={this.state.pageCountSH1} page={this.state.currentPageSH1} onChange={e => this.onChangePage(e, 1)} showFirstButton={this.state.pageCountSH1 > 7 ? true : false} showLastButton={this.state.pageCountSH1 > 7 ? true : false} size="small" />
            </div>;
        let paginationforSH2 =
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={this.state.pageCountSH2} page={this.state.currentPageSH2} onChange={e => this.onChangePage(e, 2)} showFirstButton={this.state.pageCountSH2 > 7 ? true : false} showLastButton={this.state.pageCountSH2 > 7 ? true : false} size="small" />
            </div>;
        return (
            <div>
                <br />
                <div className="table-container-2">
                    <table className='table table-striped table-bordered table-sm table-hover'
                        aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Mac</th>
                                <th>Nickname</th>
                                <th>Status <RefreshIcon onClick={this.refreshAllStatus} /></th>
                                <th>Temp</th>
                                <th>Battery</th>
                                <th>WiFi</th>
                                <th>SensorHub</th>
                                <th>Current</th>
                            </tr>
                        </thead>
                        {contents}
                    </table>

                    <ContextMenu id="devContextMenu">
                        <MenuItem data={{ action: 'clicked' }} onClick={this.onContextClick}>
                            View Device Properties
                        </MenuItem>
                    </ContextMenu>
                </div>

                <div className="white-container">
                    <h5 className="sectionTitle">Asset Information</h5>
                    <form style={{ marginLeft: 15 + 'px' }}>
                        <div className="row">
                            <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Firmware</label>
                                    <label className="form-control" id="firmware">
                                        {this.state.currentDevice !== undefined ? this.state.currentDevice.sensorFw : ''}
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Dropouts</label>

                                    <label className="form-control" id="dropouts">
                                        {this.state.currentDevice !== undefined ? this.state.currentDevice.dropouts : ''}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Created</label>

                                    <label className="form-control" id="created">
                                        {this.state.currentDevice !== undefined ? this.getDate(this.state.currentDevice.createdOn) : ''}
                                    </label>

                                </div>
                            </div>
                            <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Last Update</label>

                                    <label className="form-control" id="last-update">
                                        {this.state.currentDevice !== undefined ? this.getDate(this.state.currentDevice.lastUpdated) : ''}
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Sensor 1</label>

                                    <label className="form-control" id="sensor1">
                                        {this.state.currentDevice !== undefined ? this.state.currentDevice.sensor1 : ''}
                                    </label>
                                </div>
                                <div className="txt-wide">
                                    <label htmlFor="description">Mapped to</label>

                                    <label className="form-control-text-runoff" id="mapsTo1">
                                        {this.state.currentDevice !== undefined ? this.cleanMapping(this.state.currentDevice.mapsTo1) : ''}
                                    </label>

                                </div>
                                <div className="txt-wide">
                                    <label htmlFor="description">Sensor 2</label>

                                    <label className="form-control" id="sensor2">
                                        {this.state.currentDevice !== undefined ? this.state.currentDevice.sensor2 : ''}
                                    </label>

                                </div>
                                <div className="txt-wide">
                                    <label htmlFor="description">Mapped to</label>

                                    <label className="form-control-text-runoff" id="mapsTo2">
                                        {this.state.currentDevice !== undefined ? this.cleanMapping(this.state.currentDevice.mapsTo2) : ''}
                                    </label>

                                </div>
                                {sensor3}
                            </div>
                            <div className="col">
                                <div className="txt-wide">
                                    <label htmlFor="description">Current Config</label>

                                    <label className="form-control" id="current-config"
                                        style={{ height: 272 + "px", overflow: "scroll" }}>
                                        <pre>{this.state.deviceConfiguration}</pre>
                                    </label>
                                </div>
                            </div>
                            {configLoader}
                        </div>


                        <p className="status-msg">{this.state.statusMessage}</p>
                        {(!this.state.isCustomerSupport) && (
                            <button
                                type="button"
                                className="secondary-btn btn-small lastItem"
                                onClick={this.openRepairSensorMapModal}
                                disabled={this.getRepairButtonDisabled()}
                            >
                                Repair Sensor Map
                            </button>)}

                    </form>
                </div>
                <br />
                {this.renderGraph()}
                {this.state.showDeviceLogs &&
                    <Tabs>
                        <TabList style={{ marginTop: 10 + "px", paddingLeft: 15 + "px" }}>
                            <Tab>Gateway</Tab>
                            <Tab>SH-1</Tab>
                            <Tab>SH-2</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="table-container-2">
                                {/* <h5 className="sectionTitle" >Device Logs<RefreshIcon onClick={this.refreshorgetDevicelogs} /></h5> */}
                                <table className='table table-striped table-bordered table-sm table-hover'
                                    aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th> Id</th>
                                            <th>Device Id</th>
                                            <th>Action</th>
                                            <th>Hardware Revision</th>
                                            <th>Firmware Revision</th>
                                            <th>CertificateId</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    {contentforGatewayLogs}
                                </table>
                                {paginationforGateway}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="table-container-2">
                                {/* <h5 className="sectionTitle" >Device Logs<RefreshIcon onClick={this.refreshorgetDevicelogs} /></h5> */}
                                <table className='table table-striped table-bordered table-sm table-hover'
                                    aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th> Id</th>
                                            <th>Device Id</th>
                                            <th>Action</th>
                                            <th>Hardware Revision</th>
                                            <th>Firmware Revision</th>
                                            <th>CertificateId</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    {contentforSH1Logs}
                                </table>
                                {paginationforSH1}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="table-container-2">
                                {/* <h5 className="sectionTitle" >Device Logs<RefreshIcon onClick={this.refreshorgetDevicelogs} /></h5> */}
                                <table className='table table-striped table-bordered table-sm table-hover'
                                    aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th> Id</th>
                                            <th>Device Id</th>
                                            <th>Action</th>
                                            <th>Hardware Revision</th>
                                            <th>Firmware Revision</th>
                                            <th>CertificateId</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    {contentforSH2Logs}
                                </table>
                                {paginationforSH2}
                            </div>
                        </TabPanel>
                    </Tabs>}

                <br />
                <SensorMapModal show={this.state.showRepairSensorMap} loading={this.state.sensorsLoading}
                    sensorMap={this.state.currentDevice !== undefined ? this.state.currentDevice.sensorMap : []} sensorList={this.state.sensorList}
                    unmappedProperties={this.state.unmappedProperties} mapDict={this.state.mapDict} mapSensor={this.mapSensor}
                    updateSensorMap={this.updateSensorMap} onClick={this.showRepairSensorMapModal}
                    currentSensor={this.state.currentSensor} mappedSensors={this.state.mappedSensors} />
                <ConfirmationModal show={this.state.showTTConfirm} onYes={this.onTTConfirm}
                    onClick={this.showTTConfirmModal} message={this.state.modalMessage}
                    title={this.state.modalTitle}> </ConfirmationModal>
            </div>);
    }

    render() {
        const { classes } = this.props;
        if (this.props.currentCustomerId === -1) {
            return (
                <div>
                    <p className="status-msg">No customer device information to display</p>
                </div>
            );
        } else {
            let content = this.state.loading ?
                <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                    <div className="loading-wrapper">
                        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                    </div>
                </div> :
                this.renderDevices(this.state.deviceList);
            let technicianInfo = this.state.currentHVACSystem.hasOwnProperty('children') && this.state.currentHVACSystem.children.length ?
                <div className="white-container">
                    <div className="select-container-3">
                        <TechnicianView
                            macId={this.state.currentHVACSystem.children[0].name} setTechnicianInfo={this.setTechnicianInfo} />
                    </div>
                </div> : <div></div>
            let statusLoadingContent = (this.state.statusLoading && !this.state.configLoading) ?
                <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                    <div className="loading-wrapper">
                        <h3 className="status-msg"
                            style={{ textAlign: "center", color: "white" }}>{this.state.updateStatusProgress}</h3>
                        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                    </div>
                </div> : <div>{null}</div>;

            return (
                <div className={classes.root}>
                    <div className='infoPage'>
                        <h3 className="pageTitle" id="tabelLabel">Devices</h3>
                        <div style={{ display: 'none' }}>{this.props.currentCustomerId}</div>
                        <AssetHierarchyView accountId={this.props.currentCustomerId}
                            onHVACSystemSelect={this.onHVACSystemSelect} />
                        {technicianInfo}
                        {statusLoadingContent}
                        {content}

                    </div>

                </div>
            );
        }
    }
}

class SignalSymbol extends React.Component {
    render() {
        let sigStrength = 0;
        let sig = this.props.sigStrength;
        if (this.props.sigType === "Wifi") {
            if (sig === 0) sigStrength = 0;
            else if (sig > -55) sigStrength = 4;
            else if (sig > -75) sigStrength = 3;
            else if (sig > -85) sigStrength = 2;
            else if (sig > -90) sigStrength = 1;
            else sigStrength = 0;
        }
        else {
            if (sig === 0) sigStrength = 0;
            else if (sig > -85) sigStrength = 4;
            else if (sig > -90) sigStrength = 3;
            else if (sig > -100) sigStrength = 2;
            else if (sig > -103) sigStrength = 1;
            else sigStrength = 0;
        }

        if (sigStrength === 4) {
            return (
                <Image src={require('../../img/wifi-excellent.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='EXCELLENT' /> 
            );
        }
        else if (sigStrength === 3) {
            return (
                <Image src={require('../../img/wifi-great.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='GREAT' />
            );
        }
        else if (sigStrength === 2) {
            return (
                <Image src={require('../../img/wifi-okay.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='OKAY' />
            );
        }
        else if (sigStrength === 1) {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='WEAK' />
            );
        }
        else {
            return (
                <Image src={require('../../img/wifi-unusable.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                //<WifiIndicator strength='DISCONNECTED' />
            );
        }
    }
}

export default withStyles(styles)(Devices);
