/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";
import { Equipment } from "../componentObjects";

const controller = "/trainingSession";
const trainingSessionService = {
    getalertcountinfo: async (orgId, assetType = Equipment.AIR_CONDITIONER) => {
        const response = await http.get(`${controller}/getalertcountinfo?orgId=${orgId}&assetType=${assetType}`);
        return response.data;
    },
    gettrainingpublishinfo: async (assetId, assetType = Equipment.AIR_CONDITIONER) => {
        const response = await http.get(`${controller}/gettrainingpublishinfo?assetId=${assetId}&assetType=${assetType}`);
        return response.data;
    }
};
export default trainingSessionService;