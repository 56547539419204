/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/trainingVideos";
class TrainingVideosService {

    async getTrainingVideos(userId) {
        const response = await http.get(`${controller}/${userId}`);
        return response?.data;
    }

    async addWatchedVideo(userId, trainingVideoId) {
        const response = await http.post(`${controller}/addwatchedvideo`, {"UserId": userId, "TrainingVideoId": trainingVideoId});
        return response?.data;
    }
    
}

export default new TrainingVideosService();
