/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';

class EventsModal extends Component {

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };
    render() {
        
        if (!this.props.show) {
            return null;
        }
        else {
            let modalContent = <div></div>;
            if (this.props.analyticOutput !== null && this.props.analyticOutput !== undefined) {

                let tableContent = this.props.analyticOutput.Events !== null && this.props.analyticOutput.Events !== undefined && this.props.analyticOutput.Events.length ?
                    <tbody>
                    {this.props.analyticOutput.Events.map((event, index) =>
                        <tr className="alerts-table-row" key={`${event.StartCount}-${index}`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                            <td>{index}</td>
                            <td>{event.Value.toFixed(2)}</td>
                            <td>{event.StartCount}</td>
                            <td>{event.Duration}</td>
                        </tr>
                    )}
                    </tbody>
                    : <tbody>
                    <tr className="alerts-table-row" style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                        <td></td>
                        <td>No Events</td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>;

                let table = <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
                    <table className="table-hover" style={{width: '100%'}}>
                        <thead>
                        <tr className="alerts-table-row">
                            <th>EVENT ID</th>
                            <th>VALUE</th>
                            <th>START</th>
                            <th>DURATION</th>
                        </tr>
                        </thead>
                        {tableContent}
                    </table></div>
                    ;

                    let unitOffContent = this.props.analyticOutput.UnitOffCandidates !== null && this.props.analyticOutput.UnitOffCandidates !== undefined && Object.values(this.props.analyticOutput.UnitOffCandidates).length ?
                    <tbody>
                    {Object.values(this.props.analyticOutput.UnitOffCandidates).map((item, index) =>
                        <tr className="alerts-table-row" key={`${item.Index}-${index}`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                            <td>{item.Index}</td>
                            <td>{item.Type}</td>
                            <td>{item.Duration}</td>
                        </tr>
                    )}
                    </tbody>
                    : <tbody>
                    <tr className="alerts-table-row" style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                        <td></td>
                        <td>No Unit Off Candidates</td>
                        <td></td>
                    </tr>
                    </tbody>;

                let unitOffTable = <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
                    <table className="table-hover" style={{width: '100%'}}>
                        <thead>
                        <tr className="alerts-table-row">
                            <th>INDEX</th>
                            <th>TYPE</th>
                            <th>DURATION</th>
                        </tr>
                        </thead>
                        {unitOffContent}
                    </table></div>
                    ;

                    let shutdownContent = this.props.analyticOutput.ShutdownCandidates !== null && this.props.analyticOutput.ShutdownCandidates !== undefined && Object.values(this.props.analyticOutput.ShutdownCandidates).length ?
                    <tbody>
                    {Object.values(this.props.analyticOutput.ShutdownCandidates).map((item, index) =>
                        <tr className="alerts-table-row" key={`${item.score}-${index}`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                            <td>{Object.keys(this.props.analyticOutput.ShutdownCandidates)[index]}</td>
                            <td>{item.indexOfMaxInRangeForGasValve}</td>
                            <td>{item.gasValveOffAmps.toFixed(3)}</td>
                            <td>{item.score}</td>
                            <td>{item.timeToEnd}</td>
                            <td>{item.pressureSwitchFailure ? 'true' : 'false'}</td>
                        </tr>
                    )}
                    </tbody>
                    : <tbody>
                    <tr className="alerts-table-row" style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                        <td></td>
                        <td></td>
                        <td>No Shutdown Candidates</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>;

                let shutdownTable = <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
                    <table className="table-hover" style={{ width: '100%' }}>
                    <thead>
                    <tr className="alerts-table-row">
                        <th>EVENT ID</th>
                        <th>GV@</th>
                        <th>AMPS</th>
                        <th>SCORE</th>
                        <th>TTE</th>
                        <th>FAILURE</th>
                    </tr>
                    </thead>
                    {shutdownContent}
                    </table></div>;

                let ignitionContent = this.props.analyticOutput.IgnitionCandidates !== null && this.props.analyticOutput.IgnitionCandidates !== undefined && Object.values(this.props.analyticOutput.IgnitionCandidates).length ?
                    <tbody>
                    {Object.values(this.props.analyticOutput.IgnitionCandidates).map((item, index) =>
                        <tr className="alerts-table-row" key={`${item.Index}-${index}-ignition`} data={index} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                            <td>{Object.keys(this.props.analyticOutput.IgnitionCandidates)[index]}</td>
                            <td>{item.split(":")[0]}</td>
                            <td>{item.split(":").length > 0 ? item.split(":")[1] : ''}</td>
                        </tr>
                    )}
                    </tbody>
                    : <tbody>
                    <tr className="alerts-table-row" style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>
                        <td></td>
                        <td>No Ignition Candidates</td>
                        <td></td>
                    </tr>
                    </tbody>;

                let ignitionTable = <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
                    <table className="table-hover" style={{width: '100%'}}>
                        <thead>
                        <tr className="alerts-table-row">
                            <th>INDEX</th>
                            <th>SCORE</th>
                            <th>DURATION</th>
                        </tr>
                        </thead>
                        {ignitionContent}
                    </table></div>
                    ;

                    modalContent = <div>
                    {table}
                    <h5 style={{paddingTop: '10px'}}>Unit Off Candidates</h5>
                    {unitOffTable}
                    <h5 style={{paddingTop: '10px'}}>Shutdown Candidates</h5>
                    {shutdownTable}
                    <h5 style={{paddingTop: '10px'}}>Ignition Candidates</h5>
                    {ignitionTable}
                </div>
                ;
            }
            return (
                <div className="modal" style={{display: this.props.show ? "block" : "none"}}>
                    <div className="modal-wrapper"
                         style={{
                             transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                             opacity: this.props.show ? '1' : '0'
                         }}>

                        <div className="modal-header">
                            <h3>Events</h3>
                            <span className="close-modal-btn" onClick={e => {
                                this.onClick(e);
                            }}>×</span>
                        </div>

                        <div className="modal-body" style={{padding: '20px 60px'}}>
                            {modalContent}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => {
                                this.onClick(e);
                            }}>Done
                            </button>

                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default EventsModal;