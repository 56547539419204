/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Event } from "../GoogleAnalytics";
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import { jsSource } from "../../componentObjects";
import 'react-tabs/style/react-tabs.css';
import "../../CSS/DataModal.css";
import moment from 'moment';
import AlertGraphsModal from './AlertGraphsModal';
import VisibilityIcon from '@material-ui/icons/Visibility';


class ViewGraphButton extends Component {

    constructor(props) {
        super(props);

        this.getHighchartsData = this.getHighchartsData.bind(this);
        this.openGraphModal = this.openGraphModal.bind(this);
        this.clearGraphData = this.clearGraphData.bind(this);
        this.handleMultiCT = this.handleMultiCT.bind(this);

        this.state = {
            acGraph: false,
            thermGraph: false,
            acSelected: false,
            thermostatSelected: false,
            currentAssetType: "Furnace",
            canLeftClick: false,
            canRightClick: false,
            currentGraph: {
                run: {},
                daily: {}
            },
            currentMax: 0,
            buttonContent: "",
            buttonFormat: "",
            currentSource: jsSource,
            graphLoading: false,
            graphLoaded: false,
            hasAnalytics: false,
            multiDataList: [],
            multiseriesGraph: {},
            runDataList: [],
            selectedRun: 0,
            showZoomButtom: false,
            showModals: {},
            timelineDataList: [],
            extendedDataList: [],
            visibility: {
                analytics: false,
                furnace: true,
                temp: true,
                rla: true,
                lra: true,
                blowerCurrent: false,
                inducerCurrent: false,
                coolSetpoint: false,
                heatSetpoint: false
            },
            property_dictionary: { "75": [766057, 754696], "77": [754702, 768809], "76": [754686, 779446, 779443, 782721, 782722] }
        }
    }

    clearGraphData() {
        this.props.showGraphModal(this.props.buttonId);
        this.setState({
            acSelected: false,
            thermostatSelected: false,
            currentGraph: {
                run: {},
                daily: {}
            },
            currentSource: jsSource,
            graphLoaded: false,
            multiDataList: [],
            multiseriesGraph: {},
            runDataList: [],
            timelineDataList: [],
            extendedDataList: []
        });
    }

    handleMultiCT(ctName, checked) {
        this.setState(prevState => ({
            visibility: {
                ...prevState.visibility,
                [ctName]: checked
            }
        }));
    }

    async getHighchartsData() {
        let graph = this.state.currentGraph;
        let multiGraph = this.state.multiseriesGraph; 

        let runDataList = [];
        let timelineDataList = [];
        let extendedDataList = [];
        let multiDataList = [];
        let currentMax = 0;
        let rlaVal = 0;

        if (graph !== null && graph !== undefined) {

            if (this.state.acSelected) {
                if (graph.highchartsRun !== null && graph.highchartsRun !== undefined && graph.highchartsRun.data !== null && graph.highchartsRun.data !== undefined && graph.highchartsRun.data.length) {
                    let currentHCSource = graph.highchartsRun;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
                    let lastMoment = moment(currentHCSource.data[currentHCSource.data.length -1][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
                    var dtLast = new Date(lastMoment);
                    var pointLastMoment = Date.UTC(dtLast.getFullYear(), dtLast.getMonth(), dtLast.getDate(), dtLast.getHours(), dtLast.getMinutes(), dtLast.getSeconds(), dtLast.getMilliseconds());
                    const runMax = dataArr.reduce(function (prev, current) {
                        return (prev && prev > current) ? prev.value : current.value
                    }); //returns value
                    if (runMax > currentMax)
                        currentMax = runMax;

                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    runDataList = runDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        runDataList = runDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if (currentHCSource.indoorTempData !== null) {
                            let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                            let indoorData = {
                                name: 'Indoor Hub Temp',
                                data: indoorArr,
                                color: '#d399e0',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            runDataList = runDataList.concat(indoorData);
                        }

                        if (currentHCSource.outdoorTempData !== null) {
                            let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                            let outdoorData = {
                                name: 'Outdoor Hub Temp',
                                data: outdoorArr,
                                color: '#7fc6f5',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            runDataList = runDataList.concat(outdoorData);
                        }
                    }
                    
                    if (this.state.visibility.rla) {
                        let rlaVal = this.state.currentGraph.rla;
                        let rlaArr = Array(currentHCSource.data.length - 1).fill(rlaVal);
                        let rlaLabel = {
                            y: rlaVal,
                            dataLabels: { enabled: true, format: 'RLA' }
                        };
                        rlaArr = rlaArr.concat(rlaLabel);
                        let rlaData = {
                            name: 'RLA',
                            data: rlaArr,
                            color: '#f0b169',
                            type: 'line',
                            zIndex: 2,
                            yAxis: 0,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            dashStyle: 'longDash'
                        };
                        runDataList = runDataList.concat(rlaData);
                    }

                    if (this.state.visibility.lra) {
                        let lraVal = this.state.currentGraph.lra;
                        let lraArr = Array(currentHCSource.data.length - 1).fill(lraVal);
                        let lraLabel = {
                            y: lraVal,
                            dataLabels: { enabled: true, format: 'LRA' }
                        };
                        lraArr = lraArr.concat(lraLabel);
                        let lraData = {
                            name: 'LRA',
                            data: lraArr,
                            color: 'red',
                            type: 'line',
                            zIndex: 2,
                            yAxis: 0,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            dashStyle: 'longDash'
                        };
                        runDataList = runDataList.concat(lraData);
                    }

                    let currentAnnotations = graph.timeSeriesDataSources !== null && graph.timeSeriesDataSources !== undefined && graph.timeSeriesDataSources.length ? graph.timeSeriesDataSources[0].graphAnnotations : [];
                    if (currentAnnotations !== undefined && currentAnnotations !== null && currentAnnotations.length) {
                        let blowerAnnotation = currentAnnotations.find(a => a.state === "Blower");
                        let compressorAnnotation = currentAnnotations.find(a => a.state === "Compressor");

                        if (blowerAnnotation !== undefined || compressorAnnotation !== undefined) {
                            // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local
                            if (blowerAnnotation !== undefined) {
                                let bandStart = moment(blowerAnnotation["startTime"]);
                                let bandEnd = moment(blowerAnnotation["endTime"]);
                                if (this.state.acSelected) {
                                    let start = moment(blowerAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let startDT = new Date(start);
                                    bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                    let end = moment(blowerAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let endDT = new Date(end);
                                    bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                }

                                let blowerData ={
                                    name: "Blower",
                                    type: 'polygon',
                                    color: blowerAnnotation["color"] + "45",
                                    zIndex: 1,
                                    data: [
                                        [bandStart, 0],
                                        [bandEnd, 0],
                                        [bandEnd, 1],
                                        [bandStart, 1]
                                    ]
                                };
                                runDataList = runDataList.concat(blowerData);
                            }

                            if (compressorAnnotation !== undefined) {
                                let bandStart = moment(compressorAnnotation["startTime"]);
                                let bandEnd = moment(compressorAnnotation["endTime"]);
                                if (this.state.acSelected) {
                                    let start = moment(compressorAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let startDT = new Date(start);
                                    bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                    let end = moment(compressorAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let endDT = new Date(end);
                                    bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                }

                                let compressorData ={
                                    name: "Compressor",
                                    type: 'polygon',
                                    color: compressorAnnotation["color"] + "45",
                                    zIndex: 1,
                                    data: [
                                        [bandStart, 1],
                                        [bandEnd, 1],
                                        [bandEnd, 2],
                                        [bandStart, 2]
                                    ]
                                };
                                runDataList = runDataList.concat(compressorData);
                            }
                        }
                    }
                }

                if (graph.highchartsDaily !== null && graph.highchartsDaily !== undefined && graph.highchartsDaily.data !== null && graph.highchartsDaily.data !== undefined && graph.highchartsDaily.data.length) {
                    let currentHCSource = graph.highchartsDaily;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
                    let lastMoment = moment(currentHCSource.data[currentHCSource.data.length -1][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
                    var dtLast = new Date(lastMoment);
                    var pointLastMoment = Date.UTC(dtLast.getFullYear(), dtLast.getMonth(), dtLast.getDate(), dtLast.getHours(), dtLast.getMinutes(), dtLast.getSeconds(), dtLast.getMilliseconds());
                    
                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    timelineDataList = timelineDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        timelineDataList = timelineDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if (currentHCSource.indoorTempData !== null) {
                            let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                            let indoorData = {
                                name: 'Indoor Hub Temp',
                                data: indoorArr,
                                color: '#d399e0',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            timelineDataList = timelineDataList.concat(indoorData);
                        }

                        if (currentHCSource.outdoorTempData !== null) {
                            let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                            let outdoorData = {
                                name: 'Outdoor Hub Temp',
                                data: outdoorArr,
                                color: '#7fc6f5',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            timelineDataList = timelineDataList.concat(outdoorData);
                        }
                    }

                    if (this.state.visibility.rla) {
                        let rlaVal = this.state.currentGraph.rla;
                        let rlaArr = Array(currentHCSource.data.length - 1).fill(rlaVal);
                        let labelObj = {
                            y: rlaVal,
                            dataLabels: { enabled: true, format: 'RLA', allowOverlap: true }
                        };
                        rlaArr = rlaArr.concat(labelObj);
                        let rlaData = {
                            name: 'RLA',
                            data: rlaArr,
                            color: '#f0b169',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0,
                            dashStyle: 'longDash'
                        };
                        timelineDataList = timelineDataList.concat(rlaData);
                    }

                    if (this.state.visibility.lra) {
                        let lraVal = this.state.currentGraph.lra;
                        let lraArr = Array(currentHCSource.data.length - 1).fill(lraVal);
                        let labelObj = {
                            y: lraVal,
                            dataLabels: { enabled: true, format: 'LRA', allowOverlap: true }
                        };
                        lraArr = lraArr.concat(labelObj);
                        let lraData = {
                            name: 'LRA',
                            data: lraArr,
                            color: 'red',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0,
                            dashStyle: 'longDash'
                        };
                        timelineDataList = timelineDataList.concat(lraData);
                    }

                    let currentAnnotations = graph.daily?.graphAnnotations;
                    if (currentAnnotations !== undefined && currentAnnotations !== null && currentAnnotations.length) {
                        let blowerAnnotations = currentAnnotations.filter(a => a.state === "Blower");
                        let compressorAnnotations = currentAnnotations.filter(a => a.state === "Compressor");

                        // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local (this note from assets page...)
                        if ((blowerAnnotations !== undefined && blowerAnnotations.length) || (compressorAnnotations !== undefined && compressorAnnotations.length)) {
                            if (blowerAnnotations !== undefined && blowerAnnotations.length) {
                                let blowerDataList = [];
                                for (let i = 0; i < blowerAnnotations.length; i++) {
                                    let blowerAnnotation = blowerAnnotations[i];
                                    if (blowerAnnotation !== undefined) {
                                        let bandStart = moment(blowerAnnotation["startTime"]);
                                        let bandEnd = moment(blowerAnnotation["endTime"]);
                                        if (this.state.acSelected) {
                                            let start = moment(blowerAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let startDT = new Date(start);
                                            bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                            let end = moment(blowerAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let endDT = new Date(end);
                                            bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                        }

                                        let blowData = [
                                            [bandStart, 0],
                                            [bandEnd, 0],
                                            [bandEnd, 1],
                                            [bandStart, 1],
                                            null //allows for second shape
                                        ];
                                        blowerDataList = blowerDataList.concat(blowData);
                                    }
                                }
                                let blowerData ={
                                    name: "Blower",
                                    type: 'polygon',
                                    color: blowerAnnotations[0]["color"] + "45",
                                    zIndex: 1,
                                    data: blowerDataList
                                };
                                timelineDataList = timelineDataList.concat(blowerData);
                            }

                            if (compressorAnnotations !== undefined && compressorAnnotations.length) {
                                let compressorDataList = [];
                                for (let i = 0; i < compressorAnnotations.length; i++) {
                                    let compressorAnnotation = compressorAnnotations[i];
                                    if (compressorAnnotation !== undefined) {
                                        let bandStart = moment(compressorAnnotation["startTime"]);
                                        let bandEnd = moment(compressorAnnotation["endTime"]);
                                        if (this.state.acSelected) {
                                            let start = moment(compressorAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let startDT = new Date(start);
                                            bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                            let end = moment(compressorAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let endDT = new Date(end);
                                            bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                        }
                                        let compData = [
                                            [bandStart, 1],
                                            [bandEnd, 1],
                                            [bandEnd, 2],
                                            [bandStart, 2],
                                            null
                                        ];
                                        compressorDataList = compressorDataList.concat(compData);
                                    }
                                }
                                let compressorData ={
                                    name: "Compressor",
                                    type: 'polygon',
                                    color: compressorAnnotations[0]["color"] + "45",
                                    zIndex: 1,
                                    data: compressorDataList
                                };
                                timelineDataList = timelineDataList.concat(compressorData);
                            }
                        }
                    }
                }

                if (graph.highchartsExtended !== null && graph.highchartsExtended !== undefined && graph.highchartsExtended.data !== null && graph.highchartsExtended.data !== undefined && graph.highchartsExtended.data.length) {
                    let currentHCSource = graph.highchartsExtended;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
                    let lastMoment = moment(currentHCSource.data[currentHCSource.data.length -1][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
                    var dtLast = new Date(lastMoment);
                    var pointLastMoment = Date.UTC(dtLast.getFullYear(), dtLast.getMonth(), dtLast.getDate(), dtLast.getHours(), dtLast.getMinutes(), dtLast.getSeconds(), dtLast.getMilliseconds());

                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    extendedDataList = extendedDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        extendedDataList = extendedDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if (currentHCSource.indoorTempData !== null) {
                            let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                            let indoorData = {
                                name: 'Indoor Hub Temp',
                                data: indoorArr,
                                color: '#d399e0',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            extendedDataList = extendedDataList.concat(indoorData);
                        }

                        if (currentHCSource.outdoorTempData !== null) {
                            let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                            let outdoorData = {
                                name: 'Outdoor Hub Temp',
                                data: outdoorArr,
                                color: '#7fc6f5',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            extendedDataList = extendedDataList.concat(outdoorData);
                        }
                    }

                    if (this.state.visibility.rla) {
                        let rlaVal = this.state.currentGraph.rla;
                        let rlaArr = Array(currentHCSource.data.length - 1).fill(rlaVal);
                        let labelObj = {
                            y: rlaVal,
                            dataLabels: { enabled: true, format: 'RLA', allowOverlap: true }
                        };
                        rlaArr = rlaArr.concat(labelObj);
                        let rlaData = {
                            name: 'RLA',
                            data: rlaArr,
                            color: '#f0b169',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0,
                            dashStyle: 'longDash'
                        };
                        extendedDataList = extendedDataList.concat(rlaData);
                    }

                    if (this.state.visibility.lra) {
                        let lraVal = this.state.currentGraph.lra;
                        let lraArr = Array(currentHCSource.data.length - 1).fill(lraVal);
                        let labelObj = {
                            y: lraVal,
                            dataLabels: { enabled: true, format: 'LRA', allowOverlap: true }
                        };
                        lraArr = lraArr.concat(labelObj);
                        let lraData = {
                            name: 'LRA',
                            data: lraArr,
                            color: 'red',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0,
                            dashStyle: 'longDash'
                        };
                        extendedDataList = extendedDataList.concat(lraData);
                    }

                    let currentAnnotations = graph.extended?.graphAnnotations;
                    if (currentAnnotations !== undefined && currentAnnotations !== null && currentAnnotations.length) {
                        let blowerAnnotations = currentAnnotations.filter(a => a.state === "Blower");
                        let compressorAnnotations = currentAnnotations.filter(a => a.state === "Compressor");

                        // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local (this note from assets page...)
                        if ((blowerAnnotations !== undefined && blowerAnnotations.length) || (compressorAnnotations !== undefined && compressorAnnotations.length)) {
                            if (blowerAnnotations !== undefined && blowerAnnotations.length) {
                                let blowerDataList = [];
                                for (let i = 0; i < blowerAnnotations.length; i++) {
                                    let blowerAnnotation = blowerAnnotations[i];
                                    if (blowerAnnotation !== undefined) {
                                        let bandStart = moment(blowerAnnotation["startTime"]);
                                        let bandEnd = moment(blowerAnnotation["endTime"]);
                                        if (this.state.acSelected) {
                                            let start = moment(blowerAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let startDT = new Date(start);
                                            bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                            let end = moment(blowerAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let endDT = new Date(end);
                                            bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                        }

                                        let blowData = [
                                            [bandStart, 0],
                                            [bandEnd, 0],
                                            [bandEnd, 1],
                                            [bandStart, 1],
                                            null //allows for second shape
                                        ];
                                        blowerDataList = blowerDataList.concat(blowData);
                                    }
                                }
                                let blowerData ={
                                    name: "Blower",
                                    type: 'polygon',
                                    color: blowerAnnotations[0]["color"] + "45",
                                    zIndex: 1,
                                    data: blowerDataList
                                };
                                extendedDataList = extendedDataList.concat(blowerData);
                            }

                            if (compressorAnnotations !== undefined && compressorAnnotations.length) {
                                let compressorDataList = [];
                                for (let i = 0; i < compressorAnnotations.length; i++) {
                                    let compressorAnnotation = compressorAnnotations[i];
                                    if (compressorAnnotation !== undefined) {
                                        let bandStart = moment(compressorAnnotation["startTime"]);
                                        let bandEnd = moment(compressorAnnotation["endTime"]);
                                        if (this.state.acSelected) {
                                            let start = moment(compressorAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let startDT = new Date(start);
                                            bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                            let end = moment(compressorAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let endDT = new Date(end);
                                            bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                        }
                                        let compData = [
                                            [bandStart, 1],
                                            [bandEnd, 1],
                                            [bandEnd, 2],
                                            [bandStart, 2],
                                            null
                                        ];
                                        compressorDataList = compressorDataList.concat(compData);
                                    }
                                }
                                let compressorData ={
                                    name: "Compressor",
                                    type: 'polygon',
                                    color: compressorAnnotations[0]["color"] + "45",
                                    zIndex: 1,
                                    data: compressorDataList
                                };
                                extendedDataList = extendedDataList.concat(compressorData);
                            }
                        }
                    }
                }
            }
            else {
                if (graph.run !== null && graph.run !== undefined && graph.run.series !== null && graph.run.series !== undefined && graph.run.series.length) {
                    let graphConstants = graph.constantValues;
                    if (this.props.currentAlert.assetClassNames.some(a => a === "AirHandler")) { 
                        if (graphConstants !== null && graphConstants !== undefined && graph.run.series[0].data !== null && graph.run.series[0].data !== undefined && graph.run.series[0].data.length) {
                            let dataCount = graph.run.series[0].data.length;
                            let dailyCount = graph.daily.series[0].data.length;
                            let extendedCount = graph.extended.series[0].data.length;
                            let pointStart = moment(graph.run.series[0].data[0].timestamp);
                            let dailyStart = moment(graph.daily?.series[0].data[0].timestamp);
                            let extendedStart = moment(graph.extended?.series[0].data[0].timestamp);
                            if (graphConstants["blowerLowStageAvg"] !== null && graphConstants["blowerLowStageAvg"] !== undefined && graphConstants["blowerLowStageAvg"] !== 0) {
                               
                                let blowerLowAvg = graphConstants["blowerLowStageAvg"];
                                if (graphConstants["blowerLowStdDev"] !== null && graphConstants["blowerLowStdDev"] !== undefined && graphConstants["blowerLowStdDev"] !== 0) {
                                    let blowerLowStdDev = graphConstants["blowerLowStdDev"];
                                    let blowerLowMin = blowerLowAvg - blowerLowStdDev;
                                    let blowerLowMax = blowerLowAvg + blowerLowStdDev;
                                    let minArr = Array(dataCount - 1).fill(blowerLowMin);
                                    let minLabel = {
                                        y: blowerLowMin,
                                        dataLabels: { enabled: true, format: 'Expected Low Stage Min' }
                                    };
                                    minArr = minArr.concat(minLabel);
                                    let maxArr = Array(dataCount - 1).fill(blowerLowMax);
                                    let maxLabel = {
                                        y: blowerLowMax,
                                        dataLabels: { enabled: true, format: 'Expected Low Stage Max' }
                                    };
                                    maxArr = maxArr.concat(maxLabel);
                                    let lowStageMinData = {
                                        name: 'Expected Low Stage Min',
                                        data: minArr,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: pointStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    runDataList = runDataList.concat(lowStageMinData);

                                    let lowStageMaxData = {
                                        name: 'Expected Low Stage Max',
                                        data: maxArr,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: pointStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    runDataList = runDataList.concat(lowStageMaxData);

                                    let minArr2 = Array(dailyCount - 1).fill(blowerLowMin);
                                    minArr2 = minArr2.concat(minLabel);
                                    let maxArr2 = Array(dailyCount - 1).fill(blowerLowMax);
                                    maxArr2 = maxArr2.concat(maxLabel);
                                    let lowStageMinData2 = {
                                        name: 'Expected Low Stage Min',
                                        data: minArr2,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: dailyStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    timelineDataList = timelineDataList.concat(lowStageMinData2);

                                    let lowStageMaxData2 = {
                                        name: 'Expected Low Stage Max',
                                        data: maxArr2,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: dailyStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    timelineDataList = timelineDataList.concat(lowStageMaxData2);

                                    let minArr3 = Array(extendedCount - 1).fill(blowerLowMin);
                                    minArr3 = minArr3.concat(minLabel);
                                    let maxArr3 = Array(extendedCount - 1).fill(blowerLowMax);
                                    maxArr3 = maxArr3.concat(maxLabel);
                                    let lowStageMinData3 = {
                                        name: 'Expected Low Stage Min',
                                        data: minArr3,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: extendedStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    extendedDataList = extendedDataList.concat(lowStageMinData3);

                                    let lowStageMaxData3 = {
                                        name: 'Expected Low Stage Max',
                                        data: maxArr3,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: extendedStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    extendedDataList = extendedDataList.concat(lowStageMaxData3);
                                }
                                else {
                                    let lowAvgArr = Array(dataCount - 1).fill(blowerLowAvg);
                                    let lowAvgLabel = {
                                        y: blowerLowAvg,
                                        dataLabels: { enabled: true, format: 'Expected Low Stage Avg'}
                                    };
                                    lowAvgArr = lowAvgArr.concat(lowAvgLabel);
                                    let lowStageData = {
                                        name: 'Expected Low Stage Avg',
                                        data: lowAvgArr,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: pointStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    runDataList = runDataList.concat(lowStageData);

                                    let lowAvgArr2 = Array(dailyCount - 1).fill(blowerLowAvg);
                                    lowAvgArr2 = lowAvgArr2.concat(lowAvgLabel);
                                    let lowStageData2 = {
                                        name: 'Expected Low Stage Avg',
                                        data: lowAvgArr2,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: dailyStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    timelineDataList = timelineDataList.concat(lowStageData2);

                                    let lowAvgArr3 = Array(extendedCount - 1).fill(blowerLowAvg);
                                    lowAvgArr3 = lowAvgArr3.concat(lowAvgLabel);
                                    let lowStageData3 = {
                                        name: 'Expected Low Stage Avg',
                                        data: lowAvgArr3,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: extendedStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    extendedDataList = extendedDataList.concat(lowStageData3);
                                }
                            }

                            if (graphConstants["blowerHighStageAvg"] !== null && graphConstants["blowerHighStageAvg"] !== undefined && graphConstants["blowerHighStageAvg"] !== 0) {
                                let blowerHighAvg = graphConstants["blowerHighStageAvg"];
                                if (graphConstants["blowerHighStdDev"] !== null && graphConstants["blowerHighStdDev"] !== undefined && graphConstants["blowerHighStdDev"] !== 0) {
                                    let blowerHighStdDev = graphConstants["blowerHighStdDev"];
                                    let blowerHighMin = blowerHighAvg - blowerHighStdDev;
                                    let blowerHighMax = blowerHighAvg + blowerHighStdDev;
                                    let minArr = Array(dataCount - 1).fill(blowerHighMin);
                                    let minLabel = {
                                        y: blowerHighMin,
                                        dataLabels: { enabled: true, format: 'Expected High Stage Min' }
                                    };
                                    minArr = minArr.concat(minLabel);
                                    let maxArr = Array(dataCount - 1).fill(blowerHighMax);
                                    let maxLabel = {
                                        y: blowerHighMax,
                                        dataLabels: { enabled: true, format: 'Expected High Stage Max' }
                                    };
                                    maxArr = maxArr.concat(maxLabel);
                                    let highStageMinData = {
                                        name: 'Expected High Stage Min',
                                        data: minArr,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: pointStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    runDataList = runDataList.concat(highStageMinData);

                                    let highStageMaxData = {
                                        name: 'Expected High Stage Max',
                                        data: maxArr,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: pointStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    runDataList = runDataList.concat(highStageMaxData);

                                    let minArr2 = Array(dailyCount - 1).fill(blowerHighMin);
                                    minArr2 = minArr2.concat(minLabel);
                                    let maxArr2 = Array(dailyCount - 1).fill(blowerHighMax);
                                    maxArr2 = maxArr2.concat(maxLabel);

                                    let highStageMinData2 = {
                                        name: 'Expected High Stage Min',
                                        data: minArr2,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: dailyStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    timelineDataList = timelineDataList.concat(highStageMinData2);

                                    let highStageMaxData2 = {
                                        name: 'Expected High Stage Max',
                                        data: maxArr2,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: dailyStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    timelineDataList = timelineDataList.concat(highStageMaxData2);

                                    let minArr3 = Array(extendedCount - 1).fill(blowerHighMin);
                                    minArr3 = minArr3.concat(minLabel);
                                    let maxArr3 = Array(extendedCount - 1).fill(blowerHighMax);
                                    maxArr3 = maxArr3.concat(maxLabel);

                                    let highStageMinData3 = {
                                        name: 'Expected High Stage Min',
                                        data: minArr3,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: extendedStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    extendedDataList = extendedDataList.concat(highStageMinData3);

                                    let highStageMaxData3 = {
                                        name: 'Expected High Stage Max',
                                        data: maxArr3,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: extendedStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    extendedDataList = extendedDataList.concat(highStageMaxData3);
                                } else {
                                    let highAvgArr = Array(dataCount- 1).fill(blowerHighAvg);
                                    let highAvgLabel = {
                                        y: blowerHighAvg,
                                        dataLabels: { enabled: true, format: 'Expected High Stage Avg'}
                                    };
                                    highAvgArr = highAvgArr.concat(highAvgLabel);
                                    let highStageData = {
                                        name: 'Expected High Stage Avg',
                                        data: highAvgArr,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: pointStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    runDataList = runDataList.concat(highStageData);

                                    let highAvgArr2 = Array(dailyCount - 1).fill(blowerHighAvg);
                                    highAvgArr2 = highAvgArr2.concat(highAvgLabel);
                                    let highStageData2 = {
                                        name: 'Expected High Stage Avg',
                                        data: highAvgArr2,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: dailyStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    timelineDataList = timelineDataList.concat(highStageData2);

                                    let highAvgArr3 = Array(extendedCount - 1).fill(blowerHighAvg);
                                    highAvgArr3 = highAvgArr3.concat(highAvgLabel);
                                    let highStageData3 = {
                                        name: 'Expected High Stage Avg',
                                        data: highAvgArr3,
                                        color: 'red',
                                        type: 'line',
                                        zIndex: 2,
                                        pointStart: extendedStart,
                                        pointInterval: 1000,
                                        yAxis: 0,
                                        dashStyle: 'longDash',
                                        visible: true
                                    };
                                    extendedDataList = extendedDataList.concat(highStageData3);
                                }
                            }

                            if (graphConstants["blowerLowAverage"] !== null && graphConstants["blowerLowAverage"] !== undefined && graphConstants["blowerLowAverage"] !== 0) {
                                let blowerLowAvg = graphConstants["blowerLowAverage"];
                                let lowAvgArr = Array(dataCount - 1).fill(blowerLowAvg);
                                let lowAvgLabel = {
                                    y: blowerLowAvg,
                                    dataLabels: { enabled: true, format: 'Blower Low Avg' }
                                };
                                lowAvgArr = lowAvgArr.concat(lowAvgLabel);
                                let lowStageData = {
                                    name: 'Blower Low Average',
                                    data: lowAvgArr,
                                    color: 'teal',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: pointStart,
                                    pointInterval: 1000,
                                    yAxis: 0,
                                    visible: true
                                };
                                runDataList = runDataList.concat(lowStageData);

                                let lowAvgArr2 = Array(dailyCount - 1).fill(blowerLowAvg);
                                lowAvgArr2 = lowAvgArr2.concat(lowAvgLabel);
                                let lowStageData2 = {
                                    name: 'Blower Low Average',
                                    data: lowAvgArr2,
                                    color: 'teal',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: dailyStart,
                                    pointInterval: 1000,
                                    yAxis: 0,
                                    visible: true
                                };
                                timelineDataList = timelineDataList.concat(lowStageData2);

                                let lowAvgArr3 = Array(extendedCount - 1).fill(blowerLowAvg);
                                lowAvgArr3 = lowAvgArr3.concat(lowAvgLabel);
                                let lowStageData3 = {
                                    name: 'Blower Low Average',
                                    data: lowAvgArr3,
                                    color: 'teal',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: extendedStart,
                                    pointInterval: 1000,
                                    yAxis: 0,
                                    visible: true
                                };
                                extendedDataList = extendedDataList.concat(lowStageData3);
                            }

                            if (graphConstants["blowerHighAverage"] !== null && graphConstants["blowerHighAverage"] !== undefined && graphConstants["blowerHighAverage"] !== 0) {
                                let blowerHighAvg = graphConstants["blowerHighAverage"];
                                let highAvgArr = Array(dataCount - 1).fill(blowerHighAvg);
                                let highAvgLabel = {
                                    y: blowerHighAvg,
                                    dataLabels: { enabled: true, format: 'Blower High Avg' }
                                };
                                highAvgArr = highAvgArr.concat(highAvgLabel);
                                let highStageData = {
                                    name: 'Blower High Average',
                                    data: highAvgArr,
                                    color: 'teal',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: pointStart,
                                    pointInterval: 1000,
                                    yAxis: 0,
                                    visible: true
                                };
                                runDataList = runDataList.concat(highStageData);

                                let highAvgArr2 = Array(dailyCount - 1).fill(blowerHighAvg);
                                highAvgArr2 = highAvgArr2.concat(highAvgLabel);
                                let highStageData2 = {
                                    name: 'Blower High Average',
                                    data: highAvgArr2,
                                    color: 'teal',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: dailyStart,
                                    pointInterval: 1000,
                                    yAxis: 0,
                                    visible: true
                                };
                                timelineDataList = timelineDataList.concat(highStageData2);

                                let highAvgArr3 = Array(extendedCount - 1).fill(blowerHighAvg);
                                highAvgArr3 = highAvgArr3.concat(highAvgLabel);
                                let highStageData3 = {
                                    name: 'Blower High Average',
                                    data: highAvgArr3,
                                    color: 'teal',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: extendedStart,
                                    pointInterval: 1000,
                                    yAxis: 0,
                                    visible: true
                                };
                                extendedDataList = extendedDataList.concat(highStageData3);
                            }
                        }
                    }
                    
                    
                    for (let i = 0; i < graph.run.series.length; i++) {
                        let series = graph.run.series[i];
                        if (series.data.length) {
                            let dataArr = series.data.map((item) => (item.value));
                            let pointStart = moment(series.data[0].timestamp);
                            let data = {
                                name: series.name,
                                data: dataArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            runDataList = runDataList.concat(data);
                        }
                    }
                }

                if (graph.daily !== null && graph.daily !== undefined && graph.daily.series !== null && graph.daily.series !== undefined && graph.daily.series.length) {
                    for (let j = 0; j < graph.daily.series.length; j++) {
                        let series = graph.daily.series[j];
                        if (series.data.length) {

                            let dailyArr = series.data.map((item) => (item.value));
                            let dailyStart = moment(series.data[0].timestamp);
                            let dailyData = {
                                name: series.name,
                                data: dailyArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            timelineDataList = timelineDataList.concat(dailyData);
                        }
                    }
                }

                if (graph.extended !== null && graph.extended !== undefined && graph.extended.series !== null && graph.extended.series !== undefined && graph.extended.series.length) {
                    for (let j = 0; j < graph.extended.series.length; j++) {
                        let series = graph.extended.series[j];
                        if (series.data.length) {

                            let extendedArr = series.data.map((item) => (item.value));
                            let extendedStart = moment(series.data[0].timestamp);
                            let extendedData = {
                                name: series.name,
                                data: extendedArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: extendedStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            extendedDataList = extendedDataList.concat(extendedData);
                        }
                    }
                }
            }
        }

        if (multiGraph !== null && multiGraph !== undefined && multiGraph.series !== undefined && multiGraph.series.length) {
            for (let i = 0; i < multiGraph.series.length; i++) {
                let series = multiGraph.series[i];
                if (series.data.length) {

                    let multiArr = series.data.map((item) => (item.value));
                    let multiStart = moment(series.data[0].timestamp);
                    let multiEnd = moment(series.data[series.data.length - 1].timestamp);
                    let timeDiff = multiEnd.diff(multiStart);

                    let multiData = {
                        name: series.name,
                        data: multiArr,
                        color: series.color,
                        zIndex: 2,
                        type: 'line',
                        pointStart: multiStart,
                        pointInterval: timeDiff / 800,
                        yAxis: 0
                    };

                    multiDataList = multiDataList.concat(multiData);
                }
            }
        }
        
        this.setState({ 
            runDataList: runDataList, 
            timelineDataList: timelineDataList, 
            extendedDataList: extendedDataList,
            multiDataList: multiDataList, 
            currentMax: currentMax 
        }, () => {
            let rlaMin = this.props.rlaVal - 5;
            if (this.state.acSelected && currentMax >= rlaMin) {
                this.setState(prevState => ({
                    visibility: {
                        ...prevState.visibility,
                        rla: true
                    }
                }));
            }
        });
    }

    openGraphModal(alert, asset) {
        Event("View Graph Clicked", "User clicked view graph button in triage alerts", "View Graph clicked");
        if (!this.state.graphLoaded) {
            this.setState({
                editAlert: alert,
                graphLoading: true
            }, async () => {

                    this.props.showGraphModal(this.props.buttonId);
               
                    let selectedStartTime = this.props.currentAlert.endTime == null ? "" : this.props.currentAlert.startTime;
                    let selectedEndTime = this.props.currentAlert.endTime == null ? this.props.currentAlert.startTime : this.props.currentAlert.endTime;

                    let assetType = "Furnace";
                    let acSelected = this.props.acSelected;//false;
                    let thermostatSelected = false;
                    if (this.props.currentAlert.assetClassNames.some(a => a === "AirConditioner")) {
                        acSelected = true;
                        assetType = "AirConditioner";
                        await AssetService.get(this.props.currentAlert.pathIds[1])
                            .then(response => {
                                this.setState({ acHvacSystem: response.data });
                            });
                    }
                    else if (this.props.currentAlert.assetClassNames.some(a => a === "AirHandler")) {
                        assetType = "AirHandler";
                    }
                    else if (this.props.currentAlert.assetClassNames.some(a => a === "Thermostat")) {
                        thermostatSelected = true;
                        assetType = "Thermostat";
                    }

                    let propertySet = Object.hasOwnProperty.call(this.state.property_dictionary, this.props.currentAlert.typeId);
                    if (propertySet) {

                        await AlertService.getmultiseriesgraphdata({
                            assetId: parseInt(this.props.currentAlert.assetId, 10),
                            propertyList: this.state.property_dictionary[this.props.currentAlert.typeId],
                            startTime: selectedStartTime,
                            endTime: selectedEndTime,
                            maxPoints: 800,
                            summaryType: 1
                        }, acSelected ? this.props.currentAlert.pathIds[1] : null)
                            .then(response2 => {
                                if (this.props._isMounted)
                                    this.setState({
                                        multiseriesGraph: response2.data.trend,
                                        currentGraph: response2.data.run,
                                    }, async () => {
                                        if (this.state.currentGraph !== null && this.state.currentGraph !== undefined && this.state.currentGraph.timeSeriesDataSources !== null) {
                                            await this.getHighchartsData();

                                            const runName = this.state.currentGraph.runNamesList[0];
                                            this.setState({
                                                untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                graphLoading: false,
                                            });
                                        }
                                        else {
                                            this.setState({
                                                graphLoading: false,
                                                numRuns: 0,
                                                currentRunName: "",
                                                currentSource: jsSource,
                                                canLeftClick: false,
                                                canRightClick: false
                                            });
                                        }
                                    });
                            });

                    }
                    else {
                        if (this.state.dpStartStatus == null && this.state.dpEndStatus == null) {
                            let alertStart = new Date(this.props.currentAlert.startTime);
                            alertStart.setMinutes(alertStart.getMinutes() - 5);
                            let dailyStart = alertStart.setHours(alertStart.getHours() - 6);
                            let dailyEnd = alertStart.setHours(alertStart.getHours() + 12);
                            this.setState({
                                dpStartDateTime: dailyStart,
                                dpEndDateTime: dailyEnd
                            }, async () => {
                                    if (assetType === "AirHandler") {
                                        await AlertService.getsingleassetalertdata(this.props.currentAsset.offHigh, "AirHandler", this.props.currentAsset.acCurrentPropId, this.props.currentAlert)
                                            .then(response4 => {
                                                this.setState({
                                                    currentGraph: response4.data,
                                                    multiseriesGraph: null,
                                                    hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                    acGraph: false,
                                                    acSelected: false,
                                                    thermostatSelected: false,
                                                    thermGraph: false,
                                                    currentAssetType: assetType
                                                }, async () => {
                                                    await this.getHighchartsData();

                                                    this.setState({
                                                        graphLoading: false,
                                                    });
                                                });
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            })
                                    }
                                    else if (acSelected) {
                                        /** AC GRAPH **/
                                        await AlertService.getacalertgraphdata(this.props.currentAlert.assetId, this.props.currentAlert.startTime, this.props.currentAlert.endTime, this.props.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                            .then(response5 => {
                                                if (this.props._isMounted) {
                                                    this.setState({
                                                        currentGraph: response5.data,
                                                        hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                        acGraph: true,
                                                        thermGraph: false,
                                                        acSelected: true,
                                                        thermostatSelected: false,
                                                        selectedRun: 0,
                                                        currentAssetType: assetType
                                                    }, async () => {
                                                        if (this.state.currentGraph.timeSeriesDataSources !== null) {
                                                            await this.getHighchartsData();

                                                            const runName = this.state.currentGraph.runNamesList[0];

                                                            this.setState({
                                                                currentRunName: runName,
                                                                currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                runList: this.state.currentGraph.timeSeriesDataSources,
                                                                numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                canLeftClick: false,
                                                                canRightClick: true,
                                                                graphLoading: false
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                graphLoading: false,
                                                                numRuns: 0,
                                                                currentRunName: "",
                                                                currentSource: jsSource,
                                                                canLeftClick: false,
                                                                canRightClick: false
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                    }
                                    else if (thermostatSelected) {
                                        await AlertService.getthermostatalertdata(this.props.currentAlert)
                                            .then(response6 => {
                                                if (this.props._isMounted)
                                                    this.setState({
                                                        currentGraph: response6.data,
                                                        hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                        acGraph: false,
                                                        thermGraph: true,
                                                        acSelected: false,
                                                        thermostatSelected: true,
                                                        currentAssetType: assetType
                                                    }, async () => {
                                                        await this.getHighchartsData();

                                                        this.setState({ graphLoading: false });
                                                    });
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            });
                                    }
                                    else {
                                        await AlertService.getallgraphdata(this.props.currentAsset.offHigh, this.props.currentAsset.furnaceId, this.props.currentAsset.acCurrentPropId, this.props.currentAsset.furnaceCurrentPropId, this.props.currentAlert)
                                            .then(response3 => {
                                                if (this.props._isMounted)
                                                    this.setState({
                                                        currentGraph: response3.data,
                                                        hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                        acGraph: false,
                                                        thermGraph: false,
                                                        currentAssetType: assetType
                                                    }, async () => {
                                                        await this.getHighchartsData();

                                                        this.setState({
                                                            graphLoading: false,
                                                        });
                                                    });
                                            });
                                    } 
                            });

                        }
                        else {

                            if (acSelected) {
                                /** AC GRAPH **/
                                await AlertService.getacalertgraphdata(this.props.currentAlert.assetId, this.props.currentAlert.startTime, this.props.currentAlert.endTime, this.props.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                    .then(response5 => {
                                        if (this.props._isMounted) {
                                            this.setState({
                                                currentGraph: response5.data,
                                                multiseriesGraph: null,
                                                hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                acGraph: true,
                                                thermGraph: false,
                                                acSelected: true,
                                                thermostatSelected: false,
                                                selectedRun: 0,
                                                currentAssetType: assetType
                                            }, async () => {
                                                if (this.state.currentGraph.timeSeriesDataSources !== null && this.state.currentGraph.timeSeriesDataSources.length) {
                                                    await this.getHighchartsData();

                                                    const runName = this.state.currentGraph.runNamesList[0];

                                                    this.setState({
                                                        currentRunName: runName,
                                                        currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                        untrainedRun: runName !== undefined ? (runName.charAt(0) === 'g' ? false : (runName.charAt(0) === 'r' ? false : true)) : false,
                                                        runList: this.state.currentGraph.timeSeriesDataSources,
                                                        numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                        canLeftClick: false,
                                                        canRightClick: true
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        currentRunName: "",
                                                        currentSource: jsSource,
                                                        runList: [],
                                                        numRuns: 0,
                                                        canLeftClick: false,
                                                        canRightClick: false,
                                                        graphLoading: false,
                                                    });
                                                }
                                            });
                                        }
                                    });
                            }
                            else if (thermostatSelected) {
                                await AlertService.getthermostatalertdata(this.props.currentAlert)
                                    .then(response6 => {
                                        if (this.props._isMounted)
                                            this.setState({
                                                currentGraph: response6.data,
                                                hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                acGraph: false,
                                                thermGraph: true,
                                                acSelected: false,
                                                thermostatSelected: true,
                                                currentAssetType: assetType
                                            }, async () => {
                                                await this.getHighchartsData();

                                                this.setState({ graphLoading: false });
                                            });
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }
                            else if (assetType === "AirHandler") {
                                await AlertService.getsingleassetalertdata(this.props.currentAsset.offHigh, "AirHandler", this.props.currentAsset.acCurrentPropId, this.props.currentAlert)
                                    .then(response4 => {
                                        this.setState({
                                            currentGraph: response4.data,
                                            multiseriesGraph: null,
                                            hasAnalytics: this.props.currentAsset.hasAnalytics,
                                            acGraph: false,
                                            acSelected: false,
                                            thermostatSelected: false,
                                            thermGraph: false,
                                            currentAssetType: assetType
                                        }, async () => {
                                            await this.getHighchartsData();

                                            this.setState({
                                                graphLoading: false,
                                            });
                                        });
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    })
                            }
                            else {
                                await AlertService.getallgraphdata(this.props.currentAsset.offHigh, this.props.currentAsset.furnaceId, this.props.currentAsset.acCurrentPropId, this.props.currentAsset.furnaceCurrentPropId, this.props.currentAlert)
                                    .then(response3 => {
                                        if (this.props._isMounted)
                                            this.setState({
                                                currentGraph: response3.data,
                                                multiseriesGraph: null,
                                                hasAnalytics: this.props.currentAsset.hasAnalytics,
                                                currentAssetType: assetType
                                            }, async () => {
                                                await this.getHighchartsData();

                                                this.setState({
                                                    graphLoading: false,
                                                });
                                            });
                                    });
                            }
                        }
                    }
            });
        }
        else { /* graph already loaded for alert */
            this.props.showGraphModal(this.props.buttonId);
        }
    }

    render() {
        return (
            <div>
                <div><button className="secondary-btn btn-small" style={this.props.buttonFormat} onClick={() => this.openGraphModal(this.props.currentAlert, this.props.currentAsset)}>{this.props.buttonContent}</button></div>


                <AlertGraphsModal graphLoading={this.state.graphLoading} show={this.props.show} currentAsset={this.props.currentAsset} isThermostat={this.state.thermostatSelected} isAirHandler={this.props.currentAlert.assetClassNames.some(a => a.trim() === "AirHandler")} currentMax={this.state.currentMax}
                    currentAlert={this.props.currentAlert} runDataList={this.state.runDataList} currentGraph={this.state.currentGraph} multiseriesGraph={this.state.multiseriesGraph}
                    timeLineDataList={this.state.timelineDataList} extendedDataList={this.state.extendedDataList} multiDataList={this.state.multiDataList} clearGraphData={this.clearGraphData} acSelected={this.props.acSelected}
                />

            </div>
        );
    }
}

export default ViewGraphButton;