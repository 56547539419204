import React, {useEffect, useState} from "react";
import {Event, PageView, Timing} from "../GoogleAnalytics";
import TrainingCard from "./TrainingCard";
import './../../custom.css';
import TrainingVideosService from "../../services/trainingVideos.service";
import {Spinner} from "react-bootstrap";

const Technician = "TECHNICIAN";

function Training({ currentUserId, isLoading, roles, isMobile }) {
    
    const [isMounted, setIsMounted] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [trainingVideos, setTrainingVideos] = useState([]);
    const [userId, setUserId] = useState(currentUserId);
    const [userRoles, setUserRoles] = useState(roles);

    useEffect(() => {
        setIsMounted(true);
        
        PageView();

        if (isMounted) {
            if (isMobile) {
                // Mobile stores userId and roles in local storage
                setUserId(localStorage.getItem("userId"));
                setUserRoles(JSON.parse(localStorage.getItem("roles")));
                
                getTrainingVideos().then();
            } else if (!isLoading) {
                getTrainingVideos().then();
            }
        }

        return () => {
            setIsMounted(false);
        };
    }, [isMounted]);

    const getTrainingVideos = async () => {
        setIsLoadingData(true);

        const startTime = performance.now();

        await TrainingVideosService.getTrainingVideos(userId)
            .then(response => {
                if (response !== undefined && response !== null) {
                    setTrainingVideos(response);
                }

                const elapsedTime = performance.now() - startTime;
                Timing("Training Videos Loading", "loading", elapsedTime, "Training Videos Loading");

                setIsLoadingData(false);
            })
            .catch(e => {
                setIsLoadingData(false);
                console.log(e);
            });
    }
    
    const onVideoWatched = async (id) => {
        Event("Training Video Clicked", "User clicked training video", "Training Video Clicked");
        
        if (userRoles.includes(Technician)) {
            await TrainingVideosService.addWatchedVideo(userId, id)
                .then()
                .catch(e => {
                    console.log(e);
                });    
        }
    }

    const renderTrainingVideosContent = () => {
        return isLoadingData ?
            <div className={[isMobile ? "loading-mobile" : "loading"].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderGridView();
    }
    
    const renderGridView = () => {
      return (
          <div style={{width: '100%', height: '100vh'}}>
              <div className={isMobile ? "mobile-training-card-grid" : "training-card-grid"} style={{alignContent: "center"}}>
                  {trainingVideos.map(video => (
                      <TrainingCard
                          key={video.id}
                          id={video.id}
                          thumbnailImage={video.thumbnailImageBase64}
                          description={video.description}
                          url={video.videoUrl}
                          isWatched={video.isWatched}
                          onWatched={onVideoWatched}
                          isMobile={isMobile}
                      />
                  ))}
              </div>
          </div>
      );
    }

    return (
        <div className={isMobile ? 'mobile-training-background' : 'training-background'}>
            <h2 className="pageTitle" id="tabelLabel" style={{borderBottom: 'none', padding: isMobile ? '5px 0px 0px 30px' : '20px 0px 0px 30px'}}>Training Videos</h2>
            {renderTrainingVideosContent()}
        </div>
    );
}

export default Training;
