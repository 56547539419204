/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "../../CSS/DataModal.css";
import "react-datepicker/dist/react-datepicker.css";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const animatedComponents = makeAnimated();

class ScheduleJobModal extends Component {
    constructor(props) {
        super(props);

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTechnician = this.onChangeTechnician.bind(this);
        this.onBook = this.onBook.bind(this);

        this.state = {
            selectedDate: null,
            selectedDateStr: "",
            selectedUtcDateStr: "",
            selectedTechnician: null
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
    };

    onChangeDate(e) {
        var date = moment(e).toDate();
        var dateStr = moment(e).format();
        var dateUtcStr = moment(e).utc().format();
        this.setState({
            selectedDate: date,
            selectedDateStr: dateStr,
            selectedUtcDateStr : dateUtcStr
        });
    }

    onChangeTechnician = (selectedTech) => {
        this.setState({ selectedTechnician: selectedTech });
    }

    async onBook() {
        let techId = this.state.selectedTechnician == null ? null : this.state.selectedTechnician.userId;
        this.props.onBook(this.state.selectedUtcDateStr, techId);

        this.setState({
            selectedDate: null,
            selectedDateStr: "",
            selectedTechnician: null,
            selectedUtcDateStr : ""
        });
    }

    getOptionLabel = (option) => {
        return (option.lastName + ", " + option.firstName + " (" + option.userId + ")");
    }

    getOptionValue = (option) => {
        return (option.userId);
    }

    render() {
        const { classes } = this.props;

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>
                        <div className="modal-header">
                            <h3>Schedule Job: {this.props.currentJob.subject}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left", marginTop: '15px', marginBottom: '90px' }}>
                            <div className="col-6 offset-3">
                                <form>
                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Schedule For</label>
                                        <DatePicker
                                            customInput={<input className="form-control" style={{ padding: '2px 8px' }} />}
                                            id="scheduleDate"
                                            name="scheduleDate"
                                            selected={this.state.selectedDate}
                                            onChange={date => this.onChangeDate(date)}
                                            dateFormat="MM/dd/yyyy hh:mm:ss aa"
                                            timeIntervals={15}
                                            showTimeSelect
                                            placeholderText="--Select Date/Time--"
                                        />
                                    </div>
                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Assign To</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999 }) }}
                                            menuPortalTarget={document.body}
                                            components={animatedComponents}
                                            options={this.props.technicianList}
                                            getOptionLabel={this.getOptionLabel}
                                            getOptionValue={this.getOptionValue}
                                            value={this.state.selectedTechnician}
                                            onChange={this.onChangeTechnician}
                                            placeholder="--Select Technician--"
                                        />
                                    </div>
                                    <br />
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={this.onBook}>Book</button>
                        </div>
                    </div>
                </div>
            );
        }
    }

}

export default withStyles(styles)(ScheduleJobModal);