/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { Loading } from 'react-loading-dot';
import { PageView, Event } from "../GoogleAnalytics";
import PartnerService from "../../services/partner.service";
import AlertService from "../../services/alerts.service";
import ScheduleService from '../../services/schedule.service';
import BasicHighchartGraph from '../Graph/BasicHighchartGraph';

//import ACAssetTrainingReview from './ACAssetTrainingReview';
//import DeviceTrainingReview from './DeviceTrainingReview';
//import ACAlertTrainingReview from './ACAlertTrainingReview';
//import AlertTypeTrainingReview from './AlertTypeTrainingReview';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconTooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import NotificationIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ScheduledIcon from '@material-ui/icons/DateRange';
import UnscheduledIcon from '@material-ui/icons/EventBusy';
import ServicedIcon from '@material-ui/icons/EventAvailable';
import GroupsIcon from '@material-ui/icons/People';
import InstallIcon from '@material-ui/icons/SystemUpdate';
import DeviceDownIcon from '@material-ui/icons/MobileOff';
import SignalIcon from '@material-ui/icons/SpeakerPhone';
import RefreshIcon from '@material-ui/icons/Refresh';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardActionArea } from '@material-ui/core';
import moment from "moment";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';
import { Equipment } from "../../componentObjects";
import { withStyles } from '@material-ui/core/styles';

const COLORS = ['#0033a0', '#3ed3c2', '#ff7555', '#ff9900', '#7d8ee2'];

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

class AirHandlerOnboarding extends Component {
    static displayName = AirHandlerOnboarding.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadInfo = this.loadInfo.bind(this);
        this.populateInfo = this.populateInfo.bind(this);
        this.getChartData = this.getChartData.bind(this);
        this.onTrainingReportClick = this.onTrainingReportClick.bind(this);
        this.onTrainingFaultsClick = this.onTrainingFaultsClick.bind(this);
        this.onShowAllClick = this.onShowAllClick.bind(this);

        this.state = {
            loading: false,
            onboardInfo: {},
            orgId: 1,
            showAllTraining: true,//false,
            showShowAllBtn: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.loadInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInfo() {
        if (!this.props.isLoading)
            this.setState({ loading: true }, async () => {
                let orgId = this.props.currentPartnerId;
                let showBtn = true;
                let showAll = false;
                if (this.props.loggedInOrg !== "LifeWhere") {
                    showBtn = false;
                }
                else if (this.props.showAllTraining) {
                    showAll = true;
                    orgId = 1;
                }

                this.setState({
                    orgId: orgId,
                    showAllTraining: showAll,
                    showShowAllBtn: showBtn
                }, async () => {
                    await this.populateInfo();
                })
            });
    }

    async populateInfo() {
        console.log("populateInfo(), orgId: " + this.state.orgId);
        await PartnerService.getonboardinginfo(this.state.orgId, Equipment.AIR_HANDLER)
            .then(response => {
                if (this._isMounted) {
                    if (response != null && response !== {}) {
                        this.setState({ onboardInfo: response }, () => {
                            this.setState({ loading: false });
                        });
                    }
                    else {
                        this.setState({ loading: false });
                    }
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    onShowAllClick() {
        Event("Show All Clicked", "User clicked show all in AirHandler Onboarding Dashboard", "Show All Clicked");
        this.setState({ loading: true, showAllTraining: !this.state.showAllTraining }, () => {
            this.props.setShowAllTraining(this.state.showAllTraining);
            this.setState({ orgId: this.state.showAllTraining ? 1 : this.props.currentPartnerId }, async () => {
                await this.populateInfo();
            });
        });
    }

    onTrainingReportClick() {
        Event("Training Report Clicked", "User clicked training report in AirHandler Onboarding Dashboard", "Training Report Clicked");
        this.props.setActivePath("/airHandlerAssetTrainingReview");
        this.props.history.push("/airHandlerAssetTrainingReview");
    }

    onTrainingFaultsClick() {
        Event("Training Faults Clicked", "User clicked training faults in AirHandler Onboarding Dashboard", "Training Faults Clicked");
        this.props.setActivePath("/airHandlerTrainingAlertCountReview");
        this.props.history.push("/airHandlerTrainingAlertCountReview");
    }

    onChangePage(title) {
        this.setState({ pageName: title });
    }

    getChartData(data) {
        let chartDataList = [];

        if (data !== undefined && data.length) {
            let pointMoment = moment(data[0].Timestamp).add(moment().utcOffset() * -1, 'minutes').format("l")
            var dt = new Date(pointMoment);
            var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());

            let trainedArr = data.map((item) => (item.Trained));
            const trainedData = {
                name: `Trained Air Handlers`,
                data: trainedArr,
                color: '#176a95',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(trainedData);

            let failedArr = data.map((item) => (item.Failed));
            const failedData = {
                name: `Air Handlers that failed training`,
                data: failedArr,
                color: 'red',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(failedData);

            let notEnoughDataArr = data.map((item) => (item.NotEnoughData));
            const notEnoughDataData = {
                name: `Air Handlers with not enough data`,
                data: notEnoughDataArr,
                color: 'orange',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(notEnoughDataData);

            /*let untrainedArr = data.map((item) => (item.Untrained));
            const untrainedData = {
                name: `Air Handlers in Awaiting Data and Retrain`,
                data: untrainedArr,
                color: 'purple',
                dashStyle: 'solid',
                type: 'line',
                pointStart: pointStartMoment,
                pointInterval: 24 * 3600 * 1000,
                opacity: 1,
                marker: {
                    enabledThreshold: 0
                }
            };
            chartDataList = chartDataList.concat(untrainedData);*/
        }

        return chartDataList;
    }

    getPercentage(count) {
        let total = this.state.onboardInfo.totalFaults;
        let percentage = (count / total) * 100;
        percentage = percentage.toFixed(2);

        return (percentage + "%");
    }

    renderContent() {

        let trainingReportContent = <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <div className='col' style={{ textAlign: 'right' }}>
                        <CloseIcon className="dashboard-icon-sm db-red db-red-bg" />
                    </div>
                    <div className="col" style={{ textAlign: 'left' }}>
                        <div className="dashboard-number db-num3">
                            {this.state.onboardInfo.failedCount}
                        </div>
                    </div>

                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Failed Training</small>
                </div>

            </div>

            <div className="col" style={{ minHeight: '40px', borderRight: '1px solid lightgrey' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <div className='col' style={{ textAlign: 'right' }}>
                        <PriorityHighIcon className="dashboard-icon-sm db-orange db-orange-bg" />
                    </div>
                    <div className="col" style={{ textAlign: 'left' }}>
                        <div className="dashboard-number db-num3">
                            {this.state.onboardInfo.notEnoughDataCount}
                        </div>
                    </div>

                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Not Enough Data</small>
                </div>
            </div>

            <div className="col" style={{ minHeight: '40px' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <div className="col" style={{ textAlign: 'right' }}>
                        <CheckIcon className="dashboard-icon-sm db-teal" />
                    </div>
                    <div className="col" style={{ textAlign: 'left' }}>
                        <div className="dashboard-number db-num3">
                            {this.state.onboardInfo.trainedCount}
                        </div>
                    </div>

                </div>
                <div>
                    <small style={{ color: "dimGray", fontSize: '80%', textAlign: 'center' }}>Trained</small>
                </div>
            </div>
        </div>;

        let assetsTrainedContent = <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
            <div className="col" style={{ minHeight: '40px' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <div className="col">
                        <div className="dashboard-number" style={{ fontSize: '2.25rem', paddingTop: '.25rem' }}>
                            {this.state.onboardInfo.totalTrainedCount}
                        </div>
                    </div>

                </div>
                <div>
                    <small style={{ color: "transparent", fontSize: '80%', textAlign: 'center' }}>placeholder</small>
                </div>
            </div>
        </div>;

        let trainingHistoryContent = <div className="row no-gutters card-row" style={{ textAlign: 'center', minHeight: '110px !important' }}>
            <div className="col" style={{ minHeight: '110px' }}>
                <BasicHighchartGraph chartData={this.getChartData(this.state.onboardInfo.trainingGraphData)} yAxisTitle='Asset Count' />
            </div>
        </div>

        let assetsNeedTrainContent = <div className="row no-gutters card-row" style={{ textAlign: 'center' }}>
            <div className="col" style={{ minHeight: '40px' }}>
                <div className="row no-gutters dashboard-val" style={{ justifyContent: 'center' }}>
                    <div className="col">
                        <div className="dashboard-number" style={{ fontSize: '2.25rem', paddingTop: '.25rem' }}>
                            {this.state.onboardInfo.totalRetrainCount}
                        </div>
                    </div>

                </div>
                <div>
                    <small style={{ color: "transparent", fontSize: '80%', textAlign: 'center' }}>placeholder</small>
                </div>
            </div>
        </div>;

        let trainingFaultsContent = <div className="row no-gutters card-row" style={{ textAlign: 'center', margin: '8px 0px' }}>
            <div className="col" style={{ minHeight: '40px' }}>
                <div style={{ margin: '0px 20px' }}>
                    <table style={{ width: '100%', marginBottom: '0' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 30px 10px' }}>
                                    FAULT
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '0px 0px 10px' }}>
                                    #
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                    % (trained past 24hrs)
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.onboardInfo.trainingFaults !== undefined && this.state.onboardInfo.trainingFaults.map((fault, index) =>
                                <tr key={fault.failureId} data={index} className="alerts-table-row">
                                    <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> {fault.name} </td>
                                    <td style={{ textAlign: 'center', padding: '8px 3px' }}> {fault.count} </td>
                                    <td style={{ textAlign: 'left', padding: '8px 3px 8px 40px' }}> {this.getPercentage(fault.count)} </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;

        let falsePositiveFaultsContent = <div className="row no-gutters card-row" style={{ textAlign: 'center', margin: '8px 0px' }}>
            <div className="col" style={{ minHeight: '40px' }}>
                <div style={{ margin: '0px 20px' }}>
                    <table style={{ width: '100%', marginBottom: '0' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 30px 10px' }}>
                                    FAULT
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '0px 0px 10px' }}>
                                    #
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'left', padding: '0px 10px 10px 40px' }}>
                                    % (Trained past 24hrs)
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="alerts-table-row">
                                <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> Contact Failure </td>
                                <td style={{ textAlign: 'center', padding: '8px 3px' }}> 4 </td>
                                <td style={{ textAlign: 'left', padding: '8px 3px 8px 40px' }}> 13% </td>
                            </tr>

                            <tr className="alerts-table-row">
                                <td style={{ textAlign: 'left', padding: '8px 3px 8px 30px' }}> Amp higher than... </td>
                                <td style={{ textAlign: 'center', padding: '8px 3px' }}> 3 </td>
                                <td style={{ textAlign: 'left', padding: '8px 3px 8px 40px' }}> 5% </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;

        return (
            <div>
                <div className="row" style={{ padding: '30px 30px 0px' }}>

                    <div className="col-6" style={{ display: 'grid' }}>
                        <div>
                            <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onTrainingReportClick}>
                                <div className="col">

                                    <Card elevation={4}>
                                        <CardActionArea style={{ height: '100%' }}>
                                            <CardContent style={{ padding: '10px 0px' }}>

                                                <h6 style={{ paddingLeft: '15px' }}>Asset Training Report (24 hours)</h6>
                                                {trainingReportContent}

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6" style={{ display: 'grid' }}>
                        <div>
                            <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onAssetsTrainedClick}>
                                <div className="col">

                                    <Card elevation={4}>
                                        <CardActionArea style={{ height: '100%' }}>
                                            <CardContent style={{ padding: '10px 0px' }}>

                                                <h6 style={{ paddingLeft: '15px' }}>Air Handler Assets Trained</h6>
                                                {assetsTrainedContent}

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ padding: '0px 30px', height: '100%' }}>
                    <div className="col-6">
                        <div>
                            <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onTrainingHistoryClick}>
                                <div className="col">

                                    <Card elevation={4} style={{ height: '100%' }}>
                                        <CardActionArea style={{ height: '100%' }}>
                                            <CardContent style={{ padding: '10px 0px', textAlign: 'center' }}>

                                                <h6 style={{ paddingLeft: '15px' }}>Asset Training History</h6>
                                                {trainingHistoryContent}

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">

                        <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onAssetsNeedTrainingClick}>
                            <div className="col">

                                <Card elevation={4}>
                                    <CardActionArea style={{ height: '100%' }}>
                                        <CardContent style={{ padding: '10px 0px' }}>

                                            <h6 style={{ paddingLeft: '15px' }}>Air Handler Assets Needing Train/Retrain</h6>
                                            {assetsNeedTrainContent}

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </div>

                        <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onTrainingFaultsClick}>
                            <div className="col">

                                <Card elevation={4}>
                                    <CardActionArea style={{ height: '100%' }}>
                                        <CardContent style={{ padding: '10px 0px' }}>

                                            <h6 style={{ paddingLeft: '15px' }}>Air Handler Training Faults</h6>
                                            {trainingFaultsContent}

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </div>

                    </div>
                </div>

                {/*<div className="row" style={{ padding: '0px 30px', height: '100%' }}>
                    <div className="col-6 offset-6" style={{ display: 'grid' }}>
                        <div className="row no-gutters" style={{ marginBottom: '20px' }} onClick={this.onFalsePositiveFaultsClick}>
                            <div className="col">

                                <Card elevation={4}>
                                    <CardActionArea style={{ height: '100%' }}>
                                        <CardContent style={{ padding: '10px 0px' }}>

                                            <h6 style={{ paddingLeft: '15px' }}>Air Handler False-Positive Faults</h6>
                                            {falsePositiveFaultsContent}

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>*/}

            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let contents = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderContent();

        let showAllBtn = this.state.showShowAllBtn ?
            <div className="btn-right" style={{ padding: '25px 0px 0px 30px' }}>
                <button className={this.state.showAllTraining ? "primary-btn btn-large" : "secondary-btn btn-large"} style={{ width: 150 + 'px' }} onClick={this.onShowAllClick}>Show All</button>
            </div>
            : null;

        return (
            <div className={classes.root}>
                <div className='infoPage'>
                    <div className="row no-gutters">
                        <div className="col">
                            <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                                Air Handler Onboarding Dashboard
                            </h2>
                        </div>
                        <div className="col">
                            {showAllBtn}
                        </div>
                    </div>

                    {contents}

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AirHandlerOnboarding);